import React from "react";
import { NavLink } from "react-router-dom";
import { CommonImg } from "../../../Images/CommonImg";
import { Image } from "../../../Images/Image";
import { Study } from "../../../Images/Study";
import SITE_PATHS from "../../Path";

export default function Blog1() {


    return (
        <>
            <div className="page-title-area">
                <div className="container">
                    <div className="page-title-content">
                        <h2>Education System in Australia</h2>
                        <ul>
                            <li><NavLink to={SITE_PATHS.LOGOCLICK}>Home</NavLink></li>
                            <li>Blog Us</li>
                        </ul>
                    </div>
                </div>

                <div className="shape-img3">

                    <img src={CommonImg[1]?.path} ></img>
                </div>
                <div className="shape-img3">
                    <img
                        src=""
                        alt="banner" />
                </div>
            </div>

            <div className="about-area pb-100 bannermargin">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="about-img"><img
                             src={Study[35]?.path} 
                                alt="service" /></div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="about-content">
                                <div className="content"><span className="sub-title"><img
                                   src={CommonImg[0]?.path} 
                                    alt="icon" /> Services</span>
                                    <h2>Effects of immigration on the Indian market</h2>
                                    <p>In many nations, including India, migration is a part of social and economic life; nevertheless, the characteristics of migrant populations differ significantly. The Economic Times reported on April 15, 2019, that over the previous 40 years, India saw the most internal migration of any country. Even with modest estimates, India's 100 million domestic immigrants are transferring enormous sums of money "home," an amount 8 times more than the country's total expenditures for healthcare and education.</p>

                                    <p>Immigration has an impact on the country's labor supply. Immigration has two facets in the host nation. The country's economy grows and develops more, which is good. On the other hand, the threat posed by illegal immigration to national security is a bad thing.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="our-mission-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-content">
                                <div className="content"><span className="sub-title"><img
                                   src={CommonImg[0]?.path} 
                                    alt="icon" /> Services</span>
                                    <h2>Impact of Immigration on home country</h2>
                                    <p>It is natural to wonder why immigrants leave their native nation for another and what the new country can't provide.  The level of life, lack of employment prospects, poor salaries, lack of educational chances, and other factors are among the causes of immigration. The country must investigate why so many people leave for other nations. Immigration often harms the nation's economy, level of output, and the number of working-age people.</p>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-image"><img
                                className="ieltstest"   src={Image[24]?.path} 
                                alt="service" /></div>
                        </div>
                    </div>
                </div>
            </div>



            <div className="about-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="about-img"><img
                                className="ieltstest"   src={Study[34]?.path}
                                alt="service" /></div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="about-content">
                                <div className="content"><span className="sub-title"><img
                                   src={CommonImg[0]?.path} 
                                    alt="icon" /> Services</span>
                                    <h2>Remittance</h2>

                                    <p>It is common knowledge that immigrants send a sizable sum of money home to assist their families. The local government, and individuals and households, have access to a sizable influx of foreign currency or capital for growth and development.</p>

                                    <p>Based on World Health Organization research, India received 87 billion dollars in remittances in 2021, making it the top beneficiary and far ahead of nations like China and Mexico. Remittances are an "essential and good" financial outcome of migration, benefiting immigrants, their relatives, and their companions who stay in their home countries.</p>

                                    <p>Today, excluding China, remittances outpace foreign direct investment by more than three times and outnumber government development aid by more than fifty percent. This highlights the significance of remittance flows in sustaining expenditure in recipient nations during tough economic times.</p>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="our-mission-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-content">
                                <div className="content"><span className="sub-title"><img
                                   src={CommonImg[0]?.path} 
                                    alt="icon" /> Services</span>
                                    <h2>Does immigration help the economy?</h2>

                                    <p>Numerous studies have found that economic migration increases global economic growth. International migration, according to the World Bank, boosts global GDP by enabling employees to relocate to regions where they can be most productive. For nations with low birth rates, immigrants can be especially significant. </p>

                                    <p>Three factors- changes in the labor supply, changes in productivity, and remittances from migrants- can all have an impact on growth and the lowering of poverty as a result of emigration.</p>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-image"><img
                                className="ieltstest"   src={Study[6]?.path} 
                                alt="service" /></div>
                        </div>
                    </div>
                    <div className="col-lg-12">

                        <div className="content missincintbox">
                            <p>The home nation may benefit from immigration. It can boost the production of their employees, raise financial stability, and lower unemployment. Young immigrants can also be considered a wellspring of creativity and skill.</p>

                            <p>Numerous migrants, mainly from Western nations, relocated from their home countries. When they return, they typically have money saved up, employable skills, and connections that support creativity and financial progress in their home countries.</p>

                        </div>

                    </div>
                </div>
            </div>



            <div className="our-mission-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                            <div className="our-mission-image"><img
                                className="ieltstest"   src={Image[2]?.path}
                                alt="service" /></div>
                        </div>

                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-content">
                                <div className="content"><span className="sub-title"><img
                                   src={CommonImg[0]?.path} 
                                    alt="icon" /> Services</span>
                                    <h2>Migration's adverse effects on the home country</h2>

                                    <p>The loss of a skilled workforce in countries of origin is one of the detrimental effects of migration. For instance, when doctors relocate, the nation they leave behind suffers from a medical shortage.</p>

                                    <p>Many young people leave their native countries for affluent countries because of unemployment. Because of this, many young people with skills don't contribute to the economy. The home country is thus forced to deal with the loss of competent young.</p>

                                    <p>Families are frequently abandoned when individuals move. Children may grow up without having access to a better family network that may help them financially and emotionally as they develop. The socioeconomic problems that migrants experience in their new nation may also affect their home country.</p>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="our-mission-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-content">
                                <div className="content"><span className="sub-title"><img
                                   src={CommonImg[0]?.path} 
                                    alt="icon" /> Services</span>
                                    <h2>Conclusion</h2>

                                    <p>In the end, it can be argued that immigration and migration have both beneficial and negative effects on the home country's economy. People move either because of their financial difficulties or because they are unemployed. These issues affect the national economy. There is a considerable loss of the youth workforce as young people depart for better prospects. Overall, the remittance was advantageous to the home nation as well.</p>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-image"><img
                                className="ieltstest"   src={Study[17]?.path} 
                                alt="service" /></div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}