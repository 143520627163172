import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { CommonImg } from "../../Images/CommonImg";
import { Image } from "../../Images/Image";
import SITE_PATHS from "../Path";
import { toast, ToastContainer } from "react-toastify"
import 'react-toastify/dist/ReactToastify.css';
import { newUserVisiting } from "../../ApiComponent/Apifunction";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { validationEmail } from "../../ApiComponent/Validation";
export default function ContactUs() {
    const [state, setState] = useState({ Name: "", Email: "", Phone: "", VisaType: "", Country: "" })
    const submit = async () => {
        const { Name, Email, Phone, VisaType, Country } = state
        if (Name === "") {
            toast("Please Enter correct info for Name");
        }
        else if (Email === "") {
            toast("Please Enter correct info for Email");
        }
        else if (Phone === "") {
            toast("Please Enter correct info for Phone");
        }
        else if (Phone != "" && Phone.length < 10) {
            toast("Please Enter correct info for Phone");
        }
        else if (VisaType === "") {
            toast("Please Select Visa Type");
        }
        else if (Country === "") {
            toast("Please Select Country");
        }
        else {

            const val = await validationEmail(Email)
            if (!val) { toast("Please Enter correct info for Email"); }
            else {
                const FromUser = "info@aussieimmigration.com.au"
                const data = await newUserVisiting(Name, Email, Phone, VisaType, Country, FromUser)
                toast("Details Saved Succesfully");
                setState({ Name: "", Email: "", Phone: "", VisaType: "", Country: "" })
            }
        }
    }

    return (
        <>
            <div className="page-title-area">
                <ToastContainer position="top-center" />
                <div className="container">
                    <div className="page-title-content">
                        <h4 className="text_size">Contact</h4>
                        <ul>
                            <li><NavLink to={SITE_PATHS.LOGOCLICK}>Home</NavLink></li>
                            <li>Contact</li>
                        </ul>
                    </div>
                </div>
                <div className="shape-img3">

                    <img className="bannermargin_img" src={CommonImg[3]?.path}></img>
                </div>

            </div>
            <div className="contact-info-area pt-100 bannermargin pb-70">
                <div className="container">
                    <div className="section-title"><span className="sub-title">
                        <img src={CommonImg[0]?.path} alt="contact"/>
                        Our Address </span>
                        <p>Experience something completely different contact us</p>

                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <div className="contact-info-box">
                                <div className="back-icon"><i className="fas fa-map-marker-alt"></i></div>
                                <div className="icon"><i className="fas fa-map-marker-alt"></i></div>
                                <h3>Australia</h3>
                                <p>1/16 Mcdougall, ST Milton, 4064, QLD, Australia</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="contact-info-box">
                                <div className="back-icon"><i className="fas fa-phone-alt"></i></div>
                                <div className="icon"><i className="fas fa-phone-alt"></i></div>
                                <h3>Contact</h3>
                                <p>Mobile: <a href="tel:485863005">(+61) 485 863 005</a></p>
                                <p><a href="mailto:info@aussieimmigration.com.au">info@aussieimmigration.com.au</a></p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                            <div className="contact-info-box">
                                <div className="back-icon"><i className="fas fa-clock"></i></div>
                                <div className="icon"><i className="fas fa-clock"></i></div>
                                <h3>Hours of Operation</h3>
                                <p>Monday - Friday: 08:00 - 17:00</p>
                                <p>Saturday &amp; Sunday: Closed</p>
                            </div>
                        </div>
                    </div>
                    {/* <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <div className="contact-info-box">
                                <div className="back-icon"><i className="fas fa-map-marker-alt"></i></div>
                                <div className="icon"><i className="fas fa-map-marker-alt"></i></div>
                                <h3>India</h3>
                                <p>SCO-58, Emperor Square, TDI City, Sonipat Haryana, India</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="contact-info-box">
                                <div className="back-icon"><i className="fas fa-phone-alt"></i></div>
                                <div className="icon"><i className="fas fa-phone-alt"></i></div>
                                <h3>Contact</h3>
                                <p>Mobile: <a href="tel:+918295195516">(+91) 82951-95516</a></p>
                                <p> <a href="mailto:info@aussieimmigration.com.au">info@aussieimmigration.com.au</a></p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                            <div className="contact-info-box">
                                <div className="back-icon"><i className="fas fa-clock"></i></div>
                                <div className="icon"><i className="fas fa-clock"></i></div>
                                <h3>Hours of Operation</h3>
                                <p>Monday - Saturday: 07:30 - 17:00</p>
                                <p>Sunday : Closed</p>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
            <section className="contact-area pb-100">
                <div className="container">
                    <div className="section-title"><span className="sub-title">
                        <img src={CommonImg[0]?.path} alt="contact"/>
                        Get in Touch</span>
                        <h2>Ready to Get Started?</h2>
                        <p>Your email address will not be published. Required fields are marked *</p>
                    </div>
                    <div className="row">
                        <div className="col-lg-6 col-md-12">
                            <div className="contact-image"><img
                                src={Image[39]?.path}
                                alt="contact" /></div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="contact-form">
                                <form id="contactForm">
                                    <h3 className="landingheadeing">Signup for free consultation</h3>
                                    <div className="row">
                                        <div className="col-lg-12 col-md-6">
                                            <div className="form-group"><input type="text" name="name" className="form-control"
                                                onChange={(e) => setState({ ...state, Name: e.target.value })}
                                                value={state.Name}
                                                required="" placeholder="Your name" /></div>
                                        </div>
                                        <div className="col-lg-12 col-md-6">
                                            <div className="form-group">
                                                <input type="email" name="email"
                                                    onChange={(e) => setState({ ...state, Email: e.target.value })}
                                                    value={state.Email} className="form-control" required="" placeholder="Your email address" />
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-md-12">
                                            <div className="form-group">

                                                <PhoneInput
                                                    country={'in'} className="form-control"
                                                    enableAreaCodes={true}
                                                    value={state.Phone}
                                                    onChange={phone => setState({ ...state, Phone: phone })}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-md-12">
                                            <div className="form-group">
                                                <select value={state.VisaType} className="form-control" onChange={(e) => setState({ ...state, VisaType: e.target.value })} >
                                                    <option >Visa Type</option>
                                                    <option value="Student Visa">Student Visa</option>
                                                    <option value="Permanent Visa">Permanent Residence</option>
                                                    <option value="Tourist Visa">Tourist Visa</option>
                                                    <option value="Spouse/Family Visa">Spouse/Family Visa</option>
                                                    <option value="Parent Visa">Parent Visa</option>
                                                    <option value="Work Visa">Work Visa</option>
                                                    <option value="Education Visa">Education Visa</option>
                                                </select>

                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-md-12">
                                            <div className="form-group" value={state.Country} onChange={(e) => setState({ ...state, Country: e.target.value })}>
                                                <select className="form-control" >
                                                    <option >Country to Immigrate</option>
                                                    <option value="Australia">Australia </option>
                                                    <option value="UK">UK</option>
                                                    <option value="Canada">Canada </option>
                                                    <option value="USA">USA</option>
                                                    <option value="New Zealand">New Zealand</option>
                                                    <option value="Other">Other </option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-md-12"><div type="submit" onClick={() => submit()} className="default-btn"><i
                                            className="fas fa-check"></i>Start Now <span></span></div></div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}