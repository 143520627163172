import React from "react";
import { NavLink } from "react-router-dom";
import SITE_PATHS from "../../Path";
import { CommonImg } from "../../../Images/CommonImg";

export default function Course7() {


    return (
        <>

            <div className="page-title-area">
                <div className="container">
                    <div className="page-title-content">
                        <h2>Work Visa</h2>
                        <ul>
                            <li><NavLink to={SITE_PATHS.LOGOCLICK}>Home</NavLink></li>
                            <li>Course</li>
                        </ul>
                    </div>
                </div>

                <div className="shape-img3">

                    <img src={CommonImg[1]?.path} ></img>
                </div>
                <div className="shape-img3">
                    <img
                        src=""
                        alt="banner" />
                </div>
            </div>
            <section>
                <div>
                    <p> Coming Soon...</p>
                </div>
            </section>
        </>
    )
}