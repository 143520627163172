import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { CommonImg } from "../Images/CommonImg";
import SITE_PATHS from "./Path";
export default function Header() {
  const [show, setshow] =useState(false);

  const display=()=>{setshow(!show)}
    return (
        <>

            <div id="navbar" className="navbar-area">
                <div className="tarn-nav">
                    <div className="container-fluid">
                        <nav className="navbar navbar-expand-lg navbar-light">
                            <NavLink aria-current="page" className="navbar-brand"  onClick={()=> setshow(false)} 
                                to={SITE_PATHS.LOGOCLICK}>
                                <img src={CommonImg[13]?.path} alt="logo" /></NavLink>
                            <button onClick={()=> display()} className="navbar-toggler navbar-toggler-right collapsed"
                                type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
                                aria-controls="navbarSupportedContent" aria-expanded="false"
                                aria-label="Toggle navigation">
                                <span className="icon-bar top-bar"></span>
                                <span className="icon-bar middle-bar"></span>
                                <span className="icon-bar bottom-bar"></span></button>
                            <div className={`collapse navbar-collapse ${show ? "collapseshow" : ""}`} id="navbarSupportedContent">
                                <ul className="navbar-nav">
                                    <li className="nav-item"><NavLink aria-current="page" className="nav-link "
                                        to={SITE_PATHS.DASHBOARD}>Home <i className="fas fa-chevron-down"></i></NavLink>

                                        <ul className="dropdown-menu">
                                            <li className="nav-item"><NavLink className="nav-link"
                                                to={SITE_PATHS.ABOUT} onClick={()=> setshow(false)}>About Us</NavLink></li>
                                            {/* <li className="nav-item"><NavLink className="nav-link"
                                                to={SITE_PATHS.HISTORY}>History</NavLink></li>
                                            <li className="nav-item"><NavLink className="nav-link"
                                                to={SITE_PATHS.TESTIMONIALS}>Testimonials</NavLink>
                                            </li> */}
                                            <li className="nav-item"><NavLink className="nav-link"
                                                to={SITE_PATHS.FAQ}  onClick={()=> setshow(false)}>FAQ</NavLink>
                                            </li>

                                            {/* <li className="nav-item"><NavLink className="nav-link"
                                                to={SITE_PATHS.GALLARY}>Gallery</NavLink>
                                            </li> */}
                                            {/* <li className="nav-item"  onClick={()=> setshow(false)}><NavLink className="nav-link"
                                                to={SITE_PATHS.TEAM}>Our Team</NavLink>
                                            </li> */}
                                        </ul>
                                    </li>

                                    <li className="nav-item"><NavLink className="nav-link"
                                        to={SITE_PATHS.SERVICE}>Services <i className="fas fa-chevron-down"></i></NavLink>
                                        <ul className="dropdown-menu">
                                            <li className="nav-item"  onClick={()=> setshow(false)}><NavLink className="nav-link"
                                                to={SITE_PATHS.IELTS}>IELTS</NavLink></li>
                                            <li className="nav-item"  onClick={()=> setshow(false)}><NavLink className="nav-link"
                                                to={SITE_PATHS.PTE}>PTE
                                                Details</NavLink></li>
                                            <li className="nav-item"  onClick={()=> setshow(false)}><NavLink className="nav-link"
                                                to={SITE_PATHS.SPOKENENGLISH}>Spoken English
                                                Details</NavLink></li>

                                        </ul>
                                    </li>

                                    <li className="nav-item"><NavLink className="nav-link"
                                        to={SITE_PATHS.AUSTRALIA}>Study Abroad <i className="fas fa-chevron-down"></i></NavLink>
                                        <ul className="dropdown-menu">
                                            <li className="nav-item"  onClick={()=> setshow(false)}><NavLink className="nav-link"
                                                to={SITE_PATHS.AUSTRALIA}>Study Visa In AUSTRALIA</NavLink></li>
                                            <li className="nav-item"  onClick={()=> setshow(false)}><NavLink className="nav-link"
                                                to={SITE_PATHS.CANADA}>Study Visa In CANADA</NavLink></li>
                                            <li className="nav-item"  onClick={()=> setshow(false)}><NavLink className="nav-link"
                                                to={SITE_PATHS.UK}>Study Visa In UK</NavLink></li>
                                            <li className="nav-item"  onClick={()=> setshow(false)}><NavLink className="nav-link"
                                                to={SITE_PATHS.USA}>Study Visa In USA</NavLink></li>
                                            <li className="nav-item"  onClick={()=> setshow(false)}><NavLink className="nav-link"
                                                to={SITE_PATHS.NEWZEALAND}>Study Visa In New Zealand</NavLink></li>
                                        </ul>
                                    </li>
                                    <li className="nav-item"><NavLink className="nav-link"
                                        to={SITE_PATHS.COURSE}>Visa Types<i className="fas fa-chevron-down"></i></NavLink>
                                        <ul className="dropdown-menu">
                                            <li className="nav-item"  onClick={()=> setshow(false)}><NavLink className="nav-link"
                                                to={SITE_PATHS.COURSE}>Visa</NavLink></li>
                                            <li className="nav-item"  onClick={()=> setshow(false)}><NavLink className="nav-link"
                                                to={SITE_PATHS.COURSE1}>Student/Education Visa </NavLink></li> 
                                                 <li className="nav-item"  onClick={()=> setshow(false)}><NavLink className="nav-link"
                                                to={SITE_PATHS.COURSE2} >Permanent Residence </NavLink></li> 
                                                 <li className="nav-item"  onClick={()=> setshow(false)}><NavLink className="nav-link"
                                                to={SITE_PATHS.COURSE3} >Tourist Visa </NavLink></li> 
                                                 <li className="nav-item"  onClick={()=> setshow(false)}><NavLink className="nav-link"
                                                to={SITE_PATHS.COURSE4}>Spouse/Family Visa</NavLink></li> 
                                                 <li className="nav-item"  onClick={()=> setshow(false)}><NavLink className="nav-link"
                                                to={SITE_PATHS.COURSE5}>Parent Visa </NavLink></li> 
                                                 <li className="nav-item"  onClick={()=> setshow(false)}><NavLink className="nav-link"
                                                to={SITE_PATHS.COURSE6}>Work Visa </NavLink></li> 
                                                 {/* <li className="nav-item"><NavLink className="nav-link"
                                                to={SITE_PATHS.COURSE7}>Course Details</NavLink></li>  */}
                                                 {/* <li className="nav-item"  onClick={()=> setshow(false)}><NavLink className="nav-link"
                                                to={SITE_PATHS.COURSE8}>Education Visa </NavLink></li>  */}
                                                  </ul>
                                    </li>
                                    <li className="nav-item"  onClick={()=> setshow(false)} ><NavLink className="nav-link"
                                        to={SITE_PATHS.CONTACT}>Contact Us</NavLink>

                                    </li>

                                </ul>
                                <div className="others-option d-flex align-items-center">


                                    <div className="option-item"><a className="default-btn"
                                       onClick={()=> setshow(false)} href="tel:+918295195516"><i className="fas fa-phone-alt"></i> Call Now <span></span></a></div>

                                    <div className="option-item"><NavLink className="default-btn"
                                      onClick={()=> setshow(false)}  to={SITE_PATHS.LANDINGPAGE1}><i className="fas fa-long-arrow-alt-right"></i> Get Started <span></span></NavLink></div>
                                </div>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
        </>
    );
}