import React from "react";
import { NavLink } from "react-router-dom";
import SITE_PATHS from "../../Path";
import { CommonImg } from "../../../Images/CommonImg";
import { Study } from "../../../Images/Study";
import { ServiceImage } from "../../../Images/ServiceImage";
import { Image } from "../../../Images/Image";

export default function Course1() {


    return (
        <>

            <div className="page-title-area">
                <div className="container">
                    <div className="page-title-content">
                        <h4 className="text_size">Student Visa</h4>
                        <ul>
                            <li><NavLink to={SITE_PATHS.LOGOCLICK}>Home</NavLink></li>
                            <li>Student Visa</li>
                        </ul>
                    </div>
                </div>

                <div className="shape-img3">

                    <img src={CommonImg[1]?.path} ></img>
                </div>
            </div>

            <div className="about-area pb-100 bannermargin">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="about-img">
                                <img src={Study[44]?.path} className="ieltstest" alt="student visa in australia"/>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="about-content">
                                <div className="content"><span className="sub-title">
                                    <img src={CommonImg[0]?.path} alt="services"/>
                                    Services</span>
                                    <h1 className="text_size">Student Visa</h1>
                                    <p>You may be guaranteed a bright future if you apply for an Australian student visa. You must understand the student visa application procedure to obtain a student visa. The Australian Department of Home Affairs replaced the existing Assessment Level (AL) framework and Streamlined Visa Processing (SVP) procedures on July 1, 2016, by introducing the Simplified Student Visa Framework (SSVF). You must read Subclass 500 information if you want to understand the specifics of a student visa.</p>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="our-mission-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-content">
                                <div className="content"><span className="sub-title">
                                    <img src={CommonImg[0]?.path} alt="services"/> 
                                    Services</span>
                                    <h2 className="text_size">(Subclass 500) Australian Student Visa</h2>
                                    <p>Starting on the day of your enrollment, you are permitted to stay for up to five years on a student visa. The typical application price for an Australian Visa is 650 AUD (33522 INR approx.), if not exempted.</p>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-image">
                                <img className="ieltstest" src={Study[43]?.path} alt="australian student visa" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <div className="about-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="about-img">
                                <img className="ieltstest" src={Study[42]?.path} alt="student visa in australia"/>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="about-content">
                                <div className="content"><span className="sub-title">
                                    <img src={CommonImg[0]?.path} alt="services"/> 
                                    Services</span>
                                    <h2 className="text_size">Key Features or Benefits of a Student Visa</h2>

                                    <p>1.	You can study and work in Australia. One may work for around 40 hours every two weeks.</p>

                                    <p>2.	You can submit a short-term or long-term visa application depending on your needs. A long-term visa permits up to 5 years of stay, but a short-term visa (subclass 400) allows only 3 months.</p>

                                    <p>3.	Application for a student visa is not age-restricted.</p>

                                    <p>4.	The degree that is acknowledged around the globe.</p>

                                    <p>5.	Following graduation, you can apply for post-graduate study. Your student visa may then be extended in that situation.</p>

                                    <p>6.	Normally students are allowed to bring their immediate family members as per Department of Home Affairs guidelines.</p>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="our-mission-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-content">
                                <div className="content"><span className="sub-title">
                                    <img src={CommonImg[0]?.path} alt="services"/> 
                                    Services</span>
                                    <h3 className="text_size">Restriction of Australian Student Visa</h3>

                                    <p>1.	The number of overseas students working hours will again be regulated beginning in July 2023.</p>

                                    <p>2.	It is not possible for foreign students enrolled in Australian institutions to switch their academic programs.</p>

                                    <p>3.	A visa application might be rejected if the applicant doesn't have enough money in their bank account to live and study in Australia.</p>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-image">
                                <img className="ieltstest" src={Study[9]?.path} alt="student visa in australia"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <div className="about-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-12">
                            <div className="content contentboxheader"><span className="sub-title">
                                <img src={CommonImg[0]?.path} alt="services"/> 
                                Services</span>
                                <h3 className="text_size">Eligibility </h3>

                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="about-content">
                                <div className="content contentbox">

                                    <p>1.	The minimum age to apply for this visa as a school student is 6.</p>

                                    <p>2.	For each desired course, include a Confirmation of Enrollment (CoE) or Letter of Offer. The Department of Home Affairs cannot give you a visa until you submit your CoE. </p>

                                    <p>Providing CoE means you have to be registered for a full-time program at an institution listed on the Commonwealth Register of Institutions and Courses for Overseas Students.</p>

                                    <p>3.	You must provide all CoE codes in the application form if you are requesting a student visa for more than one course; otherwise, your visa may only cover the CoE that is given.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="about-content">
                                <div className="content">
                                    <p>4.	If you are under 18 years old and seeking a student visa, you must have sufficient welfare plans in place for your time in Australia.</p>

                                    <p>5.	At the time of submission of the visa application, you could be asked to show proof of your English language proficiency. In that case, you must have a qualified score on the English proficiency test. Those tests include- IELTS, TOEFL, PTE, etc.</p>

                                    <p>6.	Unless there is an "Exception," you and your family must be protected by Overseas Student Health Cover (OSHC) from a recognized Australian health insurance company.</p>

                                    <p>7.	You need to have sufficient funds to cover your stay in Australia.</p>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="about-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="about-img">
                                <img className="ieltstest" src={Study[40]?.path} alt="Australian student visa"/>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="about-content">
                                <div className="content"><span className="sub-title">
                                    <img src={CommonImg[0]?.path} alt="services"/> 
                                    Services</span>

                                    <h3 className="text_size">Step-by-step process of getting the Australian student visa</h3>

                                    <p><strong>Step 1:</strong> Those who are applying from outside Australia, should apply at least eight weeks before the commencement of your study.</p>

                                    <p>When you are in Australia and applying for the visa, do not wait until the very last minute before your current substantive visa ends to apply for your new visa, just in case there are any unanticipated complications</p>

                                    <p><strong>Step 2:</strong> Gather all the requisite documents and check in the Document Checklist Tool available on the Department of Home Affairs Website for cross-checking. For a precise document checklist, enter the nation where you obtained your passport and your place of higher study. The document checklist tool is updated twice a year.</p>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="our-mission-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-content">
                                <div className="content"><span className="sub-title">
                                    <img src={CommonImg[0]?.path} alt="services"/> 
                                    Services</span>

                                    <p><strong>Step 3:</strong> You must submit an application that is full and ready for judgment. Applications that are submitted without the necessary supporting documents may be rejected or delayed.</p>

                                    <p><strong>Step 4:</strong> Following your application, you must quickly reply to any information inquiries. You don't have to respond to a Departmental request for more information before the deadline.</p>

                                    <p><strong>Step 5:</strong> You will be informed of the visa grant number, the day when the visa expires, and the terms of your visa by the Department of Home Affairs.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-image">
                                <img className="ieltstest" src={Study[41]?.path} alt="Australian student visa"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="about-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="about-img">
                                <img className="ieltstest" src={Study[39]?.path} alt="Australian student visa"/>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="about-content">
                                <div className="content"><span className="sub-title">
                                    <img src={CommonImg[0]?.path} alt="services"/> 
                                    Services</span>
                                    <h3 className="text_size">Processing Timing of Australian Studen Visa (Subclass 500)</h3>

                                    <p>Although it typically takes a month, processing a student visa for Australia might take up to three months. Since the Simplified Student Visa Framework (SSVF) procedure has been simplified, the typical time required is between 4 and 6 weeks. 90% of applications are handled in 42 days for the higher education sector, and 75% are processed in 29 days.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="our-mission-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-content">
                                <div className="content"><span className="sub-title">
                                    <img src={CommonImg[0]?.path} alt="services"/> 
                                    Services</span>
                                    <h3 className="text_size">Cost of Study in Australia</h3>

                                    <p>The cost of a foreign student's education in Australia depends on the topic, university, and kind of residence they select. Because of this, the cost is affected by both geography and educational attainment.</p>

                                    <h3 className="text_content">Approximate cost of studies in Australia</h3>

                                    <p>Basic Studies: Approximately $9000 to $14,000.</p>

                                    <p>An undergraduate bachelor's degree typically pays between $15,000 and $33,000 per year.</p>

                                    <p>Laboratory-based Bachelor's Degree: Approximate Salary of $14,000–$35,000.</p>

                                    <p>Graduate Certificates for Postgraduates Graduate degrees: around $A20,000–$A37,000 each year.</p>

                                    <p>Master's or doctoral degree: around A$20,000 to A$37,000 per year.</p>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-image">
                                <img className="ieltstest" src={Image[23]?.path} alt="cost of study in australia"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}