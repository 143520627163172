import React, {
  useState,
} from "react";
import { Popover, Tooltip, Modal, Button } from "react-bootstrap";
import { validationEmail } from "../ApiComponent/Validation";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { newUserVisiting } from "../ApiComponent/Apifunction";
import { toast } from "react-toastify";

const PopUpNotify = (props) => {
  const { show, handleClose, title, body } = props;
  let urldata = window.location.pathname;
  const [state, setState] = useState({ Name: "", Email: "", Phone: "", VisaType: "", Country: "" })


  const submit = async () => {
    const { Name, Email, Phone, VisaType, Country } = state
    if (Name === "") {
      toast("Please Enter correct info for Name");
    }
    else if (Email === "") {
      toast("Please Enter correct info for Email");
    }
    else if (Phone === "") {
      toast("Please Enter correct info for Phone");
    }
    else if (Phone != "" && Phone.length < 10) {
      toast("Please Enter correct info for Phone");
    }
    else if (VisaType === "") {
      toast("Please Select Visa Type");
    }
    else if (Country === "") {
      toast("Please Select Country");
    }
    else {

      const val = await validationEmail(Email)
      if (!val) { toast("Please Enter correct info for Email"); }
      else {
        const FromUser = "info@aussieimmigration.com.au"
        const data = await newUserVisiting(Name, Email, Phone, VisaType, Country, FromUser)
        toast("Details Saved Succesfully");
        setState({ Name: "", Email: "", Phone: "", VisaType: "", Country: "" })
      }
    }
  }
  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>
            <h4 className="form_tl">Check Your Visa Eligibility For Free</h4>
            <h3 className="landingheadeing">Signup for free consultation</h3>
          </Modal.Title>
          <a onClick={handleClose}>
            <svg width="13" viewBox="6.29 6.34 11.31 11.31">
              <path
                fill="#425b76"
                d="m16.192 6.344-4.243 4.242-4.242-4.242-1.414 1.414L10.535 12l-4.242 4.242 1.414 1.414 4.242-4.242 4.243 4.242 1.414-1.414L13.364 12l4.242-4.242z"
              ></path>
            </svg>
          </a>
        </Modal.Header>
        <Modal.Body>
          <form id="contactForm">
          
            <div className="row">
              <div className="col-lg-12 col-md-6">
                <div className="form-group"><input type="text" name="name" className="form-control"
                  onChange={(e) => setState({ ...state, Name: e.target.value })}
                  value={state.Name}
                  required="" placeholder="Your name" /></div>
              </div>
              <div className="col-lg-12 col-md-6">
                <div className="form-group">
                  <input type="email" name="email"
                    onChange={(e) => setState({ ...state, Email: e.target.value })}
                    value={state.Email} className="form-control" required="" placeholder="Your email address" />
                </div>
              </div>
              <div className="col-lg-12 col-md-12">
                <div className="form-group">

                  <PhoneInput
                    country={'in'} className="form-control"
                    enableAreaCodes={true}
                    value={state.Phone}
                    onChange={phone => setState({ ...state, Phone: phone })}
                  />
                </div>
              </div>
              <div className="col-lg-12 col-md-12">
                <div className="form-group">
                  <select value={state.VisaType} className="form-control" onChange={(e) => setState({ ...state, VisaType: e.target.value })} >
                    <option >Visa Type</option>
                    <option value="Student Visa">Student Visa</option>
                    <option value="Permanent Visa">Permanent Residence</option>
                    <option value="Tourist Visa">Tourist Visa</option>
                    <option value="Spouse/Family Visa">Spouse/Family Visa</option>
                    <option value="Parent Visa">Parent Visa</option>
                    <option value="Work Visa">Work Visa</option>
                    <option value="Education Visa">Education Visa</option>
                  </select>

                </div>
              </div>
              <div className="col-lg-12 col-md-12">
                <div className="form-group" value={state.Country} onChange={(e) => setState({ ...state, Country: e.target.value })}>
                  <select className="form-control" >
                    <option >Country to Immigrate</option>
                    <option value="Australia">Australia </option>
                    <option value="UK">UK</option>
                    <option value="Canada">Canada </option>
                    <option value="USA">USA</option>
                    <option value="New Zealand">New Zealand</option>
                    <option value="Other">Other </option>
                  </select>
                </div>
              </div>
              <div className="col-lg-12 col-md-12"><div type="submit" onClick={() => {submit(); handleClose();}} className="custom-btn btn-11"><i
                className="fas fa-check"></i>Start Now</div></div>
            </div>
          </form>

        </Modal.Body>
        <Modal.Footer>
          <a className="d-flex align-items-center">
            <svg viewBox="2 2 20 20" width="14px">
              <path
                fill="#425b76"
                d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zm0 18c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8z"
              ></path>
              <path fill="#425b76" d="M11 11h2v6h-2zm0-4h2v2h-2z"></path>
            </svg>

          </a>

        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PopUpNotify;
