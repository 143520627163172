import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { CommonImg } from "../Images/CommonImg";
import SITE_PATHS from "./Path";
export default function LandingHeader() {


    // const [country, setCountry] = useState("India")
    // function success(pos) {
    //     var crd = pos.coords;
    //     console.log("Your current position is:", crd);
    //     console.log(`Latitude : ${crd.latitude}`);
    //     console.log(`Longitude: ${crd.longitude}`);
    //     console.log(`More or less ${crd.accuracy} meters.`);
    //     const myLat = crd.latitude
    //     const myLon = crd.longitude
    //     const myApiKey = "AIzaSyDnKUoXeO5VurSs3Otx48a_B0CWvnMIe8c"
    //     let address = fetch('https://maps.googleapis.com/maps/api/geocode/json?address=' + myLat + ',' + myLon + '&key=' + myApiKey)
    //         .then((response) => response.json())
    //         .then((responseJson) => {
    //             var countryName = responseJson.results[0].address_components.filter(x => x.types.filter(t => t == 'country').length > 0)[0].long_name;
    //             setCountry(countryName)
    //         })
    // }

    // useEffect(() => {
    //     navigator.geolocation.getCurrentPosition(success)
    // }, [])


    return (
        <>

            <div id="navbar" className="navbar-area">
                <div className="tarn-nav">
                    <div className="container-fluid">
                        <nav className="navbar navbar-expand-lg navbar-light">
                            <NavLink aria-current="page" to={SITE_PATHS.India} className="navbar-brand" >
                                <img className="mob_logo" src={CommonImg[13]?.path} alt="logo" /></NavLink>
                            <button className="navbar-toggler navbar-toggler-right collapsed toogleshowhide"
                                type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
                                aria-controls="navbarSupportedContent" aria-expanded="false"
                                aria-label="Toggle navigation">
                                <span className="icon-bar top-bar"></span>
                                <span className="icon-bar middle-bar"></span>
                                <span className="icon-bar bottom-bar"></span></button>

                            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                <ul className="navbar-nav">

                                    <li className="nav-item"><NavLink className="nav-link"
                                    >Contact <i
                                        className="fas fa-chevron-down"></i></NavLink>
                                        <ul className="dropdown-menu">
                                            {/* <li className="nav-item mb-2"> <div className="option-item"><a className="default-btn aus"
                                                href="tel:+61731529575"><i className="fas fa-phone-alt"></i>Australia:  (+61) 7315-29575  <span></span></a></div></li> */}
                                            <li className="nav-item"><div className="option-item"><a className="default-btn"
                                                href="tel:+918295195516"><i className="fas fa-phone-alt"></i>India:  (+91) 82951-95516 <span></span></a></div></li>
                                        </ul>
                                    </li>


                                </ul>
                                <div className="others-option d-flex align-items-center">


                                    <div className="option-item"><a className="animated-button1"
                                        href="tel:+918295195516"><i
                                            className="fas fa-phone-alt"></i> Call Now <span></span><span></span><span></span><span></span></a></div>


                                </div>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
        </>
    );
}