import english1 from "./service/english1.gif";
import english2 from "./service/english2.gif";
import english3 from "./service/english3.gif";
import ielts1 from "./service/ielts1.gif";
import ielts2 from "./service/ielts2.png";
import ielts3 from "./service/ielts3.png";
import ielts4 from "./service/ielts4.png";
import ielts5 from "./service/ielts5.png";
import pte1 from "./service/pte1.png";
import pte2 from "./service/pte2.png";
import pte3 from "./service/pte3.gif";
import pte4 from "./service/pte4.gif";

export const ServiceImage =[
    {
        id : 1,
        path : english1,
    },
    {
        id: 2,
        path : english2,
    },
    {
        id:3,
        path : english3,
    },
    {
        id:4,
        path : ielts1,
    },
    {
        id:5,
        path : ielts2,
    },
    {
        id:6,
        path : ielts3,
    },
    {
        id:7,
        path : ielts4,
    },
    {
        id:8,
        path : ielts5,
    },
   
    {
        id:9,
        path: pte1,
    },
    {
        id:10,
        path: pte2,
    },
    {
        id:11,
        path: pte3,
    },
    {
        id:12,
        path: pte4,
    },
  
]