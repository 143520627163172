import React from "react";
import { NavLink } from "react-router-dom";
import SITE_PATHS from "../../Path";
import { CommonImg } from "../../../Images/CommonImg";
import { Study } from "../../../Images/Study";
import { ServiceImage } from "../../../Images/ServiceImage";
import { Image } from "../../../Images/Image";

export default function Blog3() {


    return (
        <>

            <div className="page-title-area">
                <div className="container">
                    <div className="page-title-content">
                        <h2>Student Visa Assistance for Australia</h2>
                        <ul>
                            <li><NavLink to={SITE_PATHS.LOGOCLICK}>Home</NavLink></li>
                            <li>Blog</li>
                        </ul>
                    </div>
                </div>

                <div className="shape-img3">

                    <img src={CommonImg[1]?.path} ></img>
                </div>
                {/* <div className="shape-img3">
                    <img
                        src=""
                        alt="banner" />
                </div> */}
            </div>

            <div className="about-area pb-100 bannermargin">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="about-img"><img
                             src={Study[4]?.path} 
                                alt="service" /></div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="about-content">
                                <div className="content"><span className="sub-title"><img
                                   src={CommonImg[0]?.path} 
                                    alt="icon" /> Services</span>
                                    <h2>The step-by-step immigration process for Australia</h2>

                                    <p>Migrating to another nation and leaving his/her home nation is a huge step. A lot of information on the internet makes a person perplexed and overwhelmed. </p>

                                    <p>With various job openings in fields such as healthcare,  construction and mining, information technology, engineering, tourism, manufacturing, and accounting and finance, Australia, the world's most urbanized country, welcomes many immigrants from all over the world.</p>

                                    <p>Even corporate leaders and skilled experts can immigrate to Australia based on their specific fields of competence, intellectual achievements, and relevant expertise. The general skilled migration self-assessment test determines a person's chances of emigrating to Australia.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="our-mission-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-content">
                                <div className="content"><span className="sub-title"><img
                                   src={CommonImg[0]?.path} 
                                    alt="icon" /> Services</span>
                                    <h2>Australian Permanent Residency</h2>

                                    <p>You can live permanently in the nation using a visa that results in a Permanent Residency. Candidate must reside in Australia for four years to become a citizen. Your ability to seek Australian citizenship won't be available till after that. When you obtain an Australian PR, you will be able to bring your family members with you permanently.  Before the visa is issued, you should be informed of the benefits and conditions.</p>

                                    <p>The following simple steps will help you understand the Australian immigration process better.</p>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-image"><img
                                className="ieltstest"   src={Image[34]?.path} 
                                alt="service" /></div>
                        </div>
                    </div>
                </div>
            </div>



            <div className="about-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="about-img"><img
                                className="ieltstest"   src={ServiceImage[9]?.path}
                                alt="service" /></div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="about-content">
                                <div className="content"><span className="sub-title"><img
                                   src={CommonImg[0]?.path} 
                                    alt="icon" /> Services</span>
                                    <h2>1.	Check for the eligibility</h2>

                                    <p>It makes no difference which visa you apply for, but your profession must be listed in the skilled occupation list for you to qualify for the Australian PR visa criteria. The one who is using should be below 45yrs of age. The occupation you have applied for should be on the skilled occupation list. In the points grid, at least 65 points must be earned.</p>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="our-mission-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-content">
                                <div className="content"><span className="sub-title"><img
                                   src={CommonImg[0]?.path} 
                                    alt="icon" /> Services</span>
                                    <h2>2.	English Proficiency Test</h2>

                                    <p>There are three main English proficiency tests: IELTS, PTE, and TOEFL. These tests examine your English proficiency level for which you need to score better. To clear the mentioned tests, you must score 7 or above in IELTS, 65 or above in PTE, and 25 or above in TOEFL.</p>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-image"><img
                                className="ieltstest"   src={ServiceImage[2]?.path} 
                                alt="service" /></div>
                        </div>
                    </div>
                </div>
            </div>



            <div className="our-mission-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                            <div className="our-mission-image"><img
                                className="ieltstest"   src={ServiceImage[10]?.path}
                                alt="service" /></div>
                        </div>

                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-content">
                                <div className="content"><span className="sub-title"><img
                                   src={CommonImg[0]?.path} 
                                    alt="icon" /> Services</span>
                                    <h2>3.	SkillSelect's skills evaluation report</h2>

                                    <p>The third stage in gaining PR in Australia is determining if you will receive a positive Skills Assessment from the authority that reviews your employment. This evaluation is sometimes referred to as your "career validation acknowledgment." For each occupation, there is a separate Skills Assessment Authority.</p>

                                    <strong>The following are some:</strong>

                                    <p>a)	For Engineers- Engineers Australia is an evaluating body.</p>

                                    <p>b)	Australian Computer Society (ACS) for IT professionals.</p>

                                    <p>c)	Marketing experts - VETASSESS There are a lot more as well.</p>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="our-mission-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-content">
                                <div className="content"><span className="sub-title"><img
                                   src={CommonImg[0]?.path} 
                                    alt="icon" /> Services</span>
                                    <h2>4. Expression of Interest</h2>

                                    <p>You cannot just apply for a visa in Australia, even if you are eligible. You must first be invited by the government to get an Independent 189 visa, a Regional 190 visa, or a 491 visa, which includes states and territories.</p>

                                    <p>You can show your interest in being invited by submitting an expression of interest through the SkillSelect system, which selects applicants based on their performance on a points exam. Because of the high level of competition, you should aim for a score higher than the statutory minimum of 65 if applying for a 189 visa.</p>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-image"><img
                                className="ieltstest"   src={Study[32]?.path}
                                alt="service" /></div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="our-mission-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                            <div className="our-mission-image"><img
                                className="ieltstest"   src={Study[0]?.path}
                                alt="service" /></div>
                        </div>

                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-content">
                                <div className="content"><span className="sub-title"><img
                                   src={CommonImg[0]?.path} 
                                    alt="icon" /> Services</span>
                                    <h2>Requesting for visa</h2>

                                    <p>The next stage in obtaining a permanent visa in Australia is to prepare and submit a visa application after receiving an invitation from SkillSelect. Now is the moment to provide supporting documentation for what you asserted throughout the points test stage and Expression of Interest.</p>

                                    <p>The most outstanding choice you can make if you wish to immigrate to Australia is to deal with a Registered Migration Agent, and we would be pleased if you choose Bravo Migration for that. During our almost 14 years of service, we have worked on more than 7,000 visa applications.</p>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="our-mission-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-content">
                                <div className="content"><span className="sub-title"><img
                                   src={CommonImg[0]?.path} 
                                    alt="icon" /> Services</span>
                                    <h2>Submit your character and fitness certifications</h2>

                                    <p>For stamping your visa, you will also need to present extra papers such as your clearance certificates and passport.</p>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-image"><img
                                className="ieltstest"   src={Study[10]?.path} 
                                alt="service" /></div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="our-mission-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                            <div className="our-mission-image"><img
                                className="ieltstest"   src={Image[1]?.path}
                                alt="service" /></div>
                        </div>

                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-content">
                                <div className="content"><span className="sub-title"><img
                                   src={CommonImg[0]?.path} 
                                    alt="icon" /> Services</span>
                                    <h2>Benefits of Australian PR</h2>

                                    <p>●	This visa allows you to work and live anywhere in Australia.</p>

                                    <p>●	The visa lets you take advantage of all the health and employment advantages available to Australian permanent residents.</p>

                                    <p>●	The visa enables you to bring your family with you to Australia.</p>

                                    <p>●	The visa permits you to study in Australia for as long as you desire.</p>

                                    <p>●	You are free to travel to and from your own country whenever you wish.</p>

                                    <p>●	You may easily apply for an Australia visa and travel there whenever you like.</p>

                                    <p>●	You can get your children free schooling up to a certain age.</p>

                                    <p>●	You may pay for your relatives' Australian PR.</p>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}