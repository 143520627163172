import React from "react";
import { NavLink } from "react-router-dom";
import SITE_PATHS from "../../Path";
import { CommonImg } from "../../../Images/CommonImg";
import { Study } from "../../../Images/Study";
import { Image } from "../../../Images/Image";

export default function Course2() {


    return (
        <>

            <div className="page-title-area">
                <div className="container">
                    <div className="page-title-content">
                        <h4 className="text_size">Permanent Residence</h4>
                        <ul>
                            <li><NavLink to={SITE_PATHS.LOGOCLICK}>Home</NavLink></li>
                            <li>Permanent Residence</li>
                        </ul>
                    </div>
                </div>

                <div className="shape-img3">

                    <img src={CommonImg[1]?.path} ></img>
                </div>
            </div>

            <div className="about-area pb-100 bannermargin">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="about-img">
                                <img src={Image[24]?.path} alt="permanent residence in australia"/>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="about-content">
                                <div className="content"><span className="sub-title">
                                    <img src={CommonImg[0]?.path} alt="services"/> 
                                    Services</span>
                                    <h1 className="text_size">Permanent Residence</h1>
                                    <p>Applying for and being granted a permanent visa that enables you to stay in Australia permanently will make you a permanent resident of that country. The most prevalent permanent visas are those for family members and select skilled workers.</p>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="our-mission-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-content">
                                <div className="content"><span className="sub-title">
                                    <img src={CommonImg[0]?.path} alt="services"/> 
                                    Services</span>
                                    <h2 className="text_size">How different is Australian PR from Citizenship?</h2>
                                    <p>Both are different from each other. If you have an Australian PR, you can stay in Australia indefinitely, work and study there, enroll in Medicare, apply for bank loans to buy a house, sponsor eligible family members for permanent residency, and, if qualified, apply for Australian citizenship. You can also travel to and from Australia for as long as your travel facility allows. You can even work in New Zealand.</p>

                                    <p>You do not have a statutory right to return to Australia from abroad if you are an Australian permanent resident.</p>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-image">
                                <img className="ieltstest" src={Study[50]?.path} alt="Australian PR"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <div className="about-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="about-img">
                            <img className="ieltstest" src={Study[51]?.path} alt="Australian PR from Citizenship"/>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="about-content">
                                <div className="content"><span className="sub-title">
                                    <img src={CommonImg[0]?.path} alt="services"/> 
                                    Services</span>

                                    <p>The duration of the travel facility on your permanent visa will determine your ability to reenter Australia. Your travel facility is mentioned in both your letter notifying you of the issuance of your visa and the VEVO visa information. In "Trip facility on your permanent visa," you may find further details on how to interpret your travel validity.</p>

                                    <p>Permanent Residency doesn’t define you as a citizen of that country. You can have a PR of Australia but your citizenship will be changed only when you apply for one.</p>

                                    <p>Permanent residents normally are not permitted to:</p>

                                    <p>● You must have an Australian passport to receive student loans and to vote in Australian government elections.</p>

                                    <p>● You can’t travel to Australia without valid documents.</p>

                                    <p>● Unable to enlist in the Australian Defense Force.</p>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="our-mission-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-content">
                                <div className="content"><span className="sub-title">
                                    <img src={CommonImg[0]?.path} alt="services"/> 
                                    Services</span>
                                    <h2 className="text_size">Points needed to qualify for a PR visa</h2>

                                    <p>The minimum number of points necessary to qualify for a PR visa is 65; the more points you receive on the eligibility calculation, the better your chances are of receiving an invitation to apply for Australian permanent residency. You might be able to receive a faster opportunity to apply for PR if you receive 80 to 85 points.</p>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-image">
                                <img className="ieltstest" src={Study[9]?.path} alt="PR visa"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="about-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="about-img">
                                <img className="ieltstest" src={Study[42]?.path} alt="PR visa"/>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="about-content">
                                <div className="content"><span className="sub-title">
                                    <img src={CommonImg[0]?.path} alt="services"/> 
                                    Services</span>
                                    <h3 className="text_size">To qualify for a PR visa, applicants for the following Australian visas must have 65 points:</h3>

                                    <h4 className="text_content">1.	Skilled Independent Visa (Subclass 189)</h4>

                                    <p>The most common PR visa that enables you to relocate to Australia without a work offer or employer sponsorship is subclass 189, which is called the Skilled Independent Visa.</p>

                                    <p>To live and work permanently anywhere in Australia, invited workers, eligible New Zealanders, and suitable Hong Kong or British Nationals (Overseas) passport holders, must possess the necessary skills.</p>

                                    <p>A minimum of 90 points on the Points Grid System are required to qualify for this visa. You get full employment rights and benefits that are comparable to those of an Australian citizen.</p>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="our-mission-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-content">
                                <div className="content"><span className="sub-title">
                                    <img src={CommonImg[0]?.path} alt="services"/> 
                                    Services</span>
                                    <h3>2.	Skilled Nominated Visa (Subclass 190)</h3>

                                    <p>The Australian government must nominate an applicant for Subclass 190, generally known as the Skilled Nominated visa. It essentially serves as your one-way ticket to citizenship.</p>

                                    <p>●	It's a state-sponsored visa.</p>

                                    <p>●	Needs an offer of employment or employer sponsorship.</p>

                                    <p>●	You must already have a job offer.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-image">
                                <img className="ieltstest" src={Study[49]?.path} alt="skilled nominated visa"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="about-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="about-img">
                            <img className="ieltstest" src={Study[48]?.path} alt="choices for visa in australia"/>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="about-content">
                                <div className="content"><span className="sub-title">
                                    <img src={CommonImg[0]?.path} alt="services"/> 
                                    Services</span>
                                    <h3 className="text_size">Choices for Visa</h3>

                                    <p>There are many possibilities for people to settle in Australia permanently. There are three typical paths to obtaining permanent residency :</p>

                                    <p>1.	A family-stream permanent visa</p>
                                    <p>2.	A work-stream permanent visa</p>
                                    <p>3.	Business or investor-stream permanent visa</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="our-mission-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-content">
                                <div className="content"><span className="sub-title">
                                    <img src={CommonImg[0]?.path} alt="services"/> 
                                    Services</span>
                                    <h3 className="text_size">Let’s explain each visa:</h3>
                                    <h4 className="text_content">1. A family-stream permanent visa</h4>
                                    
                                    <p>This is for children who are being adopted or are in the process of being adopted outside of Australia partners, parents, or dependent relatives of an Australian citizen, permanent resident of Australia, or eligible New Zealand citizen children who need to move to Australia to provide long-term care for an approved relative</p>

                                    <h5 className="text_content">2. A work-stream permanent visa</h5>

                                    <p>These are for those whose employment in Australia is sponsored by an Australian company or who have skills that are in demand there.</p>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-image">
                                <img className="ieltstest" src={Study[47]?.path} alt="visas in australia"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="about-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="about-img">
                                <img className="ieltstest" src={Study[39]?.path} alt="business permanent visa"/>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="about-content">
                                <div className="content"><span className="sub-title">
                                    <img src={CommonImg[0]?.path} alt="services"/> 
                                    Services</span>
                                    <h3 className="text_size">Business or investor-stream permanent visa</h3>

                                    <p>This visa has three subclasses:</p>

                                    <h4 className="text_content">a)	Business Innovation and Investment Subclass 188 (provisional Visa)</h4>

                                    <p>This visa enables you to carry out commercial and investment activities, own and manage a firm in Australia, or engage in entrepreneurial activity there.</p>

                                    <p>Before qualifying, you must have a temporary stay and be recommended by a qualified government entity.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="our-mission-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-content">
                                <div className="content"><span className="sub-title">
                                    <img src={CommonImg[0]?.path} alt="services"/> 
                                    Services</span>
                                    <h4 className="text_content">b)	Business Innovation and Investment Subclass 888 (Permanent Visa)</h4>
                                    
                                    <p>For company owners, investors, and entrepreneurs to keep operating in Australia. You are still permitted to run and operate a business in Australia.
                                    You can do business and make investments in Australia. In Australia, you can keep up your entrepreneurial endeavors and your stay is permanent.
                                    </p>

                                    <h4 className="text_content">c)	Business Talent Subclass 132 (Permanent Visa)</h4>

                                    <p>With the help of this visa, you may launch a brand-new company in Australia or expand an already existing one. A state or territorial government organization must make the nomination on your behalf. To apply for the visa, you must be invited. You must have money or property worth more than AUD 1 million. Your stay is permanent.</p>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-image">
                                <img className="ieltstest" src={Study[46]?.path} alt="permanent visa in australia"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="about-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="about-img">
                                <img className="ieltstest" src={Study[45]?.path} alt="PR Process Steps from India"/>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="about-content">
                                <div className="content"><span className="sub-title">
                                    <img src={CommonImg[0]?.path} alt="services"/> 
                                    Services</span>
                                    <h3 className="text_size">Australian PR Process Steps from India</h3>

                                    <h4 className="text_content">1.	Check skill eligibility</h4>

                                    <p>To be eligible for an Australian PR visa, your profession must be included on the Skilled Occupation List. The age requirement is 45 or younger. Your score on the Points Grid must be at least 65 points.</p>

                                    <h5 className="text_content">2.	English Language Test</h5>

                                    <p>One of the three English language tests—the IELTS, TOEFL, or PTE—must provide a higher score for you.</p>

                                    <h6 className="text_content">3.	SkillSelect's skills evaluation report</h6>

                                    <p>The third step requires you to have your educational background and professional qualifications examined.</p>

                                    <p>Australia evaluates your international education against its Australian equivalent. Sending your certificates to Skill Select, the approved organization, will enable you to achieve this.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="our-mission-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-content">
                                <div className="content"><span className="sub-title">
                                    <img src={CommonImg[0]?.path} alt="services"/> 
                                    Services</span>
                                    <h4 className="text_content">4.	On Skill Select, submit your Expression of Interest (EOI).</h4>
                                    
                                    <p>Your path into Australian PR must begin with this crucial phase. On Australia's Skill Select site, you must submit an Expression of Interest (EOI). You are letting the Australian Government know that you are interested in applying for an Australian PR visa in this manner.
                                    </p>

                                    <h5 className="text_content">5.	Invitation to Appy (ITA)</h5>

                                    <p>The candidates that received the highest EOI ranking are sent an ITA. Your application for a PR visa will be nearly finished if you obtain an ITA.</p>
                                    
                                    <h6 className="text_content">6.	Submit PR application</h6>

                                    <p>Finally, you must apply for your Australia PR visa within 60 days of getting your ITA.</p>

                                    <h3>7.	Certificate of good health and character</h3>

                                    <p>Your character certificate and health report must be submitted.</p>

                                    <p>Prepare to relocate to Australia right now.</p>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-image">
                                <img className="ieltstest" src={Study[41]?.path} alt="service"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}