export const  validationForFormEntry = async(val) =>{
    if(val === "" || val === null)
    {
        return "Please Enter correct info for" + val;
    }
    else {
        return false
    }
}

export const  validationForSelectEntry = async(val) =>{
    if(val === "" || val === null)
    {
        return "Please select correct info for" + val;
    }
    else {
        return false
    }
}

export const validationEmail = async (val)=> {

    let regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if(!regEmail.test(val)){
      return false;
    }
    else return true
}