import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { CommonImg } from "../../Images/CommonImg";
import SITE_PATHS from "../Path";
export default function Courses() {


  return (
    <>
     <div className="page-title-area">
                <div className="container">
                    <div className="page-title-content">
                        <h2 className="text_size">Visa Types</h2>
                        <ul>
                        <li><NavLink to={SITE_PATHS.LOGOCLICK}>Home</NavLink></li>
                            <li>Visa</li>
                        </ul>
                    </div>
                </div>

                <div className="shape-img3">

                <img className="bannermargin_img" src={CommonImg[12]?.path}></img>
                </div>
             
            </div>
            <div className="courses-area ptb-100  bannermargin bg-fafafb">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <div className="single-courses-box">
                                <div className="courses-image"><NavLink  className="d-block image"
                                        to={SITE_PATHS.COURSE1}>
                                            <img src="./img/course1.jpg" alt="about"/>
                                            </NavLink> <NavLink  className="fav" to={SITE_PATHS.COURSE1}>
                                                <i className="flaticon-heart"></i></NavLink> 
                                    <div className="price shadow free">Free</div>
                                </div>
                                <div className="courses-content">
                                    <div className="course-author d-flex align-items-center">
                                        <img src="./img/course1.jpg" className="rounded-circle" alt="Student Visa"/>
                                            <span>Aussie</span>
                                    </div>
                                    <h3><NavLink  to={SITE_PATHS.COURSE1}>Student/Education Visa</NavLink></h3>
                                    
                                    <ul className="courses-box-footer d-flex justify-content-between align-items-center">
                                        <li><i className="fas fa-calendar"></i> 8 Weeks Long</li>
                                        <li><i className="fas fa-user-friends"></i> Available Now</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="single-courses-box ">
                                <div className="courses-image"><NavLink  className="d-block image"
                                        to={SITE_PATHS.COURSE2}>
                                            <img src="./img/course2.jpg" alt="about"/>
                                            </NavLink> <NavLink  className="fav" to={SITE_PATHS.COURSE2}>
                                                <i className="flaticon-heart"></i></NavLink> 
                                    <div className="price shadow">$49</div>
                                </div>
                                <div className="courses-content">
                                    <div className="course-author d-flex align-items-center">
                                <img src="./img/course1.jpg" className="rounded-circle" alt="Permanent Residence"/>
                                            <span>Aussie</span>
                                        </div>
                                    <h3><NavLink  to={SITE_PATHS.COURSE2}>Permanent Residence</NavLink></h3>
                                    
                                    <ul className="courses-box-footer d-flex justify-content-between align-items-center">
                                        <li><i className="fas fa-calendar"></i> 7 Weeks Long</li>
                                        <li><i className="fas fa-user-friends"></i> Available Now</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="single-courses-box">
                                <div className="courses-image"><NavLink  className="d-block image"
                                        to={SITE_PATHS.COURSE3}>
                                            <img src="./img/course3.jpg" alt="about"/>
                                            </NavLink> <NavLink  className="fav" to={SITE_PATHS.COURSE3}>
                                                <i className="flaticon-heart"></i></NavLink> 
                                    <div className="price shadow">$69</div>
                                </div>
                                <div className="courses-content">
                                    <div className="course-author d-flex align-items-center">
                                        <img src="./img/course1.jpg" className="rounded-circle" alt="Tourist Visa"/>
                                        <span>Aussie</span>
                                    </div>
                                    <h3><NavLink  to={SITE_PATHS.COURSE3}>Tourist Visa</NavLink></h3>
                                    
                                    <ul className="courses-box-footer d-flex justify-content-between align-items-center">
                                        <li><i className="fas fa-calendar"></i> 2 Weeks Long</li>
                                        <li><i className="fas fa-user-friends"></i> Available Now</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="single-courses-box">
                                <div className="courses-image"><NavLink  className="d-block image"
                                        to={SITE_PATHS.COURSE4}>
                                            <img src="./img/course4.jpg" alt="about"/>
                                            </NavLink> 
                                            <NavLink  className="fav"to={SITE_PATHS.COURSE4}>
                                                <i className="flaticon-heart"></i></NavLink> 
                                    <div className="price shadow">$39</div>
                                </div>
                                <div className="courses-content">
                                    <div className="course-author d-flex align-items-center">
                                        <img src="./img/course1.jpg" className="rounded-circle" alt="Family Visa"/>
                                        <span>Aussie</span>
                                    </div>
                                    <h3><NavLink  to={SITE_PATHS.COURSE4}>Spouse/Family Visa</NavLink></h3>
                                    
                                    <ul className="courses-box-footer d-flex justify-content-between align-items-center">
                                        <li><i className="fas fa-calendar"></i> 3 Weeks Long</li>
                                        <li><i className="fas fa-user-friends"></i> Available Now</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="single-courses-box">
                                <div className="courses-image"><NavLink  className="d-block image"
                                        to={SITE_PATHS.COURSE5}>
                                            <img src="./img/course5.jpg" alt="about"/>
                                            </NavLink> <NavLink  className="fav" to={SITE_PATHS.COURSE5}>
                                                <i className="flaticon-heart"></i></NavLink> 
                                    <div className="price shadow">$65</div>
                                </div>
                                <div className="courses-content">
                                    <div className="course-author d-flex align-items-center">
                                        <img src="./img/course1.jpg" className="rounded-circle" alt="Parent Visa"/>
                                        <span>Aussie</span>
                                    </div>
                                    <h3><NavLink  to={SITE_PATHS.COURSE5}>Parent Visa</NavLink></h3>
                                    
                                    <ul className="courses-box-footer d-flex justify-content-between align-items-center">
                                        <li><i className="fas fa-calendar"></i> 3 Weeks Long</li>
                                        <li><i className="fas fa-user-friends"></i> Available Now</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="single-courses-box">
                                <div className="courses-image"><NavLink  className="d-block image"
                                        to={SITE_PATHS.COURSE6}>
                                            <img src="./img/course6.jpg" alt="about"/>
                                            </NavLink> <NavLink  className="fav" to={SITE_PATHS.COURSE6}>
                                                <i className="flaticon-heart"></i></NavLink> 
                                    <div className="price shadow">$69</div>
                                </div>
                                <div className="courses-content">
                                    <div className="course-author d-flex align-items-center">
                                        <img src="./img/course1.jpg" className="rounded-circle" alt="Work Visa"/>
                                        <span>Aussie</span>
                                    </div>
                                    <h3><NavLink  to={SITE_PATHS.COURSE6}>Work Visa</NavLink></h3>
                                    
                                    <ul className="courses-box-footer d-flex justify-content-between align-items-center">
                                        <li><i className="fas fa-calendar"></i> 2 Weeks Long</li>
                                        <li><i className="fas fa-user-friends"></i> Available Now</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        {/* <div className="col-lg-4 col-md-6">
                            <div className="single-courses-box">
                                <div className="courses-image"><NavLink  className="d-block image"
                                        to={SITE_PATHS.COURSE7}>
                                            <img src="./img/course1.jpg" alt="about"/>
                                            </NavLink> <NavLink  className="fav" to={SITE_PATHS.COURSE7}>
                                                <i className="flaticon-heart"></i></NavLink> 
                                    <div className="price shadow">$69</div>
                                </div>
                                <div className="courses-content">
                                    <div className="course-author d-flex align-items-center">
                                        <img src="./img/course1.jpg" className="rounded-circle" alt="about"/>
                                        <span>Other Visa</span>
                                    </div>
                                    <h3><NavLink  to={SITE_PATHS.COURSE7}>Work Visa</NavLink></h3>
                                    
                                    <ul className="courses-box-footer d-flex justify-content-between align-items-center">
                                        <li><i className="fas fa-calendar"></i> 2 Weeks Long</li>
                                        <li><i className="fas fa-user-friends"></i> Available Now</li>
                                    </ul>
                                </div>
                            </div>
                        </div> */}

                        <div className="col-lg-4 col-md-6">
                            <div className="single-courses-box">
                                <div className="courses-image"><NavLink  className="d-block image"
                                        to={SITE_PATHS.COURSE8}>
                                            <img src="./img/course3.jpg" alt="about"/>
                                            </NavLink> <NavLink  className="fav" to={SITE_PATHS.COURSE8}>
                                                <i className="flaticon-heart"></i></NavLink> 
                                    <div className="price shadow">$69</div>
                                </div>
                                <div className="courses-content">
                                    <div className="course-author d-flex align-items-center">
                                        <img src="./img/course1.jpg" className="rounded-circle" alt="Education Visa"/>
                                        <span>Other Visa</span>
                                    </div>
                                    <h3><NavLink  to={SITE_PATHS.COURSE8}>Education Visa</NavLink></h3>
                                    
                                    <ul className="courses-box-footer d-flex justify-content-between align-items-center">
                                        <li><i className="fas fa-calendar"></i> 2 Weeks Long</li>
                                        <li><i className="fas fa-user-friends"></i> Available Now</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-12 col-md-12">
                            <div className="pagination-area text-center"><NavLink  className="prev page-numbers"
                                   to={SITE_PATHS.LOGOCLICK}><i className="fas fa-chevron-left"></i></NavLink> 
                                        {/* <span
                                    className="page-numbers current">1</span><NavLink  className="page-numbers"
                                   to={SITE_PATHS.LOGOCLICK}>2</NavLink> <NavLink  className="page-numbers"
                                   to={SITE_PATHS.LOGOCLICK}>3</NavLink> <NavLink  className="page-numbers"
                                   to={SITE_PATHS.LOGOCLICK}>4</NavLink> <NavLink  className="next page-numbers"
                                   to={SITE_PATHS.LOGOCLICK}><i
                                        className="bx bx-chevrons-right"></i></NavLink>  */}
                                        </div>
                        </div>
                    </div>
                </div>
                {/* <div className="vector-shape6"><img
                        src="./img/circle-shape1-01ae53caad7e9930c72205eddba707d3.png"
                        alt="about"/></div> */}
            </div>
    </>
  );
}