

import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom/dist";
import Footer from "./component/Footer";
import Header from "./component/Header";
import LandingFooter from "./component/LandingFooter";
import LandingHeader from "./component/LandingHeader";
import LandingHeaderAus from "./component/LandingHeaderAus";
import RouterComponent from "./Router/RouterComponent";
import ReactGA from 'react-ga';
const TRACKING_ID = "UA-246535217-1"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

export default function App() {
  let urldata = window.location.pathname;
  console.log("urldata", urldata)
  const location = useLocation();
  useEffect(() => {
    const url = urldata.split("/")
    console.log("url", url[1])
   
    if(url[1] === "dashboard"){
      document.title ="Aussie Immigration - Overseas Migration & Consulting Experts"; 
      document.getElementsByTagName("META")[4].content="Registered Migration Agents & a skilled team of consultants in Aussie Immigration provide personnel assistance immigration & Visa services for both organizations and individuals.";
      document.getElementsByTagName("META")[5].content="ielts, pte, spoken english, student visa in australia";
      document.getElementsByTagName("link")[1].href="https://www.aussieimmigration.com.au/" + url[1];
      document.getElementsByTagName("link")[2].href="https://www.aussieimmigration.com.au/" + url[1];
    }
    else if(url[1] === "about"){
      document.title ="About Aussie Immigration Consultancy"; 
      document.getElementsByTagName("META")[4].content="Aussie Immigration is a platform where skilled and knowledgeable specialists help their clients with immigration, migration and visa services.";
      document.getElementsByTagName("META")[5].content="ielts, pte, spoken english, student visa in australia";
      document.getElementsByTagName("link")[1].href="https://www.aussieimmigration.com.au/" + url[1];
      document.getElementsByTagName("link")[2].href="https://www.aussieimmigration.com.au/" + url[1];
    }

    else if(url[1] === "contact"){
      document.title ="Contact Us Today For The Best Immigration Service  | Aussie Immigration"; 
      document.getElementsByTagName("META")[4].content="Have any queries related to immigration services or do you want to contact an expert? Contact Aussie Immigration via mail or call for the best advice and service.";
      document.getElementsByTagName("META")[5].content="aussie immigration";
      document.getElementsByTagName("link")[1].href="https://www.aussieimmigration.com.au/" + url[1];
      document.getElementsByTagName("link")[2].href="https://www.aussieimmigration.com.au/" + url[1];
    }

    else if(url[1] === "faq"){
      document.title ="FAQ For Immigration | Aussie Immigration Consultancy"; 
      document.getElementsByTagName("META")[4].content="Some fundamental standard inquiries emerge before utilizing any immigration & visa services. Ask us any questions you have; we have all the answers.";
      document.getElementsByTagName("META")[5].content="ielts, pte, spoken english, student visa in australia";
      document.getElementsByTagName("link")[1].href="https://www.aussieimmigration.com.au/" + url[1];
      document.getElementsByTagName("link")[2].href="https://www.aussieimmigration.com.au/" + url[1];
    }

    else if(url[1] === "team"){
      document.title ="team Page"; 
      document.getElementsByTagName("META")[4].content="team US the page or site here to set dynamically";
      document.getElementsByTagName("META")[5].content="team key the page or site here to set dynamically";
      document.getElementsByTagName("link")[1].href="https://www.aussieimmigration.com.au/" + url[1];
      document.getElementsByTagName("link")[2].href="https://www.aussieimmigration.com.au/" + url[1];
    }

    else if(url[1] === "ielts"){
      document.title ="IELTS Offline & Online Preparation | Aussie Immigration Consultancy"; 
      document.getElementsByTagName("META")[4].content="Want to study Overseas? Aussie Immigration Consultancy helps you. Take our IELTS courses and crack your IELTS exam in one go. 100% result guaranteed.";
      document.getElementsByTagName("META")[5].content="ielts key the page or site here to set dynamically";
      document.getElementsByTagName("link")[1].href="https://www.aussieimmigration.com.au/" + url[1];
      document.getElementsByTagName("link")[2].href="https://www.aussieimmigration.com.au/" + url[1];
    }

    else if(url[1] === "pte"){
      document.title ="Top-Notch PTE Test Assistance Service | Aussie Immigration"; 
      document.getElementsByTagName("META")[4].content="Looking to prepare for your PTE Test? Aussie Immigration can help! We offer PTE Test Preparation courses that will help you achieve your best possible score.";
      document.getElementsByTagName("META")[5].content="ielts key the page or site here to set dynamically";
      document.getElementsByTagName("link")[1].href="https://www.aussieimmigration.com.au/" + url[1];
      document.getElementsByTagName("link")[2].href="https://www.aussieimmigration.com.au/" + url[1];
    }

    else if(url[1] === "spoken-english"){
      document.title ="Spoken English Training | Aussie Immigration"; 
      document.getElementsByTagName("META")[4].content="Looking for the best spoken English training? Look no further than Aussie Immigration. We offer a variety of courses to suit your needs.";
      document.getElementsByTagName("META")[5].content="spoken english";
      document.getElementsByTagName("link")[1].href="https://www.aussieimmigration.com.au/" + url[1];
      document.getElementsByTagName("link")[2].href="https://www.aussieimmigration.com.au/" + url[1];
    }

    else if(url[1] === "Study-Visa-In-Australia"){
      document.title ="Study Visa in Australia For Students | Aussie Immigration"; 
      document.getElementsByTagName("META")[4].content="Looking to study in Australia? Aussie Immigration Consultancy can help! We provide expert advice on all types of study visas, so you can get the most out of your Australian education.";
      document.getElementsByTagName("META")[5].content="study visa in australia";
      document.getElementsByTagName("link")[1].href="https://www.aussieimmigration.com.au/" + url[1];
      document.getElementsByTagName("link")[2].href="https://www.aussieimmigration.com.au/" + url[1];
    }

    else if(url[1] === "Study-Visa-In-Canada"){
      document.title ="Study Visa in Canada For Students | Aussie Immigration Consultancy"; 
      document.getElementsByTagName("META")[4].content="Want to study in Canada? Choose Aussie Immigration Consultancy! Our team will help you to get a Canada student visa easily. Let us help you achieve your dream of studying in Canada!";
      document.getElementsByTagName("META")[5].content="Study-Visa-In-Canada";
      document.getElementsByTagName("link")[1].href="https://www.aussieimmigration.com.au/" + url[1];
      document.getElementsByTagName("link")[2].href="https://www.aussieimmigration.com.au/" + url[1];
    }

    else if(url[1] === "Study-Visa-In-UK"){
      document.title ="Study Visa in UK For Students | Aussie Immigration Consultancy"; 
      document.getElementsByTagName("META")[4].content="Want to study in the UK? Aussie Immigration Consultancy offers study visas for students to study in the UK. Let us help you make the most of your time in the UK!";
      document.getElementsByTagName("META")[5].content="Study-Visa-In-UK";
      document.getElementsByTagName("link")[1].href="https://www.aussieimmigration.com.au/" + url[1];
      document.getElementsByTagName("link")[2].href="https://www.aussieimmigration.com.au/" + url[1];
    }

    else if(url[1] === "Study-Visa-In-USA"){
      document.title ="Student Visa For USA | Aussie Immigration Consultancy"; 
      document.getElementsByTagName("META")[4].content="Looking to study in the USA? Aussie Immigration Consultancy is the best stop to get a USA student visa! We offer a variety of services to make the process as easy as possible for you.";
      document.getElementsByTagName("META")[5].content="Study-Visa-In-USA";
      document.getElementsByTagName("link")[1].href="https://www.aussieimmigration.com.au/" + url[1];
      document.getElementsByTagName("link")[2].href="https://www.aussieimmigration.com.au/" + url[1];
    }

    else if(url[1] === "Study-Visa-In-NewZealand"){
      document.title ="Student Visa for New Zealand | Aussie Immigration Consultancy"; 
      document.getElementsByTagName("META")[4].content="Looking to study in New Zealand? Aussie Immigration Consultancy can help with your student visa application with the help of experienced professionals.";
      document.getElementsByTagName("META")[5].content="Study-Visa-In-NewZealand";
      document.getElementsByTagName("link")[1].href="https://www.aussieimmigration.com.au/" + url[1];
      document.getElementsByTagName("link")[2].href="https://www.aussieimmigration.com.au/" + url[1];
    }

    else if(url[1] === "course"){
      document.title ="Best Visa Services for Migrating to Australia"; 
      document.getElementsByTagName("META")[4].content="Aussie Immigration Consultancy provides professional visa services for migrating to Australia. We offer a wide range of visas, including study, work, and family visas.";
      document.getElementsByTagName("META")[5].content="visa";
      document.getElementsByTagName("link")[1].href="https://www.aussieimmigration.com.au/" + url[1];
      document.getElementsByTagName("link")[2].href="https://www.aussieimmigration.com.au/" + url[1];
    }

    else if(url[1] === "Student-Visa"){
      document.title ="Student Visa To Study Abroad | Aussie Immigration Consultancy"; 
      document.getElementsByTagName("META")[4].content="Looking for a student visa to study abroad? Aussie Immigration Consultancy offers Australia study visas for students, so you can study in Australia with ease.";
      document.getElementsByTagName("META")[5].content="Student-Visa";
      document.getElementsByTagName("link")[1].href="https://www.aussieimmigration.com.au/" + url[1];
      document.getElementsByTagName("link")[2].href="https://www.aussieimmigration.com.au/" + url[1];
    }

    else if(url[1] === "Permanent-Residence"){
      document.title ="Permanent Residence In Australia | Aussie Immigration Consultancy"; 
      document.getElementsByTagName("META")[4].content="Looking to secure permanent residency in Australia? Aussie Immigration Consultancy can help. Contact us today and our expert will help you to get secure PR.";
      document.getElementsByTagName("META")[5].content="Permanent-Residence";
      document.getElementsByTagName("link")[1].href="https://www.aussieimmigration.com.au/" + url[1];
      document.getElementsByTagName("link")[2].href="https://www.aussieimmigration.com.au/" + url[1];
    }

    else if(url[1] === "Tourist-Visa"){
      document.title ="Tourist Visa For Australia | Aussie Immigration Consultancy"; 
      document.getElementsByTagName("META")[4].content="Want to travel to Australia on a tourist visa? Aussie Immigration Consultancy can help! We offer tourist visas for Australia for citizens of a number of countries.";
      document.getElementsByTagName("META")[5].content="Tourist-Visa";
      document.getElementsByTagName("link")[1].href="https://www.aussieimmigration.com.au/" + url[1];
      document.getElementsByTagName("link")[2].href="https://www.aussieimmigration.com.au/" + url[1];
    }
    
    else if(url[1] === "Family-Visa"){
      document.title ="Spouse Visa and Family Visa | Aussie Immigration Consultancy"; 
      document.getElementsByTagName("META")[4].content="Aussie Immigration Consultancy offers temporary and permanent spouse and family visa options to help you reunite with your loved ones. Apply today to get it!";
      document.getElementsByTagName("META")[5].content="Family-Visa";
      document.getElementsByTagName("link")[1].href="https://www.aussieimmigration.com.au/" + url[1];
      document.getElementsByTagName("link")[2].href="https://www.aussieimmigration.com.au/" + url[1];
    }

    else if(url[1] === "Parent-Visa"){
      document.title ="Parent Visa to Migrate | Aussie Immigration Consultancy"; 
      document.getElementsByTagName("META")[4].content="Want a parent visa? Aussie Immigration Consultancy offers both temporary and permanent parent visas, making it easy for you and your family to migrate to Australia.";
      document.getElementsByTagName("META")[5].content="Parent-Visa";
      document.getElementsByTagName("link")[1].href="https://www.aussieimmigration.com.au/" + url[1];
      document.getElementsByTagName("link")[2].href="https://www.aussieimmigration.com.au/" + url[1];
    }

    else if(url[1] === "Work-Visa"){
      document.title ="Work Visa Services | Aussie Immigration Consultancy"; 
      document.getElementsByTagName("META")[4].content="Aussie Immigration consultancy offers professional work visa services to help you get the right visa for your needs. We have a variety of visas, so you can find the perfect fit for you.";
      document.getElementsByTagName("META")[5].content="Work-Visa";
      document.getElementsByTagName("link")[1].href="https://www.aussieimmigration.com.au/" + url[1];
      document.getElementsByTagName("link")[2].href="https://www.aussieimmigration.com.au/" + url[1];
    }

    else if(url[1] === "Education-Visa"){
      document.title ="Education-Visa Page"; 
      document.getElementsByTagName("META")[4].content="Education-Visa US the page or site here to set dynamically";
      document.getElementsByTagName("META")[5].content="Education-Visa key the page or site here to set dynamically";
      document.getElementsByTagName("link")[1].href="https://www.aussieimmigration.com.au/" + url[1];
      document.getElementsByTagName("link")[2].href="https://www.aussieimmigration.com.au/" + url[1];
    }

    else if(url[1] === "IEducation-System-in-Australia"){
      document.title ="Education-System-in-Australia Page"; 
      document.getElementsByTagName("META")[4].content="Education-System-in-Australia US the page or site here to set dynamically";
      document.getElementsByTagName("META")[5].content="Education-System-in-Australia key the page or site here to set dynamically";
      document.getElementsByTagName("link")[1].href="https://www.aussieimmigration.com.au/" + url[1];
      document.getElementsByTagName("link")[2].href="https://www.aussieimmigration.com.au/" + url[1];
    }

    else if(url[1] === "Entry-Requirements-Australian-Universities"){
      document.title ="Entry-Requirements-Australian-Universities Page"; 
      document.getElementsByTagName("META")[4].content="Entry-Requirements-Australian-Universities US the page or site here to set dynamically";
      document.getElementsByTagName("META")[5].content="Entry-Requirements-Australian-Universities key the page or site here to set dynamically";
      document.getElementsByTagName("link")[1].href="https://www.aussieimmigration.com.au/" + url[1];
      document.getElementsByTagName("link")[2].href="https://www.aussieimmigration.com.au/" + url[1];
    }

    else if(url[1] === "service"){
      document.title ="service Page"; 
      document.getElementsByTagName("META")[4].content="service US the page or site here to set dynamically";
      document.getElementsByTagName("META")[5].content="service key the page or site here to set dynamically";
      document.getElementsByTagName("link")[1].href="https://www.aussieimmigration.com.au/" + url[1];
      document.getElementsByTagName("link")[2].href="https://www.aussieimmigration.com.au/" + url[1];
    }

    else if(url[1] === "our-policy"){
      document.title ="our-policy Page"; 
      document.getElementsByTagName("META")[4].content="our-policy US the page or site here to set dynamically";
      document.getElementsByTagName("META")[5].content="our-policy key the page or site here to set dynamically";
      document.getElementsByTagName("link")[1].href="https://www.aussieimmigration.com.au/" + url[1];
      document.getElementsByTagName("link")[2].href="https://www.aussieimmigration.com.au/" + url[1];
    }

    else if(url[1] === "history"){
      document.title ="history Page"; 
      document.getElementsByTagName("META")[4].content="history US the page or site here to set dynamically";
      document.getElementsByTagName("META")[5].content="history key the page or site here to set dynamically";
      document.getElementsByTagName("link")[1].href="https://www.aussieimmigration.com.au/" + url[1];
      document.getElementsByTagName("link")[2].href="https://www.aussieimmigration.com.au/" + url[1];
    }

    else if(url[1] === "india"){
      document.title ="Overseas Education, Migration & Immigration Experts | Aussie Immigration India"; 
      document.getElementsByTagName("META")[4].content="Looking for immigration & migration experts? Aussie Immigration Consultancy helps you. Get free counseling for overseas education, immigration & migration services.";
      document.getElementsByTagName("META")[5].content="aussie immigration, ielts, pte, spoken english";
      document.getElementsByTagName("link")[1].href="https://www.aussieimmigration.com.au/" + url[1];
      document.getElementsByTagName("link")[2].href="https://www.aussieimmigration.com.au/" + url[1];
    }

    else if(url[1] === "thank-you"){
      document.title ="Thank You For Submitting | Aussie Immigration Consultancy"; 
      document.getElementsByTagName("META")[4].content="Thanks for submitting the contact form for Aussie Immigration Consultancy. Our expert team will contact you as soon as possible and solve your query.";
      document.getElementsByTagName("META")[5].content="thank you";
      document.getElementsByTagName("link")[1].href="https://www.aussieimmigration.com.au/" + url[1];
      document.getElementsByTagName("link")[2].href="https://www.aussieimmigration.com.au/" + url[1];
    }
   
   else{
    document.title ="Aussie Immigration - Overseas Migration & Consulting Experts"; 
    document.getElementsByTagName("META")[4].content="Registered Migration Agents & a skilled team of consultants in Aussie Immigration provide personnel assistance immigration & Visa services for both organizations and individuals.";
    document.getElementsByTagName("META")[5].content="ielts, pte, spoken english, student visa in australia";
    document.getElementsByTagName("link")[1].href="https://www.aussieimmigration.com.au/" + url[1];
    document.getElementsByTagName("link")[2].href="https://www.aussieimmigration.com.au/" + url[1];
    }
   }, [location]);

  return <>

    {urldata === "/India" || urldata === "/india" || urldata === "/INDIA" || urldata === "/iNDIA" ?
     <div id="___gatsby">
        <div className="main_outline" tabindex="-1" id="gatsby-focus-wrapper">
          <div></div>
          <LandingHeader />
          <RouterComponent />
          <LandingFooter />
        </div>
     </div>
      : 
      urldata === "/Aus" || urldata === "/aus" || urldata === "/AUs" || urldata === "/AUS" 
      ||  urldata === "/AuS" ||  urldata === "/aUS" || urldata === "/auS"? 
      <div id="___gatsby">
        <div className="main_outline" tabindex="-1" id="gatsby-focus-wrapper">
          <div></div>
          <LandingHeaderAus />
          <RouterComponent />
          <LandingFooter />
        </div>
      </div>

    :
    urldata === "/Login" || urldata === "/login" ? 
      <div id="___gatsby">
        <div className="main_outline" tabindex="-1" id="gatsby-focus-wrapper">
          <div></div>
          <RouterComponent />
        </div>
      </div>
      :
    <div id="___gatsby">
      <div className="main_outline" tabindex="-1" id="gatsby-focus-wrapper">
        <div></div>
        <Header />
        <RouterComponent />
        <Footer />
      </div>
    </div>
  }

  </>;
}