import React, { useEffect, useState } from "react";

export default function ServicesWithDetails() {


  return (
    <>
     <div className="page-title-area">
                <div className="container">
                    <div className="page-title-content">
                        <h4 className="text_size">Course Grid</h4>
                        <ul>
                            <li><a href="https://aussieimmigration.com.au/In">Home</a></li>
                            <li>Course Grid</li>
                        </ul>
                    </div>
                </div>
              
            </div>
            <div className="courses-area ptb-100 bannermargin bg-fafafb">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <div className="single-courses-box">
                                <div className="courses-image"><a className="d-block image"
                                    href="https://aussieimmigration.com.au/Incourse-details">
                                        <img src="./img/course1-d4136c63f03383d3de27042a6347cfa9.jpg" alt="about"/>
                                            </a><a className="fav"
                                        href="https://aussieimmigration.com.au/Incourses/#"><i
                                            className="flaticon-heart"></i></a>
                                    <div className="price shadow free">Free</div>
                                </div>
                                <div className="courses-content">
                                    <div className="course-author d-flex align-items-center">
                                        <img src="./img/user1-583aae6ee13e3e467de6f0da94af921e.jpg" className="rounded-circle" alt="about"/>
                                        <span>Alex Morgan</span>
                                    </div>
                                    <h3><a href="https://aussieimmigration.com.au/Incourse-details">Introduction to
                                            Quantitative Methods</a></h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                        incididunt ut labore et dolore.</p>
                                    <ul className="courses-box-footer d-flex justify-content-between align-items-center">
                                        <li><i className="flaticon-agendas"></i> 8 Weeks Long</li>
                                        <li><i className="flaticon-team"></i> Available Now</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="single-courses-box ">
                                <div className="courses-image"><a className="d-block image"
                                        href="https://aussieimmigration.com.au/Incourse-details">
                                         <img src="./img/course2-b3045ddb403d005ebd09d765fdb0747c.jpg" alt="about"/>
                                            </a>
                                            <a className="fav" href="https://aussieimmigration.com.au/Incourses #">
                                            <i className="flaticon-heart"></i></a>
                                    <div className="price shadow">$49</div>
                                </div>
                                <div className="courses-content">
                                    <div className="course-author d-flex align-items-center">
                                        <img src="./img/user2-181890da7459bae6c23454cf7121ed65.jpg"
                                            className="rounded-circle" alt="about"/>
                                            <span>Sarah Taylor</span>
                                    </div>
                                    <h3><a href="https://aussieimmigration.com.au/Incourse-details">Introduction to
                                            Linear Models and Matrix Algebra</a></h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                        incididunt ut labore et dolore.</p>
                                    <ul className="courses-box-footer d-flex justify-content-between align-items-center">
                                        <li><i className="flaticon-agendas"></i> 7 Weeks Long</li>
                                        <li><i className="flaticon-team"></i> Available Now</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="single-courses-box">
                                <div className="courses-image"><a className="d-block image"
                                    href="https://aussieimmigration.com.au/Incourse-details">
                                        <img src="./img/course3-a1ce0bbd14877b6ba15dce3c08acb179.jpg" alt="about"/>
                                        </a><a className="fav" href="https://aussieimmigration.com.au/Incourses/#">
                                            <i className="flaticon-heart"></i></a>
                                    <div className="price shadow">$69</div>
                                </div>
                                <div className="courses-content">
                                    <div className="course-author d-flex align-items-center">
                                        <img src="./img/user3-d89e5759c382a5e0aae660cb3efcbd83.jpg"
                                            className="rounded-circle" alt="about"/>
                                            <span>David Warner</span>
                                    </div>
                                    <h3><a href="https://aussieimmigration.com.au/Incourse-details">Data Science:
                                            Inference and Modeling</a></h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                        incididunt ut labore et dolore.</p>
                                    <ul className="courses-box-footer d-flex justify-content-between align-items-center">
                                        <li><i className="flaticon-agendas"></i> 2 Weeks Long</li>
                                        <li><i className="flaticon-team"></i> Available Now</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="single-courses-box">
                                <div className="courses-image"><a className="d-block image"
                                    href="https://aussieimmigration.com.au/Incourse-details">
                                        <img src="./img/course4-8dad7c1d61195012ad6dfca253caefd8.jpg"alt="about"/>
                                        </a><a className="fav" href="https://aussieimmigration.com.au/Incourses/#">
                                            <i className="flaticon-heart"></i></a>
                                    <div className="price shadow">$39</div>
                                </div>
                                <div className="courses-content">
                                    <div className="course-author d-flex align-items-center">
                                        <img src="./img/user4-41530a7019a90cc32b995758eabc5a9a.jpg"
                                            className="rounded-circle" alt="about"/>
                                            <span>Alex Morgan</span>
                                    </div>
                                    <h3><a href="https://aussieimmigration.com.au/Incourse-details">The Data Science
                                            Course: Complete Data Science</a></h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                        incididunt ut labore et dolore.</p>
                                    <ul className="courses-box-footer d-flex justify-content-between align-items-center">
                                        <li><i className="flaticon-agendas"></i> 3 Weeks Long</li>
                                        <li><i className="flaticon-team"></i> Available Now</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="single-courses-box">
                                <div className="courses-image"><a className="d-block image"
                                    href="https://aussieimmigration.com.au/Incourse-details">
                                        <img src="./img/course5-5c84d83d38982f110b080ef951c6f544.jpg" alt="about"/>
                                            </a><a className="fav" href="https://aussieimmigration.com.au/Incourses/#"><i className="flaticon-heart"></i></a>
                                    <div className="price shadow">$65</div>
                                </div>
                                <div className="courses-content">
                                    <div className="course-author d-flex align-items-center">
                                        <img src="./img/user5-57e29bd09f31f52fdc2fa48585469c3f.jpg"
                                            className="rounded-circle" alt="about"/>
                                            <span>David Warner</span>
                                    </div>
                                    <h3><a href="https://aussieimmigration.com.au/Incourse-details">Java Programming
                                            Masterclass for Developers</a></h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                        incididunt ut labore et dolore.</p>
                                    <ul className="courses-box-footer d-flex justify-content-between align-items-center">
                                        <li><i className="flaticon-agendas"></i> 3 Weeks Long</li>
                                        <li><i className="flaticon-team"></i> Available Now</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="single-courses-box">
                                <div className="courses-image"><a className="d-block image"
                                    href="https://aussieimmigration.com.au/Incourse-details">
                                        <img src="./img/course6-448e3db27f6c79de06dc7c5fd171952a.jpg" alt="about"/>
                                        </a><a className="fav" href="https://aussieimmigration.com.au/Incourses/#">
                                            <i className="flaticon-heart"></i></a>
                                    <div className="price shadow">$69</div>
                                </div>
                                <div className="courses-content">
                                    <div className="course-author d-flex align-items-center">
                                        <img src="./img/user6-bd69b599a702aae1f157d5a18317712e.jpg" className="rounded-circle" alt="about"/>
                                            <span>David Warner</span>
                                </div>
                                    <h3><a href="https://aussieimmigration.com.au/Incourse-details">Machine Learning
                                            A-Z™: Hands-On Python</a></h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                        incididunt ut labore et dolore.</p>
                                    <ul className="courses-box-footer d-flex justify-content-between align-items-center">
                                        <li><i className="flaticon-agendas"></i> 2 Weeks Long</li>
                                        <li><i className="flaticon-team"></i> Available Now</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12 col-md-12">
                            <div className="pagination-area text-center"><a className="prev page-numbers"
                                    href="https://aussieimmigration.com.au/Incourses/#"><i
                                        className="bx bx-chevrons-left"></i></a><span
                                    className="page-numbers current">1</span><a className="page-numbers"
                                    href="https://aussieimmigration.com.au/Incourses/#">2</a><a className="page-numbers"
                                    href="https://aussieimmigration.com.au/Incourses/#">3</a><a className="page-numbers"
                                    href="https://aussieimmigration.com.au/Incourses/#">4</a><a className="next page-numbers"
                                    href="https://aussieimmigration.com.au/Incourses/#"><i
                                        className="bx bx-chevrons-right"></i></a></div>
                        </div>
                    </div>
                </div>
                <div className="vector-shape6">
                    <img src="./img/vector-shape6-ed1e8bd8932bdbab40485493b5d6071f.png" alt="about"/>
                </div>
            </div>
    </>
  );
}