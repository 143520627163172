import React from "react";
import { NavLink } from "react-router-dom";
import { CommonImg } from "../../Images/CommonImg";
import { Image } from "../../Images/Image";
import { Study } from "../../Images/Study";
import SITE_PATHS from "../Path";

export default function AboutUs() {


    return (
        <>

            <div className="page-title-area">
                <div className="container">
                    <div className="page-title-content">
                        <h4 className="text_size">About</h4>
                        <ul>
                            <li><NavLink to={SITE_PATHS.LOGOCLICK}>Home</NavLink></li>
                            <li>About Us</li>
                        </ul>
                    </div>
                </div>

                <div className="shape-img3">
                    <img src={CommonImg[1]?.path} alt="banner"></img>
                </div>
            </div>

            <section className="about-area ptb-100 bannermargin">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="about-image">
                                <img src={Study[5]?.path} alt="banner"/>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="about-content">
                                <div className="content"><span className="sub-title">
                                    <img src={CommonImg[0]?.path} alt="banner"/>
                                    About Us</span>
                                    <h1 className="text_size">Aussie Immigration Consultancy</h1>
                                    <p>Aussie Immigration Consultancy is productive, respects your time, and provides results quickly. We understand the fear people face while thinking of the visa process. We offer the required assistance because obtaining a visa might be challenging. We support you throughout the whole visa application procedure, starting at the very beginning. The Department of Home Affairs in Australia requires various lengthy, intricate forms, which we compile and complete for you.</p>
                                    <ul className="features-list">
                                        <li><img src={Image[35]?.path} alt="banner"/>
                                            <h3>10 Years</h3>
                                            <p>On the market</p>
                                        </li>
                                        <li><img src={Image[36]?.path} alt="banner"/>
                                            <h3>45+</h3>
                                            <p>Team members</p>
                                        </li>
                                        <li><img src={Image[37]?.path} alt="banner"/>
                                            <h3>100%</h3>
                                            <p>Satisfaction rate</p>
                                        </li>
                                        <li><img src={Image[38]?.path} alt="banner"/>
                                            <h3>80%</h3>
                                            <p>Senior scientist</p>
                                        </li>
                                    </ul>
                                    <p>Our goal is to give you the proper paperwork for whatever sort of visa you could need to immigrate to Australia.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className="circle-shape1">
                    <img src={Image[20]?.path} alt="banner"/>
                </div>
                <div className="container">
                    <div className="about-inner-area">
                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className="about-text">
                                    <h3 className="about_text">Our History</h3>
                                    <p>Real innovations and a positive customer experience are the heart of successful
                                        communication.</p>
                                    <ul className="features-list">
                                        <li><i className="fas fa-check"></i> Activate Listening</li>
                                        <li><i className="fas fa-check"></i> Brilliant minds</li>
                                        <li><i className="fas fa-check"></i> Better, Best, Wow!</li>
                                        <li><i className="fas fa-check"></i> Branding it better!</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className="about-text">
                                    <h3 className="about_text">Our Mission</h3>
                                    <p>Our primary goal is continuously to provide immigration and visa services to our clients through our expertise and experience. Our offerings.</p>
                                    <ul className="features-list">
                                        <li><i className="fas fa-check"></i> IELTS, PTE, Spoken English, etc. preparation.</li>
                                        <li><i className="fas fa-check"></i> Legal visa Documentation.</li>
                                        <li><i className="fas fa-check"></i> Uninterrupted Visa Service.</li>
                                        <li><i className="fas fa-check"></i> Expert Counselor.</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                                <div className="about-text">
                                    <h3 className="about_text">Who we are</h3>
                                    <p>We are a group of skilled visa specialists that are aware of every procedure needed to obtain your visa.</p>
                                    <ul className="features-list">
                                        <li><i className="fas fa-check"></i> Ease your visa process.</li>
                                        <li><i className="fas fa-check"></i> Respect your time.</li>
                                        <li><i className="fas fa-check"></i> Follow all legal procedures.</li>
                                        <li><i className="fas fa-check"></i> Supportive experts.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="circle-shape1">
                    <img src={Image[20]?.path} alt="banner" />
                </div>
            </section>
        </>
    );
}