import React, { useEffect, useState } from "react";
import { Study } from "../../Images/Study";
import { CommonImg } from "../../Images/CommonImg";
import SITE_PATHS from "../Path";
import { NavLink } from "react-router-dom";
export default function Study_Visa_In_UK() {


    return (
        <>
            <div className="page-title-area">
                <div className="container">
                    <div className="page-title-content">
                        <h4 className="text_size">UK</h4>
                        <ul>
                            <li><NavLink to={SITE_PATHS.LOGOCLICK}>Home</NavLink></li>
                            <li>Study Visa In UK</li>
                        </ul>
                    </div>
                </div>

                <div className="shape-img3">

                    <img src={Study[28]?.path}></img>
                </div>
              
            </div>
            <div className="about-area pb-100 bannermargin">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="about-img">
                                <img src={Study[23]?.path} alt="service"/>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="about-content">
                                <div className="content"><span className="sub-title">
                                    <img src={CommonImg[0]?.path} alt="services"/> 
                                    Services</span>
                                    <h1 className="text_size">Student visa for UK</h1>
                                    <p>Studying in the UK is the dream of almost every student. Every year thousands of students enrolled in UK universities. As the UK is known for its high-class education system, most students prefer studying there.</p>
                                    <p>If you have a British degree in your hand your chances of getting a job to become double. Most of the universities in the UK rank among the top 10 in the world. Most of the students from overseas study there. If you dream of studying in the UK, Aussie Immigration Consultancy is with you.</p>
                                    <p>We will help with the visa process and our agents will make your work easy and faster. You can trust us we have decades of experience and the rejection rate is also very low.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="our-mission-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-content">
                                <div className="content"><span className="sub-title">
                                    <img src={CommonImg[0]?.path} alt="services"/> 
                                    Services</span>
                                    <h2 className="text_size">Confirmation of Acceptance for Studies (CAS)</h2>
                                    <p>Confirmation of Acceptance for Studies, or CAS, stands for this. According to UK immigration laws, the university must endorse your application for a student visa. A CAS number demonstrates that you have accepted our unconditional offer of a spot on one of our programs. You will need a CAS number for a permit allowing you to study in the UK.</p>
                                    <p>The CAS is not routinely given out. If you are a postgraduate student, you must first pay your nonrefundable deposit and upload your supporting documentation. The following step is to send a CAS request using the Online Student Information System (OSIS) at least six months before the commencement of your course.</p>

                                </div>
                            </div>
                        </div>
            <div className="col-lg-6 col-md-12">
                <div className="our-mission-image">
                    <img className="ieltstest" src={Study[18]?.path} alt="Confirmation of Acceptance for Studies"/>
                </div>
            </div>
                    </div>
                </div>
            </div>



            <div className="about-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="about-img">
                                <img className="ieltstest" src={Study[10]?.path} alt="ATAS Certificate"/>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="about-content">
                                <div className="content"><span className="sub-title">
                                    <img src={CommonImg[0]?.path} alt="services"/> 
                                    Services</span>
                                    <h2 className="text_size">ATAS Certificate</h2>
                                    <p>All overseas students and researchers who are subject to UK immigration control and who want to study or conduct postgraduate research in particular sensitive fields must complete the Academic Technology Approval Scheme (ATAS), except for excluded countries.</p>
                                    <h3 className="text_content">What is the ELICOS Course?</h3>
                                    <p>The topics and research fields are those in which information may be applied to projects that aim to create advanced conventional military technology (ACMT), weapons of mass destruction (WMDs), or their deployment systems. Before they may learn or conduct an investigation in the UK, researchers, and students in these delicate fields must apply for an Academic Technology Approval Scheme (ATAS) accreditation.</p>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="our-mission-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-content">
                                <div className="content"><span className="sub-title">
                                    <img src={CommonImg[0]?.path} alt="services"/> 
                                    Services</span>
                                    <h3 className="text_size">Types of student visa</h3>
                                    <strong>There are two types of student visas:</strong>
                                    <p>1). Short-Term Student Visa</p>
                                    <p>2). Tier 4 (General) student visa.</p>
                                    <p>3). Tier 4 Child Visa</p>
                                    <h4>Short-Term Student Visa</h4>
                                    <p>A short-term study visa can be requested if you are doing a brief course in the UK. With this visa, you can spend 6 months studying in the UK or 11 months if you're doing an English language course.</p>
                                    <strong>To be eligible for a short-term study visa, you must demonstrate:</strong>
                                    <p>1). You have been granted admission to a program at a reputable academic institution in the UK.</p>
                                    <p>2). You have adequate funds to pay for your education and living expenses for the length of your studies.</p>
                                    <p>Your return trip can be funded</p>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-image">
                                <img className="ieltstest" src={Study[19]?.path} alt="Types of student visa"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="about-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="about-img">
                                <img className="ieltstest" src={Study[12]?.path} alt="Tier 4 Student Visa"/>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="about-content">
                                <div className="content"><span className="sub-title">
                                    <img src={CommonImg[0]?.path} alt="services"/> 
                                    Services</span>
                                    <h3 className="text_size">Tier 4 (General) Student Visa</h3>

                                    <p>You must apply for a Tier 4 (General) student visa if you are 16 or older and wish to pursue higher education. Additionally, bear in mind that the application must be submitted three months before the start of the course you have selected.</p>
                                    <p>Before applying for a Tier 4 (General) student visa, you must first get an offer from a university or college. The Confirmation of Acceptance of Studies, a document your institution or university can subsequently provide to you, will be available after that. To accomplish this, the institution of your choice must be licensed as a Tier 4 supporter by the UK government.</p>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="our-mission-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-content">
                                <div className="content"><span className="sub-title">
                                    <img src={CommonImg[0]?.path} alt="services"/> 
                                    Services</span>
                                    <h3 className="text_size">Tier 4 Child Visa</h3>
                                    <p>If you're between the ages of 4 and 17 and wish to attend an independent school in the UK, you may apply for a Child Student visa. A course at an independent school must unconditionally give you a spot on it. Prove that you will have access to sufficient funds to cover both the cost of your education and your assistance in the UK.</p>
                                    <h4 className="text_content">Eligibility criteria for a student visa for the UK</h4>
                                    <strong>The following information must be provided when applying for a student visa:</strong>
                                    <p>1).  A current passport or other appropriate travel paperwork</p>
                                    <p>2). From your course provider, a Confirmation of Acceptance for Studies (CAS).</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-image">
                                <img className="ieltstest" src={Study[13]?.path} alt="Tier 4 Child Visa"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="about-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                    <div className="col-lg-12">
                            <div className="content contentboxheader"><span className="sub-title">
                                <img src={CommonImg[0]?.path} alt="services"/> 
                                Services</span>
                                <h3 className="text_size">You could also have to give:</h3>
                            </div>
                        </div>
                    <div className="col-lg-6 col-md-12">
                            <div className="about-content">
                                <div className="content contentbox">
                                    <p>1). Depending on your situation, you will need to provide evidence that you have the funds to sustain yourself and finance your education.</p>
                                    <p>2). A current Academic Technology Approval Scheme ATAS certificate, if your course and country of citizenship demand one.</p>
                                    <p>3). If you are younger than 18, you must provide proof of your parent's or another legal guardian's consent. Additionally, permission is required for your journey to the UK, your housing and care arrangements in the UK, and your visa application.</p>
                                    <p>4). If you've received financial support last year for your course tuition and living expenses. You must submit written permission from that supporter with your application.</p>
                                      </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="about-content">
                                <div className="content contentbox">
                                    <p>5). Proof of approval by the relevant institution: When requesting a student visa, you can be asked to present the institution's or university's letter of acceptance.</p>
                                    <p>6). Appropriate results on English language proficiency exams like the IELTS.</p>
                                    <p>7). Financial evidence indicates that you have the resources to sustain yourself and pay for your education. To attend classes in London for up to nine months, you would need around £1,334 per month, and to attend classes outside of London for up to nine months, you would need £1,023.</p>
                                    <p>8). A recent passport-size color photograph with a white background.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="our-mission-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-content">
                                <div className="content"><span className="sub-title">
                                    <img src={CommonImg[0]?.path} alt="services"/> 
                                    Services</span>
                                    <h3 className="text_size">Non-government scholarships</h3>
                                    <p>1). Euraxess UK</p>
                                    <p>2). Castlesmart Scholarship</p>
                                    <p>3). Surfshark Privacy and Security Scholarship</p>
                                    <p>4). The Royal Society Grants</p>

                                    <h4 className="text_content">Scholarships for African students</h4>
                                    <p>1). Aga Khan Foundation Scholarship Program</p>
                                    
                                    <h4 className="text_content">For Armenian Students</h4>
                                    <p>1). The Luys Scholarship Programs</p>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-image">
                                <img className="ieltstest" src={Study[6]?.path} alt="Non-government scholarships"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="about-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="about-img">
                                <img className="ieltstest" src={Study[7]?.path} alt="For Indian Students"/>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="about-content">
                                <div className="content"><span className="sub-title">
                                    <img src={CommonImg[0]?.path} alt="services"/> 
                                    Services</span>
                                    <h3 className="text_size">For Indian Students</h3>
                                    <p>1). Charles Wallace India Trust Scholarship. This scholarship is also applicable to Pakistani students.</p>
                                    <p>2). Saltire Scholarship</p>

                                    <h4 className="text_content">For Chinese Student</h4>
                                    <p>1). Great Britain- China Educational Trust (GBCET).</p>

                                    <h4 className="text_content">UK visa applications require IELTS.</h4>
                                    <p>To study for a bachelor's or postgraduate degree at a Tier 4 Sponsor Institution, candidates for a Tier 4 Student Visa must meet the English language competence requirement set out by the institution. All colleges and institutions in the United Kingdom recognize the IELTS test results.</p>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="our-mission-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-content">
                                <div className="content"><span className="sub-title">
                                    <img src={CommonImg[0]?.path} alt="services"/> 
                                    Services</span>
                                    <h3 className="text_size">Tier 4 Child Visa</h3>
                                    <p>1). Recommended minimum CEFR level- B1</p>

                                    <p>2). Skills- Reading, Writing, Speaking, and Listening.</p>
                                    <p>3). Minimum IELTS score requires- IELTS score should be 4.0 in each skill.</p>
                                    
                                    <h4 className="text_content">Tier 4 (General) Student Visa</h4>
                                    <p>1). Recommended minimum CEFR level- B2</p>
                                    <p>2). Skills- Reading, Writing, Speaking, and Listening.</p>
                                    <p>3). Minimum IELTS score requires- IELTS score should be 5.5 in each skill.</p>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-image">
                                <img className="ieltstest" src={Study[14]?.path} alt="Tier 4 Child Visa"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="about-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="about-img">
                                <img className="ieltstest" src={Study[11]?.path} alt="study in the UK"/>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="about-content">
                                <div className="content"><span className="sub-title">
                                    <img src={CommonImg[0]?.path} alt="services"/> 
                                    Services</span>
                                    <h3 className="text_size">Without IELTS, you can study in the UK.</h3>
                                    <p>Suppose you apply to certain universities that have alternative requirements, like proof of over 60% in English in high school, an undergraduate degree in English, or an available pre-sessional language course for international students. In that case, the answer is yes, it is possible to study in the UK without IELTS.</p>
                                    <p>Without taking the IELTS exam, applicants may take the Secure English Language Test (SELT), which is required by UK Visas and Immigration (UKVI).</p>

                                    <p>1). The University of Northampton</p>
                                    <p>2). The University of Central Lancashire</p>
                                    <p>3). The University of Greenwich</p>
                                    <p>4). Swansea University</p>
                                    <p>5). University of Plymouth</p>
                                    <p>6).  Portsmouth University</p>
                                    <p>7). Northumbria University.</p>
                                    <p>8). The University of Bolton.</p>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}