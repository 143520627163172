import Study1 from "../Images/study/study1.png";
import Servicestudy2 from "../Images/study/servicestudy2.png";
import Study3 from "../Images/study/study3.png";
import Study4 from "../Images/study/study4.png";
import Study5 from "../Images/study/study5.png";
import Study6 from "../Images/study/study6.gif";
import Study7 from "../Images/study/study7.png";
import Study8 from "../Images/study/study8.gif";
import Study9 from "../Images/study/study9.png";
import Study10 from "../Images/study/study10.png";
import Study11 from "../Images/study/study11.png";
import Study12 from "../Images/study/study12.png";
import Study13 from "../Images/study/study13.png";
import Study14 from "../Images/study/study14.png";
import Study15 from "../Images/study/study15.png";
import Study16 from "../Images/study/study16.png";
import Study17 from "../Images/study/study17.png";
import Study18 from "../Images/study/study18.png";
import Study19 from "../Images/study/study19.png";
import Study20 from "../Images/study/study20.png";
import Study21 from "../Images/study/study21.png";
import Australia from "../Images/study/australia.png";
import Canada from "../Images/study/canada.png";
import UK from "../Images/study/uk.png";
import USA from "../Images/study/usa.png";
import NewZealand from "../Images/study/new_zealand.png";
import Australia1 from "../Images/common/study_in_australia.jpg";
import Canada1 from "../Images/common/study_in_canada.jpg";
import UK1 from "../Images/common/study_in_uk.jpg";
import USA1 from "../Images/common/study_in_usa.jpg";
import NewZealand1 from "../Images/common/study_in_new_zealand.jpg";
import visaimg from "../Images/study/visa-img.png";
import Study22 from "../Images/study/study22.png";
import Study23 from "../Images/study/study23.png";
import Study24 from "../Images/study/study24.png";
import Study25 from "../Images/study/study25.png";
import Study26 from "../Images/study/study26.png";
import Study27 from "../Images/study/study27.png";
import Study28 from "../Images/study/study28.png";
import Study29 from "../Images/study/study29.png";
import Study30 from "../Images/study/study30.png";
import Study31 from "../Images/study/study31.png";
import Study32 from "../Images/study/study32.png";
import Study33 from "../Images/study/study33.png";
import Study34 from "../Images/study/study34.png";
import Study35 from "../Images/study/study35.png";
import Study36 from "../Images/study/study36.png";
import Study37 from "../Images/study/study37.png";
import Study38 from "../Images/study/study38.png";
import Study39 from "../Images/study/study39.png";
import Study40 from "../Images/study/study40.png";
import Study41 from "../Images/study/study41.png";
import Study42 from "../Images/study/study42.png";
import Study43 from "../Images/study/study43.png";
import Study44 from "../Images/study/study44.png";
import Study45 from "../Images/study/study45.png";
import Study46 from "../Images/study/study46.png";
import Study47 from "../Images/study/study47.png";
import Study48 from "../Images/study/study48.png";
import Study49 from "../Images/study/study49.png";
import Study50 from "../Images/study/study50.png";
import Study51 from "../Images/study/study51.png";
import Study52 from "../Images/study/study52.png";
import Study53 from "../Images/study/study53.png";
import Study54 from "../Images/study/study54.png";
import Study55 from "../Images/study/study55.png";
import Study56 from "../Images/study/study56.png";
import Study57 from "../Images/study/study57.png";
import Study58 from "../Images/study/study58.png";
import Study59 from "../Images/study/study59.png";
import Study60 from "../Images/study/study60.png";
import Study61 from "../Images/study/study61.png";
import Study62 from "../Images/study/study62.png";
import Study63 from "../Images/study/study63.png";
import Study64 from "../Images/study/study64.png";
import Study65 from "../Images/study/study65.png";
import Study66 from "../Images/study/study66.png";
import Study67 from "../Images/study/study67.png";
import Study68 from "../Images/study/study68.png";
import Study69 from "../Images/study/study69.png";
import Study70 from "../Images/study/study70.png";
import Study71 from "../Images/study/study71.png";
import Study72 from "../Images/study/study72.png";
import Study73 from "../Images/study/study73.png";
import Study74 from "../Images/study/study74.png";
import Study75 from "../Images/study/study75.png";
import Study76 from "../Images/study/study76.png";
import Study77 from "../Images/study/study77.png";
import Study78 from "../Images/study/study78.png";
import Study79 from "../Images/study/study79.png";
import Study80 from "../Images/study/study80.png";
import Study81 from "../Images/study/study81.png";
import Study82 from "../Images/study/study82.png";
import Study83 from "../Images/study/study83.png";
export const Study =[
    {
        id : 1,
        path : Study1,
    },
    {
        id: 2,
        path : Servicestudy2,
    },
    {
        id:3,
        path : Study3,
    },
    {
        id:4,
        path : Study4,
    },
    {
        id:5,
        path : Study5,
    },
    {
        id:6,
        path : Study6,
    },
    {
        id:7,
        path : Study7,
    },
    {
        id:8,
        path: Study8,
    },
    {
        id:9,
        path: Study9,
    },
    {
        id:10,
        path: Study10,
    },
    {
        id:11,
        path: Study11,
    },
    {
        id:12,
        path: Study12,
    },
    {
        id:13,
        path: Study13,
    },
    {
        id:14,
        path: Study14,
    },
    {
        id:15,
        path: Study15,
    },
    {
        id:16,
        path: Study16,
    },
    {
        id:17,
        path: Study17,
    },
    {
        id:18,
        path: Study18,
    },
    {
        id:19,
        path: Study19,
    },
    {
        id:20,
        path: Study20,
    },
    {
        id:21,
        path: Study21,
    },
    {
        id:22,
        path: Australia,
    },
    {
        id:23,
        path: Canada,
    },
    {
        id:24,
        path: UK,
    },
    {
        id:25,
        path: USA,
    },
    {
        id:26,
        path: NewZealand,
    },
    {
        id:27,
        path: Australia1,
    },
    {
        id:28,
        path: Canada1,
    },
    {
        id:29,
        path: UK1,
    },
    {
        id:30,
        path: USA1,
    },
    {
        id:31,
        path: NewZealand1,
    },
    {
        id:32,
        path: visaimg,
    },
    {
        id:33,
        path: Study22,
    },
    {
        id:34,
        path: Study23,
    },
    {
        id:35,
        path: Study24,
    },
    {
        id:36,
        path: Study25,
    },
    {
        id:37,
        path: Study26,
    },
    {
        id:38,
        path: Study27,
    },
    {
        id:39,
        path: Study28,
    },
    {
        id:40,
        path: Study29,
    },
    {
        id:41,
        path: Study30,
    },
    {
        id:42,
        path: Study31,
    },
    {
        id:43,
        path: Study32,
    },
    {
        id:44,
        path: Study33,
    },
    {
        id:45,
        path: Study34,
    },
    {
        id:46,
        path: Study35,
    },
    {
        id:47,
        path: Study36,
    },
    {
        id:48,
        path: Study37,
    },
    {
        id:49,
        path: Study38,
    },
    {
        id:50,
        path: Study39,
    },
    {
        id:51,
        path: Study40,
    },
    {
        id:52,
        path: Study41,
    },
    {
        id:53,
        path: Study42,
    },
    {
        id:54,
        path: Study43,
    },
    {
        id:55,
        path: Study44,
    },
    {
        id:56,
        path: Study45,
    },
    {
        id:57,
        path: Study46,
    },
    {
        id:58,
        path: Study47,
    },
    {
        id:59,
        path: Study48,
    },
    {
        id:60,
        path: Study49,
    },
    {
        id:61,
        path: Study50,
    },
    {
        id:62,
        path: Study51,
    },
    {
        id:63,
        path: Study52,
    },
    {
        id:64,
        path: Study53,
    },
    {
        id:65,
        path: Study54,
    },
    {
        id:66,
        path: Study55,
    },
    {
        id:67,
        path: Study56,
    },
    {
        id:68,
        path: Study57,
    },
    {
        id:69,
        path: Study58,
    },
    {
        id:70,
        path: Study59,
    },
    {
        id:71,
        path: Study60,
    },
    {
        id:72,
        path: Study61,
    },
    {
        id:73,
        path: Study62,
    },
    {
        id:74,
        path: Study63,
    },
    {
        id:75,
        path: Study64,
    },
    {
        id:76,
        path: Study65,
    },
    {
        id:77,
        path: Study66,
    },
    {
        id:78,
        path: Study67,
    },
    {
        id:79,
        path: Study68,
    },
    {
        id:80,
        path: Study69,
    },
    {
        id:81,
        path: Study70,
    },
    {
        id:82,
        path: Study71,
    },
    {
        id:83,
        path: Study72,
    },
    {
        id:84,
        path: Study73,
    },
    {
        id:85,
        path: Study74,
    },
    {
        id:86,
        path: Study75,
    },
    {
        id:87,
        path: Study76,
    },
    {
        id:88,
        path: Study77,
    },
    {
        id:89,
        path: Study78,
    },
    {
        id:90,
        path: Study79,
    },
    {
        id:91,
        path: Study80,
    },
    {
        id:92,
        path: Study81,
    },
    {
        id:93,
        path: Study82,
    },
    {
        id:94,
        path: Study83,
    },
]