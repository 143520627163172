import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { CommonImg } from "../../Images/CommonImg";
import SITE_PATHS from "../Path";

export default function History() {


    return (
        <>
            <div className="page-title-area">
                <div className="container">
                    <div className="page-title-content">
                        <h2>History</h2>
                        <ul>
                            <li><NavLink to={SITE_PATHS.LOGOCLICK}>Home</NavLink></li>
                            <li>History</li>
                        </ul>
                    </div>
                </div>
                <div className="shape-img3">

                    <img src={CommonImg[7]?.path}></img>
                </div>
                
            </div>
            <section className="history-area ptb-100 bannermargin bg-fafafb">
                <div className="container">
                    <div className="section-title"><span className="sub-title"><img
                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAiCAYAAAA+stv/AAAFCElEQVRYhb1Xf0yUZRz/vM97dxzecUAiYMiPlSVBLpvm3CDWSHdsQVlgGlNImOVK0tqwRmbTpVuuuSFLmhNTSq22hpoV/RB/wagF00xRkQDxEJUfB3fHy929975Pe9zhzpfj7gW1z3/v8+P7+Tzf7/f5fp+XgwLWzoJoAB8ByAMQC2BAkvCzJMkbYx7/ul25/l5xlwBrZ8FMACcAxCntyjIc/3YM5cx//vCJ+ymAKL4P+iO/vZDAmJQUdiA/L3HaAxFg7SyYD2BeoMVankwvXpGyTOm5e4HGZ++TauxET5uSCiAUgKBmPV1fvABAPIDT3LaqG8p53xB41BgURUlQCA9EvhtAI4DvKNDe8carOYEEsOSiwYy2XLQ2MR0qyGcDKB795oDQaEPoFgAmvwIik6q7JAl7Axl1DHsal68++QcAZzABAKKUA1pCGHmYXwEMPI81Lpdc689a9y2pa/3G+vcA3FTjqdzzjq4et3xXWLvtw0eVofabzWdPvZxrNGqXaLV8jChK1srDNKGqTp4zbO1dKp4/9X0wckN67gwW0id0JObLmdqGBD2vs9gd9XP2HqoWKb3qG8JA14nN6ZljSEKKTh8/6zgPLsHZ35MhXmr8Jxg5QCOd3e3FUsfZBhY9r7dHWE0L6oFxDCcCtDkxebZzQW5BM9HqUm7HU5ZYea7heE3lgY/XRoySuyxthZ7Ocyxf+gLZVS1g1TGLThiy/jglPHKhv3mP6L5xfM8Oqfd6V6hacgZlKQ6EneORM2i0utiMFaunTw03rVNLrlrAqmOWeb53ejyETDGSjKJ3swAMqT2VqooGYJlagyGmiGyO8A9RWbrpO15R3mAG8BwAO6uMJWvT2ti42hAkqxVACG9KXlwUryCvAsDqywcAtlBKWzZvqnmNzan1gJrKdwd9NmF3WNorrTLHXctOTZMAFPnOcxynDTdFfR4Xl3xMlQB7/60bYVOjVZG7BLtjxG4fACGphJDMEI3Wr3hCSOSzaS/NvS0gq77sGQBrCMUMSZb/4njyWW361j5Dem4CgE+OV1cuf6HkQ8prNEGvrdPa/4XjzyPbvbHWPGYufIu53d/aq9dah0hWfVk2AHZtCmQOmRxP3pc90plpOUsrAVxmZX2o59rOga62T4ORe1wjfx8tW7kPQK+3+g1GhEftopSOuZKCYPupsbHGwpJwm7IpEQ0/4+GMSDN1Ow8Jl5oWOZtrNx1atXCDKDjeoVQe8Ufucgz9+svmt4sFy5VO34ZTsjatTxRdmW63k70LZEplwWbr319esY4lZC+XVV/mt7O5Hc7f67K2F3pPc6d55FQciTNGxa6ERjN/sOd6DuVw2tnTsePE1pLR4jNewrLwhbObCkDy1gqR5QBTnDTGnU53p9fYXY+PH0pe7GZ5oX960VTeYOqThm2nnWd+q/W6PBDYQQeV88z1G8Zskqm1/WDjV97u5Re8wST57ghCPi5IbfrW/W7bSJ4kuOslt+eie1Coubzv5BLLt80XAglQceIJg/e+11hL1anZbEjPpfq55nIAxsmS+hYi5lLbJGxMpKPev820NP+p6ke1csPccLPlzcXmydqZ1B8OLc1/BMA5FoXRsdZ+2+uz9hzdN1Fbk/VAni85Q4xBv0L55H6QAsbkiihTu/cR+78I2O+Racvoh0Sp7ZsL7ew3LNC19YtJ/+XS0vzQKwO25YIoRe1saqnb1XKVvXD6J2QEwH8PA/Uf4hwXIQAAAABJRU5ErkJggg=="
                        alt="about" />Our History</span>
                        <h2>History Begins in 2010</h2>
                    </div>
                    <ol className="timeline history-timeline">
                        <li className="timeline-block">
                            <div className="timeline-date"><span>2010</span>February 20<sup>th</sup></div>
                            <div className="timeline-icon"><span className="dot-badge"></span></div>
                            <div className="timeline-content">
                                <div className="row align-items-center">
                                    <div className="col-lg-7 col-md-12">
                                        <div className="content">
                                            <h3>Founded</h3>
                                            <p>Real innovations and a positive customer experience are the heart of
                                                successful communication. Lorem ipsum dolor sit amet, sectetur
                                                adipiscing elit, tempor incididunt ut labore et dolore magna.</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-5 col-md-12">
                                        <div className="image"><img
                                            src="./img/history1.gif"
                                            alt="about" /></div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li className="timeline-block">
                            <div className="timeline-date"><span>2013</span>January 14<sup>th</sup></div>
                            <div className="timeline-icon"><span className="dot-badge"></span></div>
                            <div className="timeline-content">
                                <div className="row align-items-center">
                                    <div className="col-lg-7 col-md-12">
                                        <div className="content">
                                            <h3>Global Success</h3>
                                            <p>Real innovations and a positive customer experience are the heart of
                                                successful communication. Lorem ipsum dolor sit amet, sectetur
                                                adipiscing elit, tempor incididunt ut labore et dolore magna.</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-5 col-md-12">
                                        <div className="image"><img
                                            src="./img/history3.gif"
                                            alt="about" /></div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li className="timeline-block">
                            <div className="timeline-date"><span>2016</span>March 25<sup>th</sup></div>
                            <div className="timeline-icon"><span className="dot-badge"></span></div>
                            <div className="timeline-content">
                                <div className="row align-items-center">
                                    <div className="col-lg-7 col-md-12">
                                        <div className="content">
                                            <h3>Founded Data Center</h3>
                                            <p>Real innovations and a positive customer experience are the heart of
                                                successful communication. Lorem ipsum dolor sit amet, sectetur
                                                adipiscing elit, tempor incididunt ut labore et dolore magna.</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-5 col-md-12">
                                        <div className="image"><img
                                            src="./img/history4.gif"
                                            alt="about" /></div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li className="timeline-block">
                            <div className="timeline-date"><span>2020</span>December 10<sup>th</sup></div>
                            <div className="timeline-icon"><span className="dot-badge"></span></div>
                            <div className="timeline-content">
                                <div className="row align-items-center">
                                    <div className="col-lg-7 col-md-12">
                                        <div className="content">
                                            <h3>International Award</h3>
                                            <p>Real innovations and a positive customer experience are the heart of
                                                successful communication. Lorem ipsum dolor sit amet, sectetur
                                                adipiscing elit, tempor incididunt ut labore et dolore magna.</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-5 col-md-12">
                                        <div className="image"><img
                                            src="./img/history2.gif"
                                            alt="about" /></div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ol>
                </div>
            </section>


            <div className="history-area ptb-100">
                <div className="container">
                    <div className="section-title"><span className="sub-title"><img
                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAiCAYAAAA+stv/AAAFCElEQVRYhb1Xf0yUZRz/vM97dxzecUAiYMiPlSVBLpvm3CDWSHdsQVlgGlNImOVK0tqwRmbTpVuuuSFLmhNTSq22hpoV/RB/wagF00xRkQDxEJUfB3fHy929975Pe9zhzpfj7gW1z3/v8+P7+Tzf7/f5fp+XgwLWzoJoAB8ByAMQC2BAkvCzJMkbYx7/ul25/l5xlwBrZ8FMACcAxCntyjIc/3YM5cx//vCJ+ymAKL4P+iO/vZDAmJQUdiA/L3HaAxFg7SyYD2BeoMVankwvXpGyTOm5e4HGZ++TauxET5uSCiAUgKBmPV1fvABAPIDT3LaqG8p53xB41BgURUlQCA9EvhtAI4DvKNDe8carOYEEsOSiwYy2XLQ2MR0qyGcDKB795oDQaEPoFgAmvwIik6q7JAl7Axl1DHsal68++QcAZzABAKKUA1pCGHmYXwEMPI81Lpdc689a9y2pa/3G+vcA3FTjqdzzjq4et3xXWLvtw0eVofabzWdPvZxrNGqXaLV8jChK1srDNKGqTp4zbO1dKp4/9X0wckN67gwW0id0JObLmdqGBD2vs9gd9XP2HqoWKb3qG8JA14nN6ZljSEKKTh8/6zgPLsHZ35MhXmr8Jxg5QCOd3e3FUsfZBhY9r7dHWE0L6oFxDCcCtDkxebZzQW5BM9HqUm7HU5ZYea7heE3lgY/XRoySuyxthZ7Ocyxf+gLZVS1g1TGLThiy/jglPHKhv3mP6L5xfM8Oqfd6V6hacgZlKQ6EneORM2i0utiMFaunTw03rVNLrlrAqmOWeb53ejyETDGSjKJ3swAMqT2VqooGYJlagyGmiGyO8A9RWbrpO15R3mAG8BwAO6uMJWvT2ti42hAkqxVACG9KXlwUryCvAsDqywcAtlBKWzZvqnmNzan1gJrKdwd9NmF3WNorrTLHXctOTZMAFPnOcxynDTdFfR4Xl3xMlQB7/60bYVOjVZG7BLtjxG4fACGphJDMEI3Wr3hCSOSzaS/NvS0gq77sGQBrCMUMSZb/4njyWW361j5Dem4CgE+OV1cuf6HkQ8prNEGvrdPa/4XjzyPbvbHWPGYufIu53d/aq9dah0hWfVk2AHZtCmQOmRxP3pc90plpOUsrAVxmZX2o59rOga62T4ORe1wjfx8tW7kPQK+3+g1GhEftopSOuZKCYPupsbHGwpJwm7IpEQ0/4+GMSDN1Ow8Jl5oWOZtrNx1atXCDKDjeoVQe8Ufucgz9+svmt4sFy5VO34ZTsjatTxRdmW63k70LZEplwWbr319esY4lZC+XVV/mt7O5Hc7f67K2F3pPc6d55FQciTNGxa6ERjN/sOd6DuVw2tnTsePE1pLR4jNewrLwhbObCkDy1gqR5QBTnDTGnU53p9fYXY+PH0pe7GZ5oX960VTeYOqThm2nnWd+q/W6PBDYQQeV88z1G8Zskqm1/WDjV97u5Re8wST57ghCPi5IbfrW/W7bSJ4kuOslt+eie1Coubzv5BLLt80XAglQceIJg/e+11hL1anZbEjPpfq55nIAxsmS+hYi5lLbJGxMpKPev820NP+p6ke1csPccLPlzcXmydqZ1B8OLc1/BMA5FoXRsdZ+2+uz9hzdN1Fbk/VAni85Q4xBv0L55H6QAsbkiihTu/cR+78I2O+Racvoh0Sp7ZsL7ew3LNC19YtJ/+XS0vzQKwO25YIoRe1saqnb1XKVvXD6J2QEwH8PA/Uf4hwXIQAAAABJRU5ErkJggg=="
                        alt="about" />Our History</span>
                        <h2>History Begins in 2010</h2>
                    </div>
                    <ol className="timeline history-timeline history-timeline-style-two">
                        <li className="timeline-block">
                            <div className="timeline-date"><span>2010</span>February 20<sup>th</sup></div>
                            <div className="timeline-icon"><span className="dot-badge"></span></div>
                            <div className="timeline-content">
                                <div className="row align-items-center">
                                    <div className="col-lg-5 col-md-12">
                                        <div className="image"><img
                                           src="./img/history1.gif"
                                            alt="about" /></div>
                                    </div>
                                    <div className="col-lg-7 col-md-12">
                                        <div className="content">
                                            <h3>Founded</h3>
                                            <p>Real innovations and a positive customer experience are the heart of
                                                successful communication. Lorem ipsum dolor sit amet, sectetur
                                                adipiscing elit, tempor incididunt ut labore et dolore magna.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li className="timeline-block">
                            <div className="timeline-date"><span>2013</span>January 14<sup>th</sup></div>
                            <div className="timeline-icon"><span className="dot-badge"></span></div>
                            <div className="timeline-content">
                                <div className="row align-items-center">
                                    <div className="col-lg-5 col-md-12">
                                        <div className="image"><img
                                           src="./img/history3.gif"
                                            alt="about" /></div>
                                    </div>
                                    <div className="col-lg-7 col-md-12">
                                        <div className="content">
                                            <h3>Global Success</h3>
                                            <p>Real innovations and a positive customer experience are the heart of
                                                successful communication. Lorem ipsum dolor sit amet, sectetur
                                                adipiscing elit, tempor incididunt ut labore et dolore magna.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li className="timeline-block">
                            <div className="timeline-date"><span>2016</span>March 25<sup>th</sup></div>
                            <div className="timeline-icon"><span className="dot-badge"></span></div>
                            <div className="timeline-content">
                                <div className="row align-items-center">
                                    <div className="col-lg-5 col-md-12">
                                        <div className="image"><img
                                          src="./img/history4.gif"
                                            alt="about" /></div>
                                    </div>
                                    <div className="col-lg-7 col-md-12">
                                        <div className="content">
                                            <h3>Founded Data Center</h3>
                                            <p>Real innovations and a positive customer experience are the heart of
                                                successful communication. Lorem ipsum dolor sit amet, sectetur
                                                adipiscing elit, tempor incididunt ut labore et dolore magna.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li className="timeline-block">
                            <div className="timeline-date"><span>2020</span>December 10<sup>th</sup></div>
                            <div className="timeline-icon"><span className="dot-badge"></span></div>
                            <div className="timeline-content">
                                <div className="row align-items-center">
                                    <div className="col-lg-5 col-md-12">
                                        <div className="image"><img
                                            src="./img/history2.gif"
                                            alt="about" /></div>
                                    </div>
                                    <div className="col-lg-7 col-md-12">
                                        <div className="content">
                                            <h3>International Award</h3>
                                            <p>Real innovations and a positive customer experience are the heart of
                                                successful communication. Lorem ipsum dolor sit amet, sectetur
                                                adipiscing elit, tempor incididunt ut labore et dolore magna.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ol>
                </div>
            </div>
        </>
    );
}