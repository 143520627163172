import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { ServiceImage } from "../../Images/ServiceImage";
import SITE_PATHS from "../Path";
import { Study } from "../../Images/Study";
import { CommonImg } from "../../Images/CommonImg";
import { Image } from "../../Images/Image";

export default function Study_Visa_In_Australia() {


    return (
        <>
            <div className="page-title-area">
                <div className="container">
                    <div className="page-title-content">
                        <h4 className="text_size">Australia</h4>
                        <ul>
                            <li><NavLink to={SITE_PATHS.LOGOCLICK}>Home</NavLink></li>
                            <li>Study Visa In Australia</li>
                        </ul>
                    </div>
                </div>

                <div className="shape-img3">
                    <img  src={Study[26]?.path}></img>
                </div>
               
            </div>
            <div className="about-area pb-100 bannermargin">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="about-img">
                                <img src={Study[21]?.path} alt="Student visa for Australia 2022"/>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="about-content">
                                <div className="content"><span className="sub-title">
                                    <img src={CommonImg[0]?.path} alt="services"/> 
                                    Services</span>
                                    <h1 className="text_size">Student visa for Australia 2022</h1>
                                    <p>Leading universities, magnificent landscapes, vibrant cities, and several oceans are just a few of the elements that impact many international students' decisions to study in Australia. Even though it can be a little scary, studying abroad can offer major advantages for both academic success and personal growth</p>
                                    <p>Due to its excellent rating, Australia is ranked second among all nations in terms of quality of life. Australia has a variety of scholarships, educational institutions with a global reputation, and degrees with worldwide recognition.</p>
                                    <p>Currently, the nation hosts more than 30,000 foreign students from more than 190 different nations. Even during the Covid 19 impact on the world, 8 universities in Australia ranked top among the top 100 in the world, as of the 2021 end-year report.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="our-mission-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-content">
                                <div className="content"><span className="sub-title">
                                    <img src={CommonImg[0]?.path} alt="services"/> 
                                    Services</span>
                                    <h2 className="text_size">An Australian Study Visa: What Is It?</h2>
                                    <p>Since July 1, 2016, a student subclass 500 visa has allowed you to remain in Australia for the duration of your study program, which is typically up to five years and starts on the day of your enrolment. You must make sure that your visa is current and that you follow the requirements listed on your student visa to have a successful stay.</p>
                                    <p>On the Department of Home Affairs website of the Australian government, you may read in-depth information on this sort of visa.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-image">
                                <img className="ieltstest" src={Study[0]?.path} alt="Australian Study Visa"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <div className="about-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-12">
                            <div className="content contentboxheader"><span className="sub-title">
                                <img src={CommonImg[0]?.path} alt="services"/> 
                                Services</span>
                                <h2 className="text_size">The Requirements of Your Application</h2>
                                <strong>It must satisfy the following conditions:</strong>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="about-content">
                                <div className="content contentbox">

                                    <p>1). A record or certificate from the senior and higher secondary levels, as well as the results (mark sheets) of the final exams. The conversion of marks to the Australian grading system is appropriate.</p>
                                    <p>2). International students must submit their GMAT or GRE results for the management courses. The GRE or GMAT are not required for admission to many Australian universities' postgraduate programs. However, if you did well on the exam, it is advised to provide a copy of your score sheet as this may improve your chances of being admitted.</p>
                                    <p>3). You could be required to submit work experience letters and certifications outlining your key duties, detailed employment history, and previous jobs held if you're enrolling for an MBA or higher education degree like Ph.D. programs. Additionally needed is a detailed resume.</p>
                                    <p>4). Last but not least, the passport must be valid for at least six months at the time of applying for entrance and a visa.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="about-content">
                                <div className="content">
                                    <p>5). Students must submit an SOP (Statement of Purpose) or personal essays outlining their hobbies, professional objectives, recreational activities, the reasons behind their choice of stream, and how they plan to support themselves economically while studying abroad.</p>
                                    <p>6). A LOR (Letter of Recommendation), outlining the student's skills and shortcomings, as well as why he or she will succeed in the chosen field, is requested from a person who has taught the student and is familiar with him or her professionally. In most circumstances, the application requires two LORs.</p>

                                    <p>If you apply through our Aussie Immigration consultant, your visa application procedure will be simple. Our government-registered agents expedite and simplify the visa application procedure. You receive the greatest service from us, and the rejection rate drops dramatically.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="our-mission-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                            <div className="our-mission-image">
                                <img src={Image[33]?.path} alt="What is the ELICOS Course?"/>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-content">
                                <div className="content"><span className="sub-title">
                                    <img src={CommonImg[0]?.path} alt="services"/> 
                                    Services</span>
                                    <h3 className="text_size">What is the ELICOS Course?</h3>
                                    <p>English language programs like EFL (English as a Foreign Language) or ESL have an equivalent in Australia called ELICOS (English Language Intensive Courses for Overseas Students). ELICOS is not an institution; instead, it is a language program. These English language courses are open to students from outside Australia, who can use them to develop their skills in addition to their academic interests.</p>
                                    <h4 className="text_content">Scholarship</h4>
                                    <p>The Australian government offers several scholarships to foreign students and contributes around $200 million annually to various programs at universities and other tertiary institutions across the nation. Every scholarship request and application must go through the scholarship administrator.</p>
                                </div>
                            </div>
                        </div>
                       
                    </div>
                        <div className="col-lg-12">
                           
                                <div className="content missincintbox">
                                <h5 className="text_content">Vocational education and training (VET)</h5>
                                    <p>The foundation of Australian vocational education and training (VET) is a collaboration between the public sector and the private sector. Both private institutions and government-run Technical and Further Education (TAFE) institutes provide VET degrees.</p>
                                    <p>Vocational education and training (VET) in Australia is a course of study that gives you the chance to obtain specialized and useful employment skills. For overseas students searching for a speedier pathway into the job and a concentration on real-world and industry-based skills, it's the ideal approach.</p>
                                    <strong>For overseas students, the main scholarship schemes are:</strong>
                                </div>                           
                        </div>
                </div>
            </div>


            <div className="about-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                            <div className="our-mission-content">
                                <div className="content"><span className="sub-title">
                                    <img src={CommonImg[0]?.path} alt="services"/> 
                                    Services</span>
                                    <h3 className="text_size">Australia Award Scholarship</h3>
                                    <p>The Australia Awards Scholarship often covers education costs, economy-class airfare to and from Australia, a living allowance, an introductory academic program, student health insurance, and initial visa costs. The lodging the applicant chooses in Australia will also benefit from this. The pre-course English language training expenses, additional academic assistance, fieldwork, and flights for a homecoming may all be covered by the Australia Awards scholarship, provided that you meet the necessary criteria and qualify.</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-12">
                            <div className="about-img">
                                <img src={ServiceImage[11]?.path} alt="Australia Award Scholarship"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="our-mission-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                            <div className="our-mission-image">
                                <img src={Study[3]?.path} alt="Destination Scholarship"/>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-content">
                                <div className="content"><span className="sub-title">
                                    <img src={CommonImg[0]?.path} alt="services"/> 
                                    Services</span>
                                    <h3 className="text_size">Destination Scholarship</h3>
                                    <p>The Australian government pays qualifying higher education providers through the Destination Australia Program (DAP), which allows both local and foreign students to study in remote Australia through scholarships. Students pursuing courses from a Certificate IV through a Ph.D. for up to four years at rural campus sites around Australia may be eligible for a Destination Australia scholarship, worth up to $15,000 per student, each year.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="about-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">

                    <div className="col-lg-6 col-md-12">
                            <div className="our-mission-content">
                                <div className="content"><span className="sub-title">
                                    <img src={CommonImg[0]?.path} alt="services"/> 
                                    Services</span>
                                    <h3 className="text_size">Australian Government Research Training Program (RTP)</h3>
                                    <p>Australia's government offers scholarships through its Research Training Program (RTP) to help domestic and foreign students pursuing postgraduate research degrees. In November, February, and June, RTP stipend scholarships are given out. As a result, for your admission application to be taken into account during the appropriate award deadline, you must submit all necessary paperwork. Please be aware that you could get your RTP scholarship notification after receiving your study offer.</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-12">
                            <div className="about-img">
                                <img className="ieltstest" src={Study[4]?.path} alt="Training Program"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="our-mission-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                            <div className="our-mission-image">
                                <img src={Study[5]?.path} alt="Without IELTS, you can study in Australia"/>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-content">
                                <div className="content"><span className="sub-title">
                                    <img src={CommonImg[0]?.path} alt="services"/> 
                                    Services</span>
                                    <h3 className="text_size">Without IELTS, you can study in Australia.</h3>
                                    <p>To apply to a study program at an Australian university, candidates must provide a TOEFL or PTE score instead of IELTS. You might also provide a certificate proving your preferred language of teaching. Students with more than three years of English study at their previous educational institution are eligible for the certificate.</p>
                                    <strong>Here is a list of universities that do not require IELTS scores:</strong>
                                    <p>1). The University of Adelaide</p>
                                    <p>2). The University of Queensland</p>
                                    <p>3). The University of South Australia</p>
                                    <p>4). Swinburne University of Technology</p>
                                    <p>5). The University of New South Wales</p>
                                    <p>6). Bond University</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="about-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                            <div className="our-mission-content">
                                <div className="content"><span className="sub-title">
                                    <img src={CommonImg[0]?.path} alt="services"/> 
                                    Services</span>
                                    <h3 className="text_size">Provider scholarships</h3>
                                    <p>Based on academic excellence, Australian educational institutions frequently award scholarships to deserving foreign students. For more information and help, speak with the admissions staff or the international office of the institution to which you are applying. International students can apply for any of the scholarships that are advertised on our website. While many are open to students of all nationalities, all scholarships have requirements that are established by the school or organization that is providing the award, and applications must be made directly to the scholarship sponsor.</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-12">
                            <div className="about-img">
                                <img className="ieltstest" src={Study[6]?.path} alt="Provider scholarships"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="our-mission-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                            <div className="our-mission-image">
                                <img src={ServiceImage[5]?.path} alt="IELTS Score"/>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-content">
                                <div className="content"><span className="sub-title">
                                    <img src={CommonImg[0]?.path} alt="services"/> 
                                    Services</span>
                                    <h3 className="text_size">IELTS Score</h3>
                                    <p>IELTS minimum requirements for studying in Australia typically call for an overall score of 6.5 and a postgraduate degree band of no less than 6.0. A minimum IELTS score of 6.0 overall is also required when applying for undergraduate choices. However, several Australian colleges allow admissions from students who received a band of 5.5 on the IELTS in any of the four categories—speaking, listening, reading, or writing. Consequently, you can still enroll if your most recent IELTS exam result was a 5.5, for example in the speaking or reading sections.</p>
                                    <strong>Some university accepts IELTS band 5.5.</strong>
                                    <p>1). Southern Cross University</p>
                                    <p>2). Trinity College – the University of Melbourne</p>
                                    <p>3). Academies Australasia</p>
                                    <p>4). CQUniversity Australia</p>
                                    <p>5). RMIT</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="about-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                            <div className="our-mission-content">
                                <div className="content"><span className="sub-title">
                                    <img src={CommonImg[0]?.path} alt="services"/> 
                                    Services</span>
                                    <h3 className="text_size">Cost of Study in Australia</h3>
                                    <p>Choosing a subject, university, and kind of housing is what determines how much it will cost an overseas student to educate in Australia. As a result, the price varies depending on the education level and the location.</p>
                                    <h4 className="text_content">Approximate cost of studies in Australia</h4>
                                    <p>1). Basic Studies: Approximately $9000 to $14,000.</p>
                                    <p>2). An undergraduate bachelor's degree typically pays between $15,000 and $33,000 per year.</p>
                                    <p>3). Laboratory-based Bachelor's Degree: Approximate Salary of $14,000–$35,000.</p>
                                    <p>4). Graduate Certificates for Postgraduates Graduate degrees: around $A20,000–$A37,000 each year.</p>
                                    <p>5). Master's or doctoral degree: around A$20,000 to A$37,000 per year.</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-12">
                            <div className="about-img">
                                <img src={Study[2]?.path} alt="Cost of Study in Australia"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}