import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { CommonImg } from "../../Images/CommonImg";
import SITE_PATHS from "../Path";

export default function Testimonials() {


  return (
    <>
     <div className="page-title-area">
                <div className="container">
                    <div className="page-title-content">
                        <h2>Testimonials</h2>
                        <ul>
                            <li><NavLink to={SITE_PATHS.LOGOCLICK}>Home</NavLink></li>
                            <li>Aussie</li>
                        </ul>
                    </div>
                </div>
                <div className="shape-img3">

                    <img className="bannermargin_img" src={CommonImg[11]?.path}></img>
                </div>
               
            </div>
            <div className="blog-area ptb-100 bannermargin">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <div className="single-blog-post bg-fffbf5">
                                <div className="post-image"><a href="https://aussieimmigration.com.au/Inblog-details"><img
                                            src="./img/blog-img4-f52ab14b085fdc2ffb053ab221728a10.jpg"
                                            alt="image"/></a></div>
                                <div className="post-content">
                                    <ul className="post-meta d-flex justify-content-between align-items-center">
                                        <li>
                                            <div className="post-author d-flex align-items-center"><img
                                                    src="./img/user1-583aae6ee13e3e467de6f0da94af921e.jpg"
                                                    className="rounded-circle" alt="image"/><span>Alex Morgan</span></div>
                                        </li>
                                        <li><i className="fas fa-calendar"></i> April 5, 2020</li>
                                    </ul>
                                    <h3><a href="https://aussieimmigration.com.au/Inblog-details">The Data Surrounding
                                            Higher Education</a></h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="single-blog-post bg-fffbf5">
                                <div className="post-image"><a href="https://aussieimmigration.com.au/Inblog-details"><img
                                            src="./img/blog-img5-a1121781a0417e0c2f1432ba1238655d.jpg"
                                            alt="image"/></a></div>
                                <div className="post-content">
                                    <ul className="post-meta d-flex justify-content-between align-items-center">
                                        <li>
                                            <div className="post-author d-flex align-items-center"><img
                                                    src="./img/user2-181890da7459bae6c23454cf7121ed65.jpg"
                                                    className="rounded-circle" alt="image"/><span>Sarah Taylor</span></div>
                                        </li>
                                        <li><i className="fas fa-calendar"></i> April 6, 2020</li>
                                    </ul>
                                    <h3><a href="https://aussieimmigration.com.au/Inblog-details">Conversion Rate the
                                            Sales Funnel Optimization</a></h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="single-blog-post bg-fffbf5">
                                <div className="post-image"><a href="https://aussieimmigration.com.au/Inblog-details"><img
                                            src="./img/blog-img6-c4849c23f0f96fb9db3bb5a803625efa.jpg"
                                            alt="image"/></a></div>
                                <div className="post-content">
                                    <ul className="post-meta d-flex justify-content-between align-items-center">
                                        <li>
                                            <div className="post-author d-flex align-items-center"><img
                                                    src="./img/user3-d89e5759c382a5e0aae660cb3efcbd83.jpg"
                                                    className="rounded-circle" alt="image"/><span>David Warner</span></div>
                                        </li>
                                        <li><i className="fas fa-calendar"></i> April7, 2020</li>
                                    </ul>
                                    <h3><a href="https://aussieimmigration.com.au/Inblog-details">Business Data is
                                            changing the world’s Energy</a></h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="single-blog-post bg-fffbf5">
                                <div className="post-image"><a href="https://aussieimmigration.com.au/Inblog-details"><img
                                            src="./img/blog-img7-2b7a85e7afb79548e68cc0e06f08bc85.jpg"
                                            alt="image"/></a></div>
                                <div className="post-content">
                                    <ul className="post-meta d-flex justify-content-between align-items-center">
                                        <li>
                                            <div className="post-author d-flex align-items-center"><img
                                                    src="./img/user4-41530a7019a90cc32b995758eabc5a9a.jpg"
                                                    className="rounded-circle" alt="image"/><span>David Warner</span></div>
                                        </li>
                                        <li><i className="fas fa-calendar"></i> April 8, 2020</li>
                                    </ul>
                                    <h3><a href="https://aussieimmigration.com.au/Inblog-details">The data-driven
                                            approach to understanding</a></h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="single-blog-post bg-fffbf5">
                                <div className="post-image"><a href="https://aussieimmigration.com.au/Inblog-details"><img
                                            src="./img/blog-img8-bf56eb8f04e906c338c929a98b28c2c1.jpg"
                                            alt="image"/></a></div>
                                <div className="post-content">
                                    <ul className="post-meta d-flex justify-content-between align-items-center">
                                        <li>
                                            <div className="post-author d-flex align-items-center"><img
                                                    src="./img/user5-57e29bd09f31f52fdc2fa48585469c3f.jpg"
                                                    className="rounded-circle" alt="image"/><span>David Warner</span></div>
                                        </li>
                                        <li><i className="fas fa-calendar"></i> April 9, 2020</li>
                                    </ul>
                                    <h3><a href="https://aussieimmigration.com.au/Inblog-details">Finding the blocks of
                                            neighboring fields</a></h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="single-blog-post bg-fffbf5">
                                <div className="post-image"><a href="https://aussieimmigration.com.au/Inblog-details"><img
                                            src="./img/blog-img10-568ea971541538d405c6355631cef45e.jpg"
                                            alt="image"/></a></div>
                                <div className="post-content">
                                    <ul className="post-meta d-flex justify-content-between align-items-center">
                                        <li>
                                            <div className="post-author d-flex align-items-center"><img
                                                    src="./img/user6-bd69b599a702aae1f157d5a18317712e.jpg"
                                                    className="rounded-circle" alt="image"/><span>David Warner</span></div>
                                        </li>
                                        <li><i className="fas fa-calendar"></i> April 10, 2020</li>
                                    </ul>
                                    <h3><a href="https://aussieimmigration.com.au/Inblog-details"><a>Data into Your
                                                Enterprise to Drive Insights</a></a></h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12 col-md-12">
                            <div className="pagination-area text-center"><a className="prev page-numbers"
                                    href="https://aussieimmigration.com.au/Inblog/#"><i
                                        className="fas fa-chevron-left"></i></a><span className="page-numbers current"
                                    aria-current="page">1</span><a className="page-numbers"
                                    href="https://aussieimmigration.com.au/Inblog/#">2</a><a className="page-numbers"
                                    href="https://aussieimmigration.com.au/Inblog/#">3</a><a className="page-numbers"
                                    href="https://aussieimmigration.com.au/Inblog/#">4</a><a className="next page-numbers"
                                    href="https://aussieimmigration.com.au/Inblog/#"><i
                                        className="fas fa-chevron-right"></i></a></div>
                        </div>
                    </div>
                </div>
            </div>
    </>
  );
}