
import loader from "../Images/common/lodaer.png";
import About from "../Images/common/about.jpg"
import About_us from "../Images/common/about_us.jpg"
import Contact from "../Images/common/contact.jpg"
import English from "../Images/common/english.jpg"
import FAQ from "../Images/common/FAQ.jpg"
import Gallery from "../Images/common/gallery.jpg"
import History from "../Images/common/history.jpg"
import IELTS from "../Images/common/ielts_a.jpg"
import Our_team from "../Images/common/our_team.jpg"
import PTE from "../Images/common/PTE.jpg"
import Testimonials from "../Images/common/testimonial_b.jpg"
import Courses from "../Images/common/courses.jpg"
import Logo from "../Images/common/logo_new.png"
import Map from "../Images/common/footer-map.png"
import FAQ1 from "../Images/faq1.png"
import FAQ2 from "../Images/faq2.png"
import Login from "../Images/common/login.jpg"
import thankyou from "../Images/common/thankyou.gif"

export const CommonImg =[
    {
        id : 1,
        path : loader,
    },
    {
        id: 2,
        path : About,
    },
    {
        id:3,
        path : About_us,
    },
    {
        id:4,
        path : Contact,
    },
    {
        id:5,
        path : English,
    },
    {
        id:6,
        path : FAQ,
    },
    {
        id:7,
        path : Gallery,
    },
    {
        id:8,
        path: History,
    },
    {
        id:9,
        path: IELTS,
    },
    {
        id:10,
        path: Our_team,
    },
    {
        id:11,
        path: PTE,
    },
    {
        id:12,
        path: Testimonials,
    },
    {
        id:13,
        path: Courses,
    },
    {
        id:14,
        path: Logo,
    },
    {
        id:15,
        path: Map,
    },
    {
        id:16,
        path: FAQ1,
    },
    {
        id:17,
        path: FAQ2,
    },
    {
        id:18,
        path: Login,
    },
    {
        id:19,
        path: thankyou,
    },
    // {
    //     id:20,
    //     path: Study20,
    // },
    // {
    //     id:21,
    //     path: Study21,
    // },
    // {
    //     id:22,
    //     path: Australia,
    // },
    // {
    //     id:23,
    //     path: Canada,
    // },
    // {
    //     id:24,
    //     path: UK,
    // },
    // {
    //     id:25,
    //     path: USA,
    // },
    // {
    //     id:26,
    //     path: NewZealand,
    // }
]