export const BASE_URL ={
    BaseURL :"https://api.aussieimmigration.com.au/api/"
}

export const APIENDPOINT = {
    // User Account API ENDPOINT
    POST_NEWUSER : "AussieImmigration/NewUserVist/",
    POST_USERLIST : "AussieImmigration/UserList/",
    POST_ADMINLOGIN : "AussieImmigration/AdminLogin/",

}

