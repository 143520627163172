import axios from 'axios';
import { APIENDPOINT, BASE_URL } from './ApiEndPoint';

export const  newUserVisiting = ( Name, Email,Phone, VisaType, Country, FromUser) => {
    const body = {
        "Name": Name, "Phone": Phone, 
        "Email" :Email, "VisaType" :VisaType, 
        "Country":Country, "FromUser" : FromUser
    };
    var headers = {
        "content-type" : "application/x-www-form-urlencoded",
        'Access-Control-Allow-Origin': '*',
    };
    const API_URL = BASE_URL.BaseURL + APIENDPOINT.POST_NEWUSER
    return axios
    .post(API_URL,body,headers)
    .then((res)=>res )
}


export const  adminLoginapi = async ( UserEmail,Password) => {
    const body = {
        "UserEmail" :UserEmail, "Password" :Password, 
    };
    var headers = {
        "content-type" : "application/x-www-form-urlencoded",
        'Access-Control-Allow-Origin': '*',
    };
    const API_URL = BASE_URL.BaseURL + APIENDPOINT.POST_ADMINLOGIN
    return axios
    .post(API_URL,body,headers)
    .then((res)=>res )
}