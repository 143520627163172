import React from "react";
import { NavLink } from "react-router-dom";
import SITE_PATHS from "../../Path";
import { CommonImg } from "../../../Images/CommonImg";
import { Study } from "../../../Images/Study";

export default function Course5() {


    return (
        <>

            <div className="page-title-area">
                <div className="container">
                    <div className="page-title-content">
                        <h4 className="text_size">Parent Visa</h4>
                        <ul>
                            <li><NavLink to={SITE_PATHS.LOGOCLICK}>Home</NavLink></li>
                            <li>Parent Visa</li>
                        </ul>
                    </div>
                </div>

                <div className="shape-img3">

                    <img src={CommonImg[1]?.path} ></img>
                </div>
            </div>

            <div className="about-area pb-100 bannermargin">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="about-img">
                                <img src={Study[70]?.path} className="ieltstest" alt="parent visa in australia"/>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="about-content">
                                <div className="content"><span className="sub-title">
                                    <img src={CommonImg[0]?.path} alt="services"/> 
                                    Services</span>
                                    <h1 className="text_size">Parent Visa</h1>
                                    <h3>(Subclass 103 & Subclass 870)</h3>

                                    <h4>Subclass 103</h4>

                                    <p>An eligible New Zealand citizen or parent of an established Australian citizen may relocate to Australia with the assistance of this permanent visa.</p>

                                    <h4>What are your options with this visa?</h4>

                                    <p>You can remain in Australia indefinitely to work, study, and live with this visa. You can enroll in Medicare, Australia's universal health insurance program. You can pay for your family members' travel to Australia. Apply for Australian citizenship if you meet the requirements.</p>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="our-mission-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-content">
                                <div className="content"><span className="sub-title">
                                    <img src={CommonImg[0]?.path} alt="services"/> 
                                    Services</span>
                                    <h2 className="text_size">For five years, travel to and from Australia</h2>

                                    <p>Within five years of receiving your visa, you are free to make as many trips as you like to and from Australia. This holds accurate for as long as this visa's travel authorization is still valid.</p>

                                    <h3>If you wish to travel after the first 5-year travel facility:a</h3>

                                    <p>A Resident Return (RRV) must be requested and approved for you to return to Australia as a permanent resident. You might also wish to think about becoming an Australian citizen. You can enter Australia without a visa if you become an Australian citizen. To leave and enter Australia again, you must obtain an Australian passport.</p>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-image">
                                <img className="ieltstest" src={Study[72]?.path} alt="service"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <div className="about-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="about-img">
                                <img className="ieltstest" src={Study[71]?.path} alt="service"/>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="about-content">
                                <div className="content"><span className="sub-title">
                                    <img src={CommonImg[0]?.path} alt="services"/> 
                                    Services</span>
                                    <h2 className="text_size">Can you include family members?</h2>

                                    <p>When you apply, you may list members of your family unit.</p>

                                    <p>Regardless of whether they are moving, you must provide information about every family member in your application.</p>

                                    <p>The conditions for character and health must be met by family members applying for visas. Even relatives who aren't traveling to Australia might need to pass the health and character tests.</p>

                                    <h3>Retirement-related applications for Parent visas (subclass 103)</h3>

                                    <p>You are exempt from the following requirements if you apply for a retiree subclass 103 visa:</p>

                                    <p>Satisfy the "balance of family" requirement, or have a sponsor or other assurance of support.</p>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="about-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                            <div className="our-mission-content">
                                <div className="content"><span className="sub-title">
                                    <img src={CommonImg[0]?.path} alt="services"/> 
                                    Services</span>
                                    <h3 className="text_size">Visa Processing Fees</h3>

                                    <p>The cost of this visa is AUD 6,625. Each extra family member that applies with you will incur a fee. This visa is paid for in two equal payments, or installments.</p>

                                    <p>When you apply, pay the initial installment. When you are asked, send the second payment.  Additional fees for background checks, police clearances, and biometrics may be required for each application.</p>

                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-12">
                            <div className="about-img">
                                <img className="ieltstest" src={Study[73]?.path} alt="processing fees in australia"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="about-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-12">
                            <div className="content contentboxheader"><span className="sub-title">
                                <img src={CommonImg[0]?.path} alt="services"/> 
                                Services</span>
                                <h3 className="text_size">Documents Required</h3>

                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="about-content">
                                <div className="content contentbox">

                                    <p>1.	Your national identification card and any supporting documents for name changes can serve as identity proof. Documentation demonstrating a name change includes marriage or divorce certificates, documents from the Australian Registry of Births, Deaths, and Marriages or the appropriate foreign body, and records of previous names you have gone by.</p>

                                    <p>2.	Your application requires a qualified sponsor unless you are a retiree. Your sponsor is required to fill out Form 40, Sponsorship for Migration to Australia.</p>

                                    <p>3.	Should meet the criteria for the balance of family test by providing proof of your relationships with each kid, information about where they live permanently, or documentation that they shouldn't be counted in the balance of family test.</p>

                                    <p>4.	Character documents with police verification certificates should be included.</p>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="about-content">
                                <div className="content">
                                    <p>5.	A current (45mm x 35mm), front-facing image with a white backdrop, taken within the last six months, should be given.</p>

                                    <p>6.	Use Form 956A Appointment or Withdrawal of an Authorized Recipient to designate someone to receive your letter and Form 956 Appointment of a Registered Migration Agent, Legal Practitioner, or Exempt Person to designate someone to provide immigration assistance.</p>

                                    <p>7.	You must demonstrate this person's reliance on you. Provide:</p>

                                    <p>○	Form 47a, "Details of a Child or Other Dependent Family Member, Aged 18 or Over," completed.</p>

                                    <p>○	An adoption certificate or birth certificate might serve as evidence of your relationship with the dependent.</p>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="about-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="about-img">
                                <img className="ieltstest" src={Study[92]?.path} alt="eligibility"/>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="about-content">
                                <div className="content"><span className="sub-title">
                                    <img src={CommonImg[0]?.path} alt="services"/> 
                                    Services</span>
                                    <h3 className="text_size">Eligibility</h3>

                                    <p>1.	If you now possess or have previously applied for a Sponsored Parent (Temporary) (subclass 870) visa, you are ineligible to apply for this one.</p>

                                    <p>2.	You require a sponsor who meets the requirements to do so. A child that accepts sponsorship would typically sponsor you. If your child is under 18, you may be supported by a qualified family or group from the neighborhood. The Department of Home Affairs must approve the sponsorship.</p>

                                    <p className="mb-3">3.	You don't require a sponsor if you apply for this visa as a retiree.</p>
                                    
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">

                        <div className="content missincintbox">
                            <p>4.	A guarantee of assistance must be attainable. According to an assurance of support, you won't require government aid after you reach Australia on this visa.</p>

                            <p>5.	You must be in good health if you and any family members apply for the visa together.</p>
                        </div>

                    </div>
                    </div>
                </div>
            </div>

            <div className="about-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                            <div className="our-mission-content">
                                <div className="content"><span className="sub-title">
                                    <img src={CommonImg[0]?.path} alt="services"/> 
                                    Services</span>

                                    <p>6.	The character criteria must be met by you and any family members applying for the visa with you.</p>

                                    <p>7.	If you or any family members owe the Australian Government money, you or they must have already paid it back or have a formal agreement in place that has been approved to repay it.</p>

                                    <p className="mb-3">8.	If a visa was denied or revoked while you were in Australia, you could not be qualified for this one.</p>

                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-12">
                            <div className="about-img">
                                <img className="ieltstest" src={Study[74]?.path} alt="Eligibility"/>
                            </div>
                        </div>

                        <div className="col-lg-12">

                        <div className="content missincintbox">
                            <p>9.	The Life in Australia book must have been read or explained to you if you are at least 18 years old. Declare your commitment to upholding Australian law and the Australian way of life by signing the Australian Values Statement.</p>

                            <p>10.	Have health insurance to protect yourself from financial risk as it relates to your health and to prevent any unfortunate events.</p>
                        </div>

                    </div>
                    </div>
                </div>
            </div>

            <div className="about-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="about-img">
                                <img className="ieltstest" src={Study[75]?.path} alt="parent visa"/>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="about-content">
                                <div className="content"><span className="sub-title">
                                    <img src={CommonImg[0]?.path} alt="services"/> 
                                    Services</span>
                                    <h3 className="text_size">Steps to apply for the Subclass 103 parent visa</h3>

                                    <p>1.	Applicants must have a valid passport, to get a visa.</p>

                                    <p>2.	A registered migration agency, a lawyer, or an exempt person must be chosen if you need someone to help with your immigration.</p>

                                    <p>3.	Withdraw the application if applied for a different parent visa.</p>

                                    <p>4.	You must fill out form 47PT, Part C if you have applied for Migration by Contributory Parent (Temporary) or Contributory Aged Parent (Temporary) Visa Holder. Also, Part B of Form 47PA Application for a Parent to Migrate to Australia must be completed if you apply for an Aged Parent visa (subclass 804).</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="our-mission-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-content">
                                <div className="content"><span className="sub-title">
                                    <img src={CommonImg[0]?.path} alt="services"/> 
                                    Services</span>
                                    
                                    <p>5.	Submit your documents.</p>

                                    <p>6.	For the visa, a paper application is required. You may submit your application while within or outside of Australia, but you must not yet have obtained immigration clearance.</p>

                                    <p>7.	When submitting your application as a retiree, you must be present in Australia but not in the process of receiving immigration clearance. Depending on where we decide, you may be within or outside of Australia. If you now possess or have previously applied for a Sponsored Parent (Temporary) (subclass 870) visa, you are ineligible to apply for this one.</p>

                                    <p>8.	You will be informed about the acceptance or rejection of your visa application. No Refund will be issued if your visa application gets rejected.</p>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-image">
                                <img className="ieltstest" src={Study[76]?.path} alt="parent visa"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="about-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="about-img">
                                <img className="ieltstest" src={Study[77]?.path} alt="subclass 870 in australia"/>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="about-content">
                                <div className="content"><span className="sub-title">
                                    <img src={CommonImg[0]?.path} alt="services"/> 
                                    Services</span>
                                    <h3 className="text_size">Subclass 870</h3>

                                    <h4 className="text_content">Sponsored Parent ( Temporary) Visa</h4>

                                    <p>Using this short-term or temporary visa, a parent of an Australian national, an Australian permanent resident, or a New Zealand national who qualifies can travel to Australia for three or five years.</p>

                                    <h5 className="text_content">Visa Processing Fees</h5>

                                    <p>For Visa for 3 years, the processing fee is- Up to 3 years AUS 5090, and for Up to 5 years AUD 10,180. </p>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="our-mission-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-content">
                                <div className="content"><span className="sub-title">
                                    <img src={CommonImg[0]?.path} alt="services"/> 
                                    Services</span>
                                    <h3 className="text_size">Visa Processing Time</h3>
                                    
                                    <p>The visa processing time varies from 53 days to 10 months. </p>

                                    <h4 className="text_content">What can you do with this visa?</h4>

                                    <p>Depending on your eligibility, you may renew your visa for an additional 3–5 years, up to 10 years, to visit Australia. Your inability to work in Australia with this visa is the only restriction.</p>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-image">
                                <img className="ieltstest" src={Study[78]?.path} alt="visa processing time"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="about-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="about-img">
                                <img className="ieltstest" src={Study[79]?.path} alt="documents required"/>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="about-content">
                                <div className="content"><span className="sub-title">
                                    <img src={CommonImg[0]?.path} alt="services"/> 
                                    Services</span>
                                    <h3 className="text_size">Documents Required</h3>

                                    <p>1.	Your national identification card and any supporting documents for name changes can serve as identity proof. Documentation demonstrating a name change includes marriage or divorce certificates, documents from the Australian Registry of Births, Deaths, and Marriages or the appropriate foreign body, and records of previous names you have gone by. </p>

                                    <p className="mb-3">2.	If you have spent a total of 12 months or more in Australia in the past ten years since turning 16, please provide an Australian Police Certification issued by the Australian Federal Police. Include a police certificate from your native country as well as, if applicable, your military service record.</p>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">

                        <div className="content missincintbox">
                            <p>3.	If you want to designate someone to offer immigration help, you need to Use Form 956A Appointment or Withdrawal of an Authorized Recipient to designate someone to receive your letter, and Form 956 Appointment of a Registered Migration Agent, Legal Practitioner, or Exempt Person.</p>

                            <p>4.	Translate all non-English documents to English.</p>
                        </div>

                    </div>
                    </div>
                </div>
            </div>

            <div className="about-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-12">
                            <div className="content contentboxheader"><span className="sub-title">
                                <img src={CommonImg[0]?.path} alt="eligibility services in australia"/>
                                Services</span>
                                <h3 className="text_size">Eligibility </h3>

                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="about-content">
                                <div className="content contentbox">

                                    <p>1.	You must be the parent, stepparent, or parent-in-law of the Parent Sponsor to be eligible to apply. This includes adoptive and legal parents as well as stepparents. Minimum age of 18 is required.</p>

                                    <p>2.	This visa does not require an assurance of support or to pass the balance of family test.</p>

                                    <p>3.	To be eligible to apply for this visa, your parent sponsor must be authorized.</p>

                                    <p>4.	The Department of Home Affairs' health requirements must be met by you.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="about-content">
                                <div className="content">

                                    <p>5.	You must own or have access to sufficient funds to cover your living expenses while you are in Australia.</p>

                                    <p>6.	You must only be visiting Australia for a short period.</p>

                                    <p>7.	The Australian government should not be owed anything by you.</p>

                                    <p>8.	For any unanticipated medical care you might require while you are in Australia, you must obtain and maintain suitable arrangements for health insurance from an Australian health insurance provider.</p>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="about-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="about-img">
                                <img className="ieltstest" src={Study[66]?.path} alt="Steps to apply parent visa"/>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="about-content">
                                <div className="content"><span className="sub-title">
                                    <img src={CommonImg[0]?.path} alt="services"/> 
                                    Services</span>
                                    <h3 className="text_size">Steps to apply</h3>

                                    <p>1.	Check your eligibility before applying. Make sure your parent sponsor is authorized. If a parent sponsor has not been accepted, you cannot apply for or be given this visa.</p>

                                    <p>2.	Collect all your documents.</p>

                                    <p>3.	Within six months following the Sponsorship's acceptance, you must apply online or from outside of Australia.</p>

                                    <p className="mb-3">4.	After applying for your visa application, you can check your visa status in your ImmiAccount. You can’t travel to Australia unless you are granted with visa.</p>

                                </div>
                            </div>
                        </div>

                        <div className="col-lg-12">

                        <div className="content missincintbox">
                        <p>5.	Form 1023 can be used to rectify any inaccurate information that was provided on the visa application.</p>

                        <p>6.	A decision on your visa will be made known to you. Your visa grant number and the dates it begins and ends are provided to you by the immigration department.</p>

                        <p>7.	Those whose applications are denied won't receive reimbursement.</p>

                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </>
    )
}