import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify"
import 'react-toastify/dist/ReactToastify.css';
import { adminLoginapi, newUserVisiting } from "../../ApiComponent/Apifunction";
import 'react-phone-input-2/lib/style.css'
import { validationEmail } from "../../ApiComponent/Validation";
import { Admin } from "../../Images/Admin";
import { setAuthType } from "../../Redux/usersList";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
export default function ContactUs() {
    const [state, setState] = useState({  Email: "", Password:""})
    const dispatch = useDispatch();
    let navigate = useNavigate();

    const submit = async () => {
        const { Email, Password } = state
        if (Email === "") {
            toast("Please Enter correct info for Email");
        }
        else if (Password === "") {
            toast("Please Enter correct info for Email");
        }
        else {
            const data = await adminLoginapi(Email, Password)
            console.log("dat=============", data)
            if(data.data.Success === true){
                const userdata = data?.data?.Message[0]
                const storageObj = {
                  username : userdata.name,
                  email : userdata.email,
                  UserID : userdata.u_id,
                };
                localStorage.setItem("auth_session", JSON.stringify(storageObj));
                dispatch(setAuthType(true));
                navigate("./dashboard");
              }

            console.log("data-------------", data)
            // else{
            //     toast("Details Saved Succesfully");
            // }
            }
        
    }

    return (
        <>


            <section className="contact-area pb-100_login">
                <div className="container">
                    <div className="section-title">
                    </div>
                    <div className="row">
                        <div className="col-lg-6 col-md-12">
                            <div className="contact-image"><img
                                src={Admin[0]?.path}
                                alt="contact" /></div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="contact-form login_form">
                                <form id="contactForm">
                                    <h3 className="landingheadeing">Login</h3>
                                    <div className="row">
                                        <div className="col-lg-12 col-md-6">
                                            <div className="form-group">
                                                <input type="email" name="email"
                                                    onChange={(e) => setState({ ...state, Email: e.target.value })}
                                                    value={state.Email} className="form-control" required="" placeholder="Your email address" />
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-md-6">
                                            <div className="form-group"><input type="password" name="password" className="form-control"
                                                onChange={(e) => setState({ ...state, Password: e.target.value })}
                                                value={state.Password}
                                                required="" placeholder="Enter Password" /></div>
                                        </div>

                                        <div className="col-lg-12 col-md-12"><div type="submit" onClick={() => submit()} className="default-btn"><i
                                            className="fas fa-check"></i>Start Now <span></span></div></div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}