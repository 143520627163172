import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { CommonImg } from "../../Images/CommonImg";
import { Image } from "../../Images/Image";
import SITE_PATHS from "../Path";

export default function FAQ() {


  return (
    <>
     <div className="page-title-area">
                <div className="container">
                    <div className="page-title-content">
                        <h4 className="text_size">FAQ</h4>
                        <ul>
                            <li><NavLink to={SITE_PATHS.LOGOCLICK}>Home</NavLink></li>
                            <li>FAQ</li>
                        </ul>
                    </div>
                </div>

                <div className="shape-img3">

                <img className="bannermargin_img" src={CommonImg[5]?.path}></img>
                </div>
             
            </div>
            <div className="ptb-100 bannermargin">
                <div className="container">
                    <div className="faq-accordion">
                        <div data-accordion-component="Accordion" className="accordion">
                            <div data-accordion-component="AccordionItem" className="accordion__item">
                                <div data-accordion-component="AccordionItemHeading" role="heading"
                                    className="accordion__heading" aria-level="3">
                                    <div className="accordion__button" id="accordion__heading-raa-0" aria-disabled="false"
                                        aria-expanded="false" aria-controls="accordion__panel-raa-0" role="button"
                                        tabindex="0" data-accordion-component="AccordionItemButton">Q1. How much of a score on the IELTS is needed to study in Australia?
                                    </div>
                                </div>
                                <div data-accordion-component="AccordionItemPanel" className="accordion__panel"
                                    role="region" aria-labelledby="accordion__heading-raa-0" id="accordion__panel-raa-0"
                                    hidden="">
                                    <p>IELTS minimum requirements for studying in Australia typically call for an overall score of 6.5 and a postgraduate degree band of no less than 6.0.</p>
                                </div>
                            </div>

                            <div data-accordion-component="AccordionItem" className="accordion__item">
                                <div data-accordion-component="AccordionItemHeading" role="heading"
                                    className="accordion__heading" aria-level="3">
                                    <div className="accordion__button" id="accordion__heading-raa-1" aria-disabled="false"
                                        aria-expanded="false" aria-controls="accordion__panel-raa-1" role="button"
                                        tabindex="0" data-accordion-component="AccordionItemButton">Q2. What distinguishes the "Aussie Immigration Consultancy" from other immigration consultancies?</div>
                                </div>
                                <div data-accordion-component="AccordionItemPanel" className="accordion__panel"
                                    role="region" aria-labelledby="accordion__heading-raa-1" id="accordion__panel-raa-1"
                                    hidden="">
                                    <p>For many years, we have offered consulting services without any flaws. Our experts put in a lot of effort to give you the best assistance possible with visa matters.</p>
                                </div>
                            </div>

                            <div data-accordion-component="AccordionItem" className="accordion__item">
                                <div data-accordion-component="AccordionItemHeading" role="heading"
                                    className="accordion__heading" aria-level="3">
                                    <div className="accordion__button" id="accordion__heading-raa-2" aria-disabled="false"
                                        aria-expanded="false" aria-controls="accordion__panel-raa-2" role="button"
                                        tabindex="0" data-accordion-component="AccordionItemButton">Q3. I was turned down for an Australia visa. Can I reapply?</div>

                                </div>
                                <div data-accordion-component="AccordionItemPanel" className="accordion__panel"
                                    role="region" aria-labelledby="accordion__heading-raa-2" id="accordion__panel-raa-2"
                                    hidden="">
                                    <p>If PIC 4013 is not waived, you will have to wait three years before you can apply for another visa.</p>
                                </div>
                            </div>

                            <div data-accordion-component="AccordionItem" className="accordion__item">
                                <div data-accordion-component="AccordionItemHeading" role="heading"
                                    className="accordion__heading" aria-level="3">
                                    <div className="accordion__button" id="accordion__heading-raa-3" aria-disabled="false"
                                        aria-expanded="false" aria-controls="accordion__panel-raa-3" role="button"
                                        tabindex="0" data-accordion-component="AccordionItemButton">Q4. How long does it take to complete an Australian student visa?</div>
                                </div>
                                <div data-accordion-component="AccordionItemPanel" className="accordion__panel"
                                    role="region" aria-labelledby="accordion__heading-raa-3" id="accordion__panel-raa-3"
                                    hidden="">
                                    <p>The average processing period for an Australian student visa is one month, which might take up to three months.</p>
                                </div>
                            </div>

                            <div data-accordion-component="AccordionItem" className="accordion__item">
                                <div data-accordion-component="AccordionItemHeading" role="heading"
                                    className="accordion__heading" aria-level="3">
                                    <div className="accordion__button" id="accordion__heading-raa-4" aria-disabled="false"
                                        aria-expanded="false" aria-controls="accordion__panel-raa-4" role="button"
                                        tabindex="0" data-accordion-component="AccordionItemButton">Q5. What accreditation must I have to work in Australia?</div>
                                </div>
                                <div data-accordion-component="AccordionItemPanel" className="accordion__panel"
                                    role="region" aria-labelledby="accordion__heading-raa-4" id="accordion__panel-raa-4"
                                    hidden="">
                                    <p>To work in Australia as a foreigner, you almost certainly need a visa unless you are a citizen of New Zealand. For all types of employees, including highly skilled, specialized, entrepreneurs, and company owners, Australia offers several various visa options.</p>
                                </div>
                            </div>

                            <div data-accordion-component="AccordionItem" className="accordion__item">
                                <div data-accordion-component="AccordionItemHeading" role="heading"
                                    className="accordion__heading" aria-level="3">
                                    <div className="accordion__button" id="accordion__heading-raa-3" aria-disabled="false"
                                        aria-expanded="false" aria-controls="accordion__panel-raa-3" role="button"
                                        tabindex="0" data-accordion-component="AccordionItemButton">Q6. Which Australian State Has the Easiest PR Process?</div>
                                </div>
                                <div data-accordion-component="AccordionItemPanel" className="accordion__panel"
                                    role="region" aria-labelledby="accordion__heading-raa-3" id="accordion__panel-raa-3"
                                    hidden="">
                                    <p>Tasmania can be an excellent alternative for someone looking to move to Australia. Considering that it provides a quick and straightforward route to Australian PR and citizenship.</p>
                                </div>
                            </div>

                            {/* <div data-accordion-component="AccordionItem" className="accordion__item">
                                <div data-accordion-component="AccordionItemHeading" role="heading"
                                    className="accordion__heading" aria-level="3">
                                    <div className="accordion__button" id="accordion__heading-raa-3" aria-disabled="false"
                                        aria-expanded="false" aria-controls="accordion__panel-raa-3" role="button"
                                        tabindex="0" data-accordion-component="AccordionItemButton">Q7. How much money would it cost you to relocate to Australia?</div>
                                </div>
                                <div data-accordion-component="AccordionItemPanel" className="accordion__panel"
                                    role="region" aria-labelledby="accordion__heading-raa-3" id="accordion__panel-raa-3"
                                    hidden="">
                                    <p>Moving to Australia typically costs AUD$14,000 ($10,500). This includes the usual visa fee of AUD$8,000 ($6,000) and the AUD$4,000 ($3,000) cost of shipping your personal belongings 9,000 kilometers around the globe.</p>
                                </div>
                            </div> */}

                        </div>
                    </div>
                </div>
            </div>
            <div className="project-start-area bg-color ptb-100">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="project-start-image">
                                <img src={Image[22]?.path} alt="Achieve your dream"/>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="project-start-content">
                                <h2 className="text_size">Achieve your dream and get our help in between.</h2>
                                <p>We make obtaining a visa easier and take the hassle with us. Solve all queries with our experts.</p><NavLink className="default-btn"
                                   to={SITE_PATHS.CONTACT}><i className="fas fa-long-arrow-alt-right"></i>Get
                                    Started<span></span></NavLink>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="vector-shape9">
                    <img src={CommonImg[16]?.path} alt="about"/>
                </div>
                <div className="vector-shape10">
                    <img src={CommonImg[15]?.path} alt="about"/>
                </div>
            </div>
    </>
  );
}