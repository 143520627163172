import React from "react";
import { NavLink } from "react-router-dom";
import { CommonImg } from "../Images/CommonImg";
import SITE_PATHS from "./Path";
import { FloatingWhatsApp } from 'react-floating-whatsapp'

export default function Footer() {

    return (
        <>

            <footer className="footer-area bg-color">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-sm-6">
                            <div className="single-footer-widget"><NavLink to={SITE_PATHS.LOGOCLICK}
                                className="logo"><img
                                    src={CommonImg[13]?.path}
                                    alt="logo" /></NavLink>
                                <p>We provide expert migration services through our experienced migration agents. Any concerns relating to visas are handled with the finest professional advice. We offer to coach non-native English speakers as well.</p>
                                
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-6">
                            <div className="single-footer-widget pl-5">
                                <h3>Explore</h3>
                                <ul className="footer-links-list">
                                    <li><NavLink to={SITE_PATHS.DASHBOARD}>Home</NavLink></li>
                                    <li><NavLink to={SITE_PATHS.ABOUT}>About</NavLink></li>

                                    <li><NavLink to={SITE_PATHS.HISTORY}>Our Blog</NavLink></li>
                                    <li><NavLink aria-current="page" className=""
                                        to={SITE_PATHS.CONTACT}>Contact</NavLink></li>
                                </ul>
                            </div>
                        </div>

                        {/* <div className="col-lg-3 col-sm-6">
                            <div className="single-footer-widget">
                                <h3>India</h3>
                                <ul className="footer-contact-info">

                                    <li><i className="fas fa-map-marker-alt"></i>SCO-58, Emperor Square, TDI City Sonipat Haryana, India
                                    </li>
                                    <li><i className="fas fa-phone-alt"></i><a href="tel:+918295195516">(+91) 82951-95516</a>
                                    </li>
                                </ul>
                                <ul className="social-link">
                                    <li><NavLink className="d-block" target="_blank" rel="noreferrer"
                                        to={SITE_PATHS.CONTACT}><i className="fab fa-facebook-square"></i></NavLink></li>
                                    <li><NavLink className="d-block" target="_blank" rel="noreferrer"
                                        to={SITE_PATHS.CONTACT}><i className="fab fa-twitter-square"></i></NavLink></li>
                                    <li><NavLink className="d-block" target="_blank" rel="noreferrer"
                                        to={SITE_PATHS.CONTACT}><i className="fab fa-instagram-square"></i></NavLink></li>
                                    <li><NavLink className="d-block" target="_blank" rel="noreferrer"
                                        to={SITE_PATHS.CONTACT}><i className="fab fa-linkedin"></i></NavLink></li>
                                </ul>
                            </div>
                        </div> */}

                        <div className="col-lg-5 col-sm-6">
                        <div className="single-footer-widget">
                                <h3>Head Office-Australia</h3>
                                <ul className="footer-contact-info">

                                    <li><i className="fas fa-map-marker-alt"></i>1/16 Mcdougall, ST Milton, 4064, QLD, Australia
                                    </li>
                                    <li><i className="fas fa-phone-alt"></i><a href="tel:485863005">(+61) 485 863 005</a>
                                    </li>
                                    <li><i className="fas fa-envelope"></i><a href="mailto:info@aussieimmigration.com.au">info@aussieimmigration.com.au</a>
                                    </li>
                                </ul>
                                <ul className="social-link">
                                    <li><NavLink className="d-block" target="_blank" rel="noreferrer"
                                        to={SITE_PATHS.CONTACT}><i className="fab fa-facebook-square"></i></NavLink></li>
                                    <li><NavLink className="d-block" target="_blank" rel="noreferrer"
                                        to={SITE_PATHS.CONTACT}><i className="fab fa-twitter-square"></i></NavLink></li>
                                    <li><NavLink className="d-block" target="_blank" rel="noreferrer"
                                        to={SITE_PATHS.CONTACT}><i className="fab fa-instagram-square"></i></NavLink></li>
                                    <li><NavLink className="d-block" target="_blank" rel="noreferrer"
                                        to={SITE_PATHS.CONTACT}><i className="fab fa-linkedin"></i></NavLink></li>
                                </ul>
                                
                            </div>
                        </div>

                    </div>
                    {/* <div className="App">
                        <FloatingWhatsApp
                            phoneNumber="+918447101910"
                            accountName="Aussie Immigration"
                            allowEsc
                            allowClickAway
                            notification
                            notificationSound
                        />
                    </div> */}
                    <div className="footer-bottom-area">
                        <div className="row align-items-center">
                            <div className="col-lg-6 col-md-6">
                                <p>@2022 | All rights reserved by <a href="" rel="noreferrer">Aussieimmigration.com.au</a></p>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <ul>
                                    <li><NavLink to={SITE_PATHS.POLICY}>Privacy Policy</NavLink>
                                    </li>
                                    <li><NavLink to={SITE_PATHS.TERMSOFSERVICE}>Terms &amp;
                                        Conditions</NavLink></li>

                                    {/* <li><NavLink to={SITE_PATHS.DISCLAIMER}>disclaimer </NavLink></li>  */}
                                </ul>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-map">
                    <img src={CommonImg[14]?.path} alt="footer-logo" />
                </div>
            </footer>
        </>
    );
}