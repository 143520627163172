import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { CommonImg } from "../../Images/CommonImg";
import { ServiceImage } from "../../Images/ServiceImage";
import { Study } from "../../Images/Study";
import SITE_PATHS from "../Path";


export default function Study_Visa_In_NewZealand() {


    return (
        <>
            <div className="page-title-area">
                <div className="container">
                    <div className="page-title-content">
                        <h4 className="text_size">New Zealand</h4>
                        <ul>
                            <li><NavLink to={SITE_PATHS.LOGOCLICK}>Home</NavLink></li>
                            <li>Study Visa In NewZealand</li>
                        </ul>
                    </div>
                </div>

                <div className="shape-img3">

                    <img  src={Study[30]?.path}></img>
                </div>
               
            </div>
            <div className="about-area pb-100 bannermargin">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="about-img">
                                <img src={Study[25]?.path} alt="Study Visa In New Zealand"/>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="about-content">
                                <div className="content"><span className="sub-title">
                                    <img src={CommonImg[0]?.path} alt="services"/> 
                                    Services</span>
                                    <h1 className="text_size">Study Visa In New Zealand</h1>
                                    <p>New Zealand has a lot to offer with a world-class educational system, internationally recognized credentials, a wealth of research possibilities, and an unrivaled quality of life, New Zealand has a lot to offer. You can count on a warm and inviting community while you are a student here and a first-rate overseas student aid system.</p>
                                    <p>All eight of the nation's institutions received excellent rankings in the QS World and Times higher education rankings to provide students the best chance. The nation's certification body New Zealand Qualification Authority (NZQA) makes that all universities are authorized to admit overseas students and adhere to the certification requirements.</p>

                                    <p>Among the few safest nations New Zealand holds the second spot on the 2018 Global Peace Index. The ideal blend of culture, environment, and opportunity for a student comprises internationally renowned institutions and an engaged educational system. New Zealand, an island nation and one of the last inhabited countries provide the ideal fusion of untainted environment, adventure, and calm.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="our-mission-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-content">
                                <div className="content"><span className="sub-title">
                                    <img src={CommonImg[0]?.path} alt="services"/> 
                                    Services</span>
                                    <h2 className="text_size">New Zealand Qualification Authority (NZQA)</h2>
                                    <p>NZQA's aim in the education sector is to ensure that New Zealand degrees are acknowledged as credible and trustworthy both domestically and internationally so that students may support themselves in their preferred interests and contribute to New Zealand society.</p>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-image">
                        <img className="ieltstest" src={Study[18]?.path} alt="New Zealand Qualification Authority"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <div className="about-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="about-img">
                                <img className="ieltstest" src={Study[9]?.path} alt="New Zealand Qualification"/>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="about-content">
                                <div className="content"><span className="sub-title">
                                    <img src={CommonImg[0]?.path} alt="services"/> 
                                    Services</span>
                                    <h2 className="text_size">New Zealand Qualification Framework (NZQF)</h2>

                                    <p>One of the oldest qualification frameworks in the world was the New Zealand Qualifications Framework (NZQF). It is the center of the educational system in New Zealand. All credentials listed on the NZQF, both secondary and tertiary, are backed by a quality guarantee that is recognized and trusted around the world.</p>

                                    <p>The NZQA is responsible for overseeing the NZQF and establishing the regulations that guarantee the caliber of the credentials published on the framework. They certify the certificates indicated on the framework for non-university education supply using these regulations. The Committee on University Academic Programs of Universities New Zealand has given its approval to the qualifications listed in the university framework.</p>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="our-mission-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-content">
                                <div className="content"><span className="sub-title">
                                    <img src={CommonImg[0]?.path} alt="services"/> 
                                    Services</span>
                                    <h3 className="text_size">Eligibility criteria for a student visa for New Zealand</h3>

                                    <p>1. You must meet the following requirements to be eligible for a student visa: Must be admitted to a program at an educational facility endorsed by the New Zealand Qualifications Authority or the Ministry of Education.</p>

                                    <p>2. Evidence that you have a scholarship that will cover your tuition expenses. You need to show evidence that you have significant cash to support yourself in New Zealand.</p>

                                    <p>3. You must provide evidence that you either already have a ticket or have access to enough funds to purchase one to leave New Zealand. Your passport must be valid for a minimum of three months beyond the duration of your stay in New Zealand.</p>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-image">
                                <img className="ieltstest" src={Study[14]?.path} alt="student visa in New Zealand"/>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12">

                        <div className="content missincintbox">
                            <p>4. You must use the New Zealand Immigration portal to submit your online student visa application. Up to 120 days before the program's start date of the program, you may apply application. Keep in mind that the date your application fee is paid is the day you submitted your application.</p>

                            <p>5. You must be in good physical and moral health to be granted a visa. Your medical background will be examined when you apply. A chest X-ray or other medical documentation may be required. To study in New Zealand, you must have a decent moral character. You must present a police certificate if you are over 17 and want to learn here for more than two years.</p>

                            <p>6. International students who are 9 years old or younger must reside with a parent or legal guardian or in a school hostel that has NZQA approval. Using the handwritten application, you must provide two color photos of your head and shoulders. A passport with at least three months remaining following the day you want to depart New Zealand.</p>
                        </div>

                    </div>
                </div>
            </div>



            <div className="our-mission-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-content">
                                <div className="content"><span className="sub-title">
                                    <img src={CommonImg[0]?.path} alt="services"/> 
                                    Services</span>
                                    <h3 className="text_size">You must demonstrate having one of the following to sustain yourself while you study in New Zealand:</h3>

                                    <p>1. Your first year of studies will cost you NZD $15,000.</p>

                                    <p>2. If your program of study lasts shorter than 36 months, you'll need NZD$1250 per month.</p>

                                    <p>3. A scholarship, such as one offered by the New Zealand Aid Programme.</p>

                                    <p>4. A sponsor or relative who has committed to providing financial support for you while you're in New Zealand.</p>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-image">
                                <img className="ieltstest" src={Study[15]?.path} alt="study in New Zealand"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="about-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="about-img">
                                <img className="ieltstest" src={Study[2]?.path} alt="Scholarship"/>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="about-content">
                                <div className="content"><span className="sub-title">
                                    <img src={CommonImg[0]?.path} alt="services"/> 
                                    Services</span>

                                    <h3 className="text_size">Scholarship</h3>

                                    <strong>Government-sponsored scholarships in New Zealand:</strong>

                                    <p>1. Fulbright New Zealand US Graduate Awards</p>

                                    <p>2. New Zealand International Doctoral Research Scholarships</p>

                                    <strong>3. New Zealand Foreign Affairs and Trade Scholarships New Zealand Scholarships aimed toward Universities:</strong>

                                    <p>1. Auckland University of Technology (AUT)- The institution offers several scholarships for overseas students.</p>

                                    <p>2. Lincoln University- Enables you to browse for scholarships appropriate to the subject and degree of your studies.</p>

                                    <p>3. Massey University Doctoral Scholarship- Grants for doctoral studies at the institution. Southern Institute of Technology</p>

                                    <p>4. The University of Auckland International Student Scholarships</p>

                                    <p>5. The University of Canterbury – Offers a variety of scholarships for overseas students.</p>

                                    <p>6. The University of Otago – Numerous postgraduate-level scholarships are available.</p>

                                    <p>7. The University of Waikato – Scholarships offered by the University of Waikato, such as the merit-based International Excellence Scholarship</p>

                                    <p>8. Victoria University of Wellington</p>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="our-mission-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-content">
                                <div className="content"><span className="sub-title">
                                    <img src={CommonImg[0]?.path} alt="services"/> 
                                    Services</span>
                                    <h3 className="text_size">1. IELTS</h3>

                                    <p>You must provide proof of your English language ability to obtain a student visa for New Zealand. To be able to demonstrate your communication and correspondence abilities often involves completing a secure English language exam.</p>

                                    <p>More than 10,000 organizations, including educational institutions, companies, professional groups, and governments, recognize the IELTS results in 140 different nations.</p>

                                    <p>In general, you must have an IELTS score of at least 6.5 overall, with no band lower than 6.0 for your postgraduate degree, to study in New Zealand. Additionally, you must have an IELTS score of at least 6.0 overall to apply for undergraduate choices. However, did you know that several New Zealand colleges allow admissions from students who have received a band of 5.5 in any of the IELTS sections- speaking, listening, reading, or writing? Therefore, if your most recent IELTS exam result was a 5.5, you may still apply in the reading or speaking sections.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-image">
                                <img className="ieltstest" src={ServiceImage[5]?.path} alt="IELTS"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="about-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="about-img">
                                <img className="ieltstest" src={Study[9]?.path} alt="Study in New Zealand"/>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="about-content">
                                <div className="content"><span className="sub-title">
                                    <img src={CommonImg[0]?.path} alt="services"/> 
                                    Services</span>
                                    <h3 className="text_size">2. Study in New Zealand Without an IELTS Score</h3>

                                    <p>Students without IELTS scores have a lot of possibilities in New Zealand. If you meet their specific language requirements and conditions, you can still apply to institutions in New Zealand. Here are a few of these:</p>

                                    <p>Class 8–12 may be used as confirmation of English-medium education. Or provide 5 years of elementary school coursework in English and 3 years of senior school. Apply without IELTS scores and be eligible for the interview with New Zealand authorities. You may also be enrolled in any institution if you submit the results of any other recognized English competency exams, such as the TOEFL, Duolingo English Test, or PTE.</p>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="our-mission-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-content">
                                <div className="content"><span className="sub-title">
                                    <img src={CommonImg[0]?.path} alt="services"/> 
                                    Services</span>
                                    <h3 className="text_size">3. Top Universities that don't require IELTS scores are listed below:</h3>

                                    <p>The University of Canterbury- Other English language competence exams beyond IELTS, such as TOEFL, PTE, CPE, and CAE, are accepted by this university.</p>

                                    <p>The University of Auckland- Accepts additional tests like TOEFL, PTE, University of Auckland Foundation Certificate in English for Academic Purposes (FCertEAP), University of Auckland English Pathway for Undergraduate Studies (EPUS), etc.</p>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-image">
                                <img className="ieltstest" src={Study[12]?.path} alt="IELTS scores"/>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12">

                        <div className="content missincintbox">
                        <h4 className="text_content">Lincoln University- Tests like the TOEFL, PTE and NZCEL are allowed.</h4>

                    <p>The University of Otago- Most other English proficiency test results, including those from the TOEFL, PTE, University of Auckland Foundation Certificate in English for Academic Purposes (FCertEAP), CPE, and CAE, are accepted.</p>

                    <p>Unitec Institute of Technology- Accepts a variety of exams, including the TOEFL, PTE, CPE, and CAE.</p>
                    </div>

                    </div>
                </div>
            </div>

        
            <div className="our-mission-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-content">
                                <div className="content"><span className="sub-title">
                                    <img src={CommonImg[0]?.path} alt="services"/> 
                                    Services</span>
                                    <h3 className="text_size">Cost of Study in New Zealand</h3>

                                    <p><strong>School Fees-</strong>For primary and secondary schools, respectively, the starting annual tuition is around $11,000 and $14,000. Beginning at roughly $25,000 per year, private elementary and secondary schools' tuition.</p>

                                    <p><strong>Diplomas/ Certificates-</strong>Pick a course that fits your budget. Consider enrolling in a one-year Diploma in Computing for $46,752 or a one-term New Zealand Certificate in Engineering for $23,376.</p>

                                    <p><strong>Bachelor’s Degree-</strong>The cost is between $20,000 to $40,000, with greater costs associated with fields like medicine and veterinary science.</p>

                                    <p><strong>Postgraduate Degree-</strong>The cost is somewhere between $20,000 and $45,000, with greater costs for fields like medicine and veterinary science.</p>

                                    <p><strong>PhDs-</strong>For the majority of disciplines, international Ph.D. students pay between $6,500 and $7,500 a year, which is the same as what New Zealand Ph.D. students pay.</p>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-image"> 
                            <img src={Study[2]?.path} alt="Cost of Study in New Zealand"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}