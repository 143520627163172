import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { CommonImg } from "../../Images/CommonImg";
import { ServiceImage } from "../../Images/ServiceImage";
import SITE_PATHS from "../Path";

export default function IELTS() {


    return (
        <>

            <div className="page-title-area">
                <div className="container">
                    <div className="page-title-content">
                        <h4 className="text_size">IELTS</h4>
                        <ul>
                            <li><NavLink to={SITE_PATHS.LOGOCLICK}>Home</NavLink></li>
                            <li>IELTS</li>
                        </ul>
                    </div>
                </div>

                <div className="shape-img3">

                    <img className="bannermargin_img" src={CommonImg[8]?.path}></img>
                </div>

            </div>

            <div className="about-area pb-100 bannermargin">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="about-img">
                                <img src={ServiceImage[5]?.path} alt="ielts"/>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="about-content">
                                <div className="content"><span className="sub-title">
                                    <img src={CommonImg[0]?.path} alt="services"/> 
                                    Services</span>
                                    <h1>IELTS</h1>
                                    <p>It's good to have you here. Want to go overseas but need to pass the IELTS exam? Let's remove your IELTS difficulties and inform you of what we are giving.</p>
                                    <h3>What is IELTS?</h3>
                                    <p>You can work, study, or immigrate to a region where English is the primary dialect by taking the International English Language Testing System (IELTS). This covers the United States, the United Kingdom, Australia, Canada, and New Zealand.</p>
                                    <p>During the test, your English listening, reading, writing, and speaking skills will be evaluated. A scale from 1 to 9 is used to evaluate IELTS tests.</p>
                                    <strong>IDP: IELTS Australia, Cambridge English, and the British Council all jointly own IELTS.</strong>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="our-mission-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-content">
                                <div className="content"><span className="sub-title">
                                    <img src={CommonImg[0]?.path} alt="services"/> 
                                    Services</span>
                                    <h2 className="text_size">IELTS test: Why take it?</h2>
                                    <p>You should be able to show that you have a strong command of the English language if you want to work, reside, or educate in an English-speaking nation. Considering 379 million speakers globally, English ranks third among all languages.</p>
                                    <p>It offers several merits to be able to converse in the dialect of the nation you intend to work or educate in. It is also necessary for getting a job and fitting into the community.</p>
                                    <p>The most common exam for those attempting to immigrate to countries like Australia, Canada, New Zealand, and the UK is IELTS. More than 11,000 enterprises, colleges, schools, and immigration agencies throughout the world, notably 3,400 establishments in the USA, acknowledge it.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-image">
                                <img className="ieltstest" src={ServiceImage[7]?.path} alt="ielts test"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <div className="about-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="about-img">
                                <img src={ServiceImage[4]?.path} alt="ielts score"/>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="about-content">
                                <div className="content"><span className="sub-title">
                                    <img src={CommonImg[0]?.path} alt="sevices"/> 
                                    Services</span>
                                    <h2 className="text_size">Do you need a certain IELTS score?</h2>
                                    <p>A higher IELTS score indicates a greater grasp of and capacity for communication in English. IELTS score criteria will vary depending on the institution, university, workplace, or immigration organization. What you want to accomplish in the nation, such as employment or study, will determine the score you require.</p>
                                    <h3>IELTS success is your goal? Follow along.</h3>
                                    <p>Every question in the IELTS exam is meant to assess your efficiency, attentiveness, and reaction time. Improve your precision and speed with our help. With us, you can fulfill your band score. Students at Aussie Consultancy are prepared for the global standards by qualified mentors. We also have support immigrants in meeting IELTS requirements for establishing English language fluency. At the Aussie Immigration Consultancy, we identify the strengths and shortcomings of our students and migrants and collaborate to enhance their skills. The mentors at our consultation will work with you to bring out your greatest qualities and make sure you acquire your desired results on your first try.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="our-mission-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-content">
                                <div className="content"><span className="sub-title">
                                    <img src={CommonImg[0]?.path} alt="services"/> 
                                    Services</span>
                                    <h3 className="text_size">What makes us the right choice?</h3>
                                    <p>With the help of our knowledgeable tutors, you'll get advice on how to study and attain the desired score more quickly.

                                        Join the Listening, Reading, Writing, and Speaking sections of the IELTS preparation program to get started with your practice tests.

                                        Do you desire to speak English effectively? In addition to receiving the name certification, our instructors will be happy to assist you with it.</p>
                                    <h4 className="text_content">We offer study materials</h4>
                                    <p>For the best test preparation, our Consultancy provides FREE study materials. The test papers from the previous year, exercise papers, a vocabulary book, audio materials, and other pertinent study materials are all included in our study materials. We make sure you obtain the study materials that will help you earn good grades.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-image">
                                <img src={ServiceImage[3]?.path} alt="What makes us the right choice"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}