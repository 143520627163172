import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  param: null,
  userdataList:[],
  authType : false
};

const usersList = createSlice({
  name: "parmaters",
  initialState,

  reducers: {
    setUsersList(state, action) {
      state.userdataList = action.payload;
    },
    setAuthType(state, action) {
      state.authType = action.payload;
    },
  },
});

export const {
  setUsersList,
  userdataList, authType, setAuthType
} = usersList.actions;
export default usersList.reducer;
