import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { CommonImg } from "../Images/CommonImg";
import SITE_PATHS from "./Path";
export default function LandingHeaderAus() {


    return (
        <>

            <div id="navbar" className="navbar-area">
                <div className="tarn-nav">
                    <div className="container-fluid">
                        <nav className="navbar navbar-expand-lg navbar-light">
                            <NavLink aria-current="page" to={SITE_PATHS.LANDINGPAGE1} className="navbar-brand" >
                                <img className="mob_logo" src={CommonImg[13]?.path} alt="logo" /></NavLink>
                            <button className="navbar-toggler navbar-toggler-right collapsed toogleshowhide"
                                type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
                                aria-controls="navbarSupportedContent" aria-expanded="false"
                                aria-label="Toggle navigation">
                                <span className="icon-bar top-bar"></span>
                                <span className="icon-bar middle-bar"></span>
                                <span className="icon-bar bottom-bar"></span></button>

                            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                <ul className="navbar-nav">

                                    <li className="nav-item"><NavLink className="nav-link"
                                    >Contact <i
                                        className="bx bx-chevron-down"></i></NavLink>
                                        <ul className="dropdown-menu">
                                            <li className="nav-item mb-2"> <div className="option-item"><a className="default-btn"
                                                href="tel:+61731529575"><i
                                                    className="bx bx-phone"></i>Australia: (+61) 7315-29575 <span></span></a></div></li>
                                            <li className="nav-item"><div className="option-item"><a className="default-btn"
                                                href="tel:+918295195516"><i
                                                    className="bx bx-phone"></i>India: (+91) 82951-95516 <span></span></a></div></li>
                                        </ul>
                                    </li>


                                </ul>
                                <div className="others-option d-flex align-items-center">


                                    <div className="option-item"><a className="default-btn"
                                        href="tel:+61731529575"><i
                                            className="bx bx-phone"></i> Call Now <span></span></a></div>


                                </div>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
        </>
    );
}