import React, { useEffect, useState } from "react";

export default function CoursesWithDetails() {


  return (
    <>
     <div className="page-title-area">
                <div className="container">
                    <div className="page-title-content">
                        <h2 className="text_size">Events</h2>
                        <ul>
                            <li><a href="https://aussieimmigration.com.au/In">Home</a></li>
                            <li>Events</li>
                        </ul>
                    </div>
                </div>
             
            </div>
            <div className="events-area pt-100 bannermargin pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-sm-6 col-md-6">
                            <div className="single-events-box">
                                <div className="image">
                                    <a className="d-block" href="https://aussieimmigration.com.au/Inevent-details">
                                        <img src="./img/event1-09ca432257422550380e17fd13aac86f.jpg" alt="event"/>
                                    </a><span className="date">Wed, 20 May, 2020</span>
                                </div>
                                <div className="content">
                                    <h3><a href="https://aussieimmigration.com.au/Inevent-details">Global Conference on
                                            Business Management</a></h3><span className="location"><i className="bx bx-map"></i>
                                        Vancover, Canada</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-6 col-md-6">
                            <div className="single-events-box">
                                <div className="image">
                                    <a className="d-block" href="https://aussieimmigration.com.au/Inevent-details">
                                        <img src="./img/event2-6f47f20490a2d3f3d34bae282adde24e.jpg" alt="event"/>
                                    </a><span className="date">Tue, 19 May, 2020</span>
                                </div>
                                <div className="content">
                                    <h3><a href="https://aussieimmigration.com.au/Inevent-details">International
                                            Conference on Teacher Education</a></h3><span className="location"><i
                                            className="bx bx-map"></i> Sydney, Australia</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-6 col-md-6">
                            <div className="single-events-box">
                                <div className="image">
                                    <a className="d-block" href="https://aussieimmigration.com.au/Inevent-details">
                                        <img src="./img/event3-d2571dff98c8dac75e60a0c5d1854400.jpg" alt="event"/>
                                    </a><span className="date">Mon, 18 May, 2020</span>
                                </div>
                                <div className="content">
                                    <h3><a href="https://aussieimmigration.com.au/Inevent-details">International
                                            Conference on Special Needs Education</a></h3><span className="location"><i
                                            className="bx bx-map"></i> Istanbul, Turkey</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-6 col-md-6">
                            <div className="single-events-box">
                                <div className="image">
                                    <a className="d-block" href="https://aussieimmigration.com.au/Inevent-details">
                                        <img src="./img/event4-665bcae1ceee7e39f6c6b5ec2efa8714.jpg" alt="event"/>
                                    </a><span className="date">Sun, 17 May, 2020</span>
                                </div>
                                <div className="content">
                                    <h3><a href="https://aussieimmigration.com.au/Inevent-details">International
                                            Conference on Literacy Teaching</a></h3><span className="location"><i
                                            className="bx bx-map"></i> Athens, Greece</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-6 col-md-6">
                            <div className="single-events-box">
                                <div className="image">
                                    <a className="d-block" href="https://aussieimmigration.com.au/Inevent-details">
                                        <img src="./img/event5-91854c4a14f459f134d8cbb650617576.jpg" alt="event"/>
                                    </a><span className="date">Sat, 16 May, 2020</span>
                            </div>
                                <div className="content">
                                    <h3><a href="https://aussieimmigration.com.au/Inevent-details">International
                                            Conference on Educational Administration</a></h3><span className="location"><i
                                            className="bx bx-map"></i> Rome, Italy</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-6 col-md-6">
                            <div className="single-events-box">
                                <div className="image">
                                    <a className="d-block" href="https://aussieimmigration.com.au/Inevent-details">
                                        <img src="./img/event6-9be5577db606361776363de9c0b6081c.jpg" alt="event"/>
                                    </a><span className="date">Fri, 15 May, 2020</span>
                                </div>
                                <div className="content">
                                    <h3><a href="https://aussieimmigration.com.au/Inevent-details">International
                                            Conference on Education and Pedagogy</a></h3><span className="location"><i
                                            className="bx bx-map"></i> Athens, Greece</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-6 col-md-6">
                            <div className="single-events-box">
                                <div className="image">
                                    <a className="d-block" href="https://aussieimmigration.com.au/Inevent-details">
                                        s<img src="./img/event7-9053e2f83cf51004afbf4878ca500507.jpg" alt="event"/>
                                    </a><span className="date">Thu, 14 May, 2020</span>
                                </div>
                                <div className="content">
                                    <h3><a href="https://aussieimmigration.com.au/Inevent-details">Research Conference
                                            Aims and Objectives</a></h3><span className="location"><i className="bx bx-map"></i>
                                        Tokyo, Japan</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-6 col-md-6">
                            <div className="single-events-box">
                                <div className="image">
                                    <a className="d-block" href="https://aussieimmigration.com.au/Inevent-details">
                                        <img src="./img/event8-2cfcd927e1209be804ace54b27d80dd0.jpg" alt="event"/>
                                    </a><span className="date">Wed, 13 May, 2020</span>
                                </div>
                                <div className="content">
                                    <h3><a href="https://aussieimmigration.com.au/Inevent-details">Conference on Gender
                                            Discrimination in Education</a></h3><span className="location"><i
                                            className="bx bx-map"></i> Oslo, Norway</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-6 col-md-6">
                            <div className="single-events-box">
                                <div className="image">
                                    <a className="d-block" href="https://aussieimmigration.com.au/Inevent-details">
                                        <img src="./img/event9-d0454a1c4ccb6b850d5a6c5ebdf89ff7.jpg" alt="event"/>
                                    </a><span className="date">Tue, 12 May, 2020</span>
                                </div>
                                <div className="content">
                                    <h3><a href="https://aussieimmigration.com.au/Inevent-details">Quality and
                                            Improvement in Education Conference</a></h3><span className="location"><i
                                            className="bx bx-map"></i> Tokyo, Japan</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </>
  );
}