
import React from "react";
import { NavLink } from "react-router-dom";
import SITE_PATHS from "../../Path";
import { CommonImg } from "../../../Images/CommonImg";
import { Study } from "../../../Images/Study";
import { Image } from "../../../Images/Image";

export default function Blog1() {


    return (
        <>
              <div className="page-title-area">
                <div className="container">
                    <div className="page-title-content">
                        <h2>Entry Requirements for Australian Universities</h2>
                        <ul>
                            <li><NavLink to={SITE_PATHS.LOGOCLICK}>Home</NavLink></li>
                            <li>Blog</li>
                        </ul>
                    </div>
                </div>

                <div className="shape-img3">

                    <img src={CommonImg[1]?.path} ></img>
                </div>
                <div className="shape-img3">
                    <img
                        src=""
                        alt="banner" />
                </div>
            </div>

            <div className="about-area pb-100 bannermargin">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="about-img"><img
                              src={Study[21]?.path} 
                                alt="service" /></div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="about-content">
                                <div className="content"><span className="sub-title"><img
                                   src={CommonImg[0]?.path} 
                                    alt="icon" /> Services</span>
                                    <h2>Reasons Why Most Of The Students Choose Australia For Study</h2>
                                    <p>In Australia, most Universities rank among the top 100 and pull foreign students to study there. Students from many countries want to study in Australia. Not only its education system is strong even you enjoy its seaside view and its vibrant culture. The popularity of studying in Australia is increasing daily. Here are the reasons why most students choose Australia for study.</p>
                                    <p>More than 30,000 international students from more than 190 different countries are now studying there. Even with the Covid 19's effect on the world, 8 Australian universities were in the top 100 as of the 2021 end-year report.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="our-mission-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-content">
                                <div className="content"><span className="sub-title"><img
                                   src={CommonImg[0]?.path} 
                                    alt="icon" /> Services</span>
                                    <h2>1.	Australia provides top-notch education.</h2>

                                    <p>Australia provides world-class education and frequently appears in international rankings, despite having a relatively limited number of universities.</p>

                                    <p>In the most recent QS World University Rankings, eight Australian institutions were ranked among the top 100, receiving praise for factors including academic standing, employer standing, and percentage of foreign students.</p>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-image"><img
                                className="ieltstest"   src={Image[1]?.path} 
                                alt="service" /></div>
                        </div>
                    </div>
                </div>
            </div>



            <div className="about-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="about-img"><img
                                className="ieltstest"   src={Study[0]?.path}
                                alt="service" /></div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="about-content">
                                <div className="content"><span className="sub-title"><img
                                   src={CommonImg[0]?.path} 
                                    alt="icon" /> Services</span>
                                    <h2>2.	Obtaining student visas is simple</h2>

                                    <p>If you're looking to get a student visa, Australia has a straightforward application process. You must meet several requirements, such as getting accepted into a school and having sufficient financial means, for your application to be approved. You also need to get enough health insurance to cover your stay.</p>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="our-mission-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-content">
                                <div className="content"><span className="sub-title"><img
                                   src={CommonImg[0]?.path} 
                                    alt="icon" /> Services</span>
                                    <h2>3.	Get innovative and rapid thinking skills.</h2>

                                    <p>Australian colleges, universities, and schools strive to provide you with the knowledge and abilities you need to succeed in your chosen area. Australian educational institutions have modified their teaching and learning methods to encourage thought-provoking creativity among students across all subject areas as the need for entrepreneurial and innovative thinkers increases globally.</p>

                                    <p>Your education at Australian colleges, universities, and schools will provide you with the information and skills you need to flourish in a world that is changing quickly and to stay adaptable under pressure.</p>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-image"><img
                                className="ieltstest"   src={Study[32]?.path} 
                                alt="service" /></div>
                        </div>
                    </div>
                </div>
            </div>



            <div className="our-mission-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                            <div className="our-mission-image"><img
                                className="ieltstest"   src={Study[5]?.path}
                                alt="service" /></div>
                        </div>

                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-content">
                                <div className="content"><span className="sub-title"><img
                                   src={CommonImg[0]?.path} 
                                    alt="icon" /> Services</span>
                                    <h2>4.	Simple conversation</h2>

                                    <p>You don't need to study Mandarin or Spanish. You won't have trouble conversing if you are fluent in English in Australia. You simply need to learn their slang and phrases, which somewhat alter their English, but once you do, you'll adore using such terms to converse.</p>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="our-mission-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-content">
                                <div className="content"><span className="sub-title"><img
                                   src={CommonImg[0]?.path} 
                                    alt="icon" /> Services</span>
                                    <h2>5.	Work Experience while studying</h2>

                                    <p>Numerous programs are offered so that students can get recognition on a worldwide scale and find rewarding employment. Many Australian institutions are among the top 100 in the world, thus their degrees are recognized all over the world. Consequently, this enhances the possibility of finding employment.</p>

                                    <p>To help you expand your professional network while you're a student, many Australian degrees and vocational courses provide internships and job experience opportunities. You could be qualified to remain and work in Australia once you've earned your degree.</p>

                                    <p>You are allowed to work in Australia while studying as well, but with the criteria that you can work only 20hrs/week. With this strategy, finding work related to your studies or that will allow you to live comfortably while you are in Australia may be possible.</p>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-image"><img
                                className="ieltstest"   src={Study[33]?.path} 
                                alt="service" /></div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="our-mission-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                            <div className="our-mission-image"><img
                                className="ieltstest"   src={Study[10]?.path}
                                alt="service" /></div>
                        </div>

                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-content">
                                <div className="content"><span className="sub-title"><img
                                   src={CommonImg[0]?.path} 
                                    alt="icon" /> Services</span>
                                    <h2>6.	Valuation of Australian Degree</h2>

                                    <p>Those who graduate from Australian universities are recognized worldwide. All organizations and sectors recognize these degrees, and they are thought to be of greater caliber than degrees from many other countries.</p>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="our-mission-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-content">
                                <div className="content"><span className="sub-title"><img
                                   src={CommonImg[0]?.path} 
                                    alt="icon" /> Services</span>
                                    <h2>7.	Education and Learning Process</h2>

                                    <p>Universities in Australia have created a distinctive structure promoting transformational learning theories because they think education is the cornerstone of development. The learning process is pace-controlled and includes techniques that assist students to cultivate critical thinking skills so they may become creative leaders.</p>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-image"><img
                                className="ieltstest"   src={Study[14]?.path} 
                                alt="service" /></div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="our-mission-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                            <div className="our-mission-image"><img
                                className="ieltstest"   src={Study[3]?.path}
                                alt="service" /></div>
                        </div>

                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-content">
                                <div className="content"><span className="sub-title"><img
                                   src={CommonImg[0]?.path} 
                                    alt="icon" /> Services</span>
                                    <h2>8.	Opportunities following graduation</h2>

                                    <p>Australia welcomes work opportunities for its university graduates. Therefore, if the students still love our country after spending so much time studying here, they are welcome to remain, work, and live here. The process is straightforward; the applicant only has to submit the required documentation when applying for a job.</p>

                                    <p>The student must then apply for a work visa while still in the country and produce evidence of employment once the position is secured. After receiving a change in visa status, the individual will be able to live, work, and earn a comfortable living in Australia.</p>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="our-mission-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-content">
                                <div className="content"><span className="sub-title"><img
                                   src={CommonImg[0]?.path} 
                                    alt="icon" /> Services</span>
                                    <h2>9.	Affordable Cost of Living</h2>

                                    <p>You might have heard about the high standard of life in Australia makes you think of your pocket. It’s not so for a student, as they can live in a shared apartment and can divide the house rent. Many student homestays inside the university as well. Both choices are student-friendly. Even they can earn also while studying.</p>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-image"><img
                                className="ieltstest"   src={Study[2]?.path} 
                                alt="service" /></div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="our-mission-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">

                    <div className="col-lg-6 col-md-12">
                            <div className="our-mission-image"><img
                                className="ieltstest"   src={Study[17]?.path} 
                                alt="service" /></div>
                        </div>
                        
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-content">
                                <div className="content"><span className="sub-title"><img
                                   src={CommonImg[0]?.path} 
                                    alt="icon" /> Services</span>
                                    <h2>Conclusion</h2>

                                    <p>In the end, it can be argued that immigration and migration have both beneficial and negative effects on the home country's economy. People move either because of their financial difficulties or because they are unemployed. These issues affect the national economy. There is a considerable loss of the youth workforce as young people depart for better prospects. Overall, the remittance was advantageous to the home nation as well.</p>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}