import React, {
  useState,
} from "react";
import { Popover, Tooltip, Modal, Button } from "react-bootstrap";
import { CommonImg } from "../Images/CommonImg";


const PopUp = (props) => {
  const { show, handleClose, title, body } = props;

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title> <img src={CommonImg[13]?.path}  alt="logo" /></Modal.Title>
          <a onClick={handleClose}>
            <svg width="13" viewBox="6.29 6.34 11.31 11.31">
              <path
                fill="#425b76"
                d="m16.192 6.344-4.243 4.242-4.242-4.242-1.414 1.414L10.535 12l-4.242 4.242 1.414 1.414 4.242-4.242 4.243 4.242 1.414-1.414L13.364 12l4.242-4.242z"
              ></path>
            </svg>
          </a>
        </Modal.Header>
        <Modal.Body>
          <p>
          We provide expert migration services through our experienced migration agents. Any concerns relating to visas are handled with the finest professional advice. We offer to coach non-native English speakers as well.

          </p>
         
        </Modal.Body>
        <Modal.Footer>
          <a className="d-flex align-items-center">
            <svg viewBox="2 2 20 20" width="14px">
              <path
                fill="#425b76"
                d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zm0 18c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8z"
              ></path>
              <path fill="#425b76" d="M11 11h2v6h-2zm0-4h2v2h-2z"></path>
            </svg>
           
          </a>
          
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PopUp;
