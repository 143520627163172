import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import SITE_PATHS from "../Path";

export default function TermsOfService() {


    return (
        <>

            <div className="page-title-area">
                <div className="container">
                    <div className="page-title-content">
                        <h2>Terms of Service</h2>
                        <ul>
                            <li><NavLink to={SITE_PATHS.LOGOCLICK}>Home</NavLink></li>
                            <li>Terms & Conditions</li>
                        </ul>
                    </div>
                </div>
               
            </div>

            <section className="terms-of-service-area bannermargin">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                        <h1>TERMS AND CONDITIONS</h1>
                        <p>
                        These terms and conditions (the "Terms and Conditions") govern the use of www.aussieimmigration.com.au (the "Site"). This Site is owned and operated by AUSSIE IMMIGRATION CONSULTANCY ABN 13 633 887 680. This Site is a help with finding migration and education agent.
                        </p>

                        <p>
                        By using this Site, you indicate that you have read and understand these Terms and Conditions and agree to abide by them at all times.
                        </p>

                        <p className="mb-4">THESE TERMS AND CONDITIONS CONTAIN A DISPUTE RESOLUTION CLAUSE THATIMPACTS YOUR RIGHTS ABOUT HOW TO RESOLVE DISPUTES. PLEASE READ IT CAREFULLY.
                        </p>
                        
                        </div>
                    </div>
                </div>
            </section>

            <section className="terms-of-service-area">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                        <h3>Intellectual Property</h3>
                        <p>
                        All content published and made available on our Site is the property of AUSSIE IMMIGRATION CONSULTANCY ABN 13 633 887 680 and the Site's creators. This includes, but is not limited to
                        images, text, logos, documents, downloadable files and anything that contributes to the composition of our Site. 
                        </p>

                        <h3>Age Restrictions</h3>

                        <p className="mb-3">The minimum age to use our Site is 16 years old. By using this Site, users agree that they are over 16 years old. We do not assume any legal responsibility for false statements about age.
                        </p>
                        
                        </div>
                    </div>
                </div>
            </section>

            <section className="terms-of-service-area">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                        <h3>Acceptable Use</h3>
                        <p>
                        As a user of our Site, you agree to use our Site legally, not to use our Site for illegal purposes, and not to:</p>

                        <p>1. Harass or mistreat other users of our Site;</p>

                        <p>2. Violate the rights of other users of our Site;</p>

                        <p>3. Violate the intellectual property rights of the Site owners or any third party to the Site;</p>

                        <p>4. Hack into the account of another user of the Site;</p>

                        <p>5. Act in any way that could be considered fraudulent; or</p>

                        <p>6. Post any material that may be deemed inappropriate or offensive.</p>

                        <p className="mb-3">If we believe you are using our Site illegally or in a manner that violates these Terms and Conditions, we reserve the right to limit, suspend or terminate your access to our Site. We also reserve the right to take any legal steps necessary to prevent you from accessing our Site.</p>
                        
                        </div>
                    </div>
                </div>
            </section>

            <section className="terms-of-service-area">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                        <h3>Third Party Goods and Services</h3>

                        <p>Our Site may offer goods and services from third parties. We cannot guarantee the quality or accuracy of goods and services made available by third parties on our Site.</p>

                        <h3>Links to Other Websites</h3>

                        <p>Our Site contains links to third party websites or services that we do not own or control. We are not responsible for the content, policies, or practices of any third party website or service linked to on our Site. It is your responsibility to read the terms and conditions and privacy policies of these third party websites before using these sites.</p>

                        <h3>Limitation of Liability</h3>

                        <p>AUSSIE IMMIGRATION CONSULTANCY ABN 13 633 887 680 and our directors, officers, agents, employees, subsidiaries, and affiliates will not be liable for any actions, claims, losses, damages, liabilities and expenses including legal fees from your use of the Site.</p>
                        
                        </div>
                    </div>
                </div>
            </section>

            <section className="terms-of-service-area">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                        <h3>Indemnity</h3>

                        <p>Except where prohibited by law, by using this Site you indemnify and hold harmless AUSSIE IMMIGRATION CONSULTANCY ABN 13 633 887 680 and our directors, officers, agents, employees, subsidiaries, and affiliates from any actions, claims, losses, damages, liabilities and expenses including legal fees arising out of your use of our Site or your violation of these Terms and Conditions.
                        </p>

                        <h3>Applicable Law</h3>

                        <p>These Terms and Conditions are governed by the laws of the State of Queensland.</p>

                        <h3>Dispute Resolution</h3>

                        <p>Subject to any exceptions specified in these Terms and Conditions, if you and AUSSIE IMMIGRATION CONSULTANCY ABN 13 633 887 680 are unable to resolve any dispute through informal discussion, then you and AUSSIE IMMIGRATION CONSULTANCY ABN 13 633 887 680 agree to submit the issue before a mediator. The decision of the mediator will not be binding. Any mediator must be a neutral party acceptable to both you and AUSSIE IMMIGRATION CONSULTANCY ABN 13 633 887 680. The costs of any mediation will be shared equally between you and AUSSIE IMMIGRATION CONSULTANCY ABN 13 633 887 680.</p>

                        <p className="mb-3">Notwithstanding any other provision in these Terms and Conditions, you and AUSSIE IMMIGRATION CONSULTANCY ABN 13 633 887 680 agree that you both retain the right to bring an action in small claims court and to bring an action for injunctive relief or intellectual property infringement. </p>
                        
                        </div>
                    </div>
                </div>
            </section>

            <section className="terms-of-service-area">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                        <h3>Severability</h3>

                        <p>If at any time any of the provisions set forth in these Terms and Conditions are found to be inconsistent or invalid under applicable laws, those provisions will be deemed void and will be removed from these Terms and Conditions. All other provisions will not be affected by the removal and the rest of these Terms and Conditions will still be considered valid.</p>

                        <h3>Changes</h3>

                        <p>These Terms and Conditions may be amended from time to time in order to maintain compliance with the law and to reflect any changes to the way we operate our Site and the way we expect users to behave on our Site. We will notify users by email of changes to these Terms and Conditions or post a notice on our Site.</p>

                        <h3>Contact Details</h3>

                        <p>Please contact us if you have any questions or concerns. Our contact details are as follows:</p>

                        <p>info@aussieimmigration.com.au</p>

                        <p>1/16 mcdougall st milton</p>

                        <p className="mb-3">You can also contact us through the feedback form available on our Site.</p>
                        
                        </div>
                    </div>
                </div>
            </section>

            <section className="terms-of-service-area">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                        <h3>Cookie Policy</h3>

                        <p>A cookie is a small file, stored on a user's hard drive by a website. Its purpose is to collect data relating to the user's browsing habits. You can choose to be notified each time a cookie is transmitted. You can also choose to disable cookies entirely in your internet browser, but this may decrease the quality of your user experience. </p>

                        <p>We use the following types of cookies on our Site:</p>

                        <p>1. Functional cookies</p>

                        <p>Functional cookies are used to remember the selections you make on our Site so that your selections are saved for your next visits;</p>

                        <p>2. Analytical cookies</p>

                        <p>Analytical cookies allow us to improve the design and functionality of our Site by collecting data on how you access our Site, for example data on the content you access, how long you stay on our Site, etc; and</p>

                        <p>3. Targeting cookies</p>

                        <p>Targeting cookies collect data on how you use the Site and your preferences. This allows us to personalise the information you see on our Site for you.</p>

                        <h3>Modifications</h3>

                        <p>This Privacy Policy may be amended from time to time in order to maintain compliance with the law and to reflect any changes to our data collection process. When we amend this Privacy Policy we will update the "Effective Date" at the top of this Privacy Policy. We recommend that our users periodically review our Privacy Policy to ensure that they are notified of any updates. If necessary, we may notify users by email of changes to this Privacy Policy. </p>

                        <h3>Contact Information</h3>

                        <p>If you have any questions, concerns or complaints, you can contact our privacy officer, Suvidha, at:</p>

                        <p>info@aussieimmigration.com.au</p>

                        <p className="mb-3">1/16 mcdougall st milton</p>
                        
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}