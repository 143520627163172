import React from "react";
import { NavLink } from "react-router-dom";
import SITE_PATHS from "../../Path";
import { CommonImg } from "../../../Images/CommonImg";
import { Study } from "../../../Images/Study";

export default function Course6() {


    return (
        <>

            <div className="page-title-area">
                <div className="container">
                    <div className="page-title-content">
                        <h4 className="text_size">Work Visa</h4>
                        <ul>
                            <li><NavLink to={SITE_PATHS.LOGOCLICK}>Home</NavLink></li>
                            <li>Work Visa</li>
                        </ul>
                    </div>
                </div>

                <div className="shape-img3">

                    <img src={CommonImg[1]?.path} ></img>
                </div>
            </div>

            <div className="about-area pb-100 bannermargin">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="about-img">
                                <img src={Study[80]?.path} className="ieltstest" alt="work visa in australia"/>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="about-content">
                                <div className="content"><span className="sub-title">
                                    <img src={CommonImg[0]?.path} alt="services"/> 
                                    Services</span>
                                    <h1 className="text_size">Work Visa</h1>

                                    <p>Australia is a bustling city with many employment options, making it a magnet for workers. You need a visa for the type of work you wish to undertake if you want to travel to Australia to work there.</p>

                                    <strong>The Australian government has established the following categories for the work migration program:</strong>

                                    <p className="mt-3">1.	Regional Work visas</p>

                                    <p>2.	Visa for Innovation</p>

                                    <p>3.	Short-stay work visas</p>

                                    <p>4.	Temporary work visas</p>

                                    <p>5.	Permanent work visas</p>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="our-mission-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-content">
                                <div className="content"><span className="sub-title">
                                    <img src={CommonImg[0]?.path} alt="services"/> 
                                    Services</span>
                                    <h2 className="text_size">1.	Regional Work visas</h2>

                                    <p>Qualified immigrants and their dependent family members who want to live and work in rural Australia may apply for one of two skilled regional provisional visas.</p>

                                    <p>a.	For persons who wish to reside and work in rural Australia and who have been nominated by a State or Territory government or sponsored by a qualified family member, there is the Skilled Work Regional (Provisional) Visa (subclass 491).</p>

                                    <p>b.	One can apply for a skilled employer-supported regional (provisional) visa if they are sponsored by an employer in a rural area of Australia.</p>

                                    <h3>Temporary skilled visas</h3>

                                    <p className="mb-3">a.	A sponsor is necessary to get a temporary skill shortage visa.</p>

                                </div>
                            </div>
                        </div>
                        
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-image">
                                <img className="ieltstest" src={Study[93]?.path} alt="regional work visas"/>
                            </div>
                        </div>
                        <div className="col-lg-12">

                        <div className="content missincintbox">
                            <p>You are permitted to live, work, and bring your family to a specific region of Australia for four years with a skilled regional provisional visa (Subclass 489). There are two entrance points for this visa: the Extended Stay pathway and the Invited track.</p>
                        </div>

                    </div>
                    </div>
                </div>
            </div>



            <div className="about-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="about-img">
                                <img className="ieltstest" src={Study[81]?.path} alt="visa for innovation"/>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="about-content">
                                <div className="content"><span className="sub-title">
                                    <img src={CommonImg[0]?.path} alt="services"/> 
                                    Services</span>
                                    <h2 className="text_size">1.	Visa for Innovation</h2>

                                    <p>Talent acquisition is becoming more competitive on a global scale. Consequently, Australia is trying to entice the most talented individuals to its borders to strengthen its economy.</p>

                                    <p>The Australian government founded the Global Australia Taskforce to lure highly skilled people, company owners, and high-value companies to Australia, along with their ideas, networks, and cash.</p>

                                    <p>To boost its economy, Australia has special initiatives aimed at attracting talent.</p>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="about-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                            <div className="our-mission-content">
                                <div className="content"><span className="sub-title">
                                    <img src={CommonImg[0]?.path} alt="services"/> 
                                    Services</span>
                                    <h3 className="text_size">The global talent visa program</h3>

                                    <p>Australia intends to employ the world's top people in 10 industries that are focused on the future. The Australian government created the initiative to boost the economy by bringing cutting-edge talents to high-priority industries to address this. As a result, Australians will have the opportunity to:</p>

                                    <p>●	Using new talents</p>

                                    <p>●	Encouraging innovation</p>

                                    <p>●	Generating employment</p>

                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-12">
                            <div className="about-img">
                                <img className="ieltstest" src={Study[82]?.path} alt="global talent visa program"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="about-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="about-img">
                                <img className="ieltstest" src={Study[84]?.path} alt="Short-Stay Work Visas"/>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="about-content">
                                <div className="content"><span className="sub-title">
                                    <img src={CommonImg[0]?.path} alt="services"/> 
                                    Services</span>
                                    <h3 className="text_size">2.	Short-Stay Work Visas</h3>

                                    <p>Types of Short-stay Work visas are:</p>

                                    <h4 className="text_content">A.	Working holiday maker Visa</h4>

                                    <p>Young adults who wish to work here to pay for an extended vacation can obtain this visa.</p>

                                    <p>a.	Subclass 462- Work and Holiday Visa</p>

                                    <p>b.	Subclass 417- Working Holiday Visa</p>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="about-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                            <div className="our-mission-content">
                                <div className="content"><span className="sub-title">
                                    <img src={CommonImg[0]?.path} alt="services"/> 
                                    Services</span>
                                    <h3 className="text_size">B. Pacific and seasonal work visas</h3>

                                    <p>Visas for employees who wish to take part in the Pacific Australia Labour Mobility (PALM) program.</p>

                                    <p>a.	Pacific Australia Labour Mobility Stream, Temporary Work (International Relations) Visa, Subclass 403, Requires Sponsorship</p>

                                    <h4 className="text_content">C. Temporary activity and specialist visas</h4>

                                    <p>This visa is for a highly specialized job, which is given temporarily to a limited person.</p>

                                    <p>a.	Sponsorship is required for some streams of the Temporary Activity Visa (Subclass 408).</p>

                                    <p>b.	Specialist visa for temporary employment (subclass 400).</p>

                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-12">
                            <div className="about-img">
                                <img className="ieltstest" src={Study[83]?.path} alt="seasonal work visas"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="about-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="about-img">
                                <img className="ieltstest" src={Study[85]?.path} alt="Temporary work visas"/>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="about-content">
                                <div className="content"><span className="sub-title">
                                    <img src={CommonImg[0]?.path} alt="services"/> 
                                    Services</span>
                                    <h3 className="text_size">Temporary work visas</h3>

                                    <p>You can go to Australia with a temporary work visa to work there. Your access may include restrictions that only let you operate in a specific field or for a particular firm.</p>

                                    <p>An interim or temporary visa that offers a permanent route is known as a provisional visa. About the temporary visa, you could be allowed to apply for a permanent visa.</p>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="about-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                            <div className="our-mission-content">
                                <div className="content"><span className="sub-title">
                                    <img src={CommonImg[0]?.path} alt="services"/> 
                                    Services</span>
                                    <h3 className="text_size">The list of possible temporary visas is below:</h3>

                                    <p>a.	Temporary Skill Shortage Visa - Subclass 482- for this visa you need sponsorship.</p>

                                    <p>b.	Temporary Graduate Visa- Subclass 482</p>

                                    <p>c.	Skilled- Recognized Graduate Visa- Subclass 476</p>

                                    <p>d.	Skilled Regional (provisional) visa- Subclass 489</p>

                                    <p>e.	Business Innovation and Investment (Provisional) visa- Subclass 188- For this visa also sponsorship is needed.</p>

                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-12">
                            <div className="about-img">
                                <img className="ieltstest" src={Study[86]?.path} alt="Investment visa"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="about-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="about-img">
                                <img className="ieltstest" src={Study[87]?.path} alt="regional visa in australia"/>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="about-content">
                                <div className="content"><span className="sub-title">
                                    <img src={CommonImg[0]?.path} alt="services"/> 
                                    Services</span>
                                    <h3 className="text_size">New Regional Visa</h3>

                                    <p>As of 16 November 2019, skilled immigrants and their dependant family members who choose to reside and work in rural Australia will have access to two new skilled regional provisional visas.</p>

                                    <p>a.	Skilled Work Rural (Provisional) Visa (Subclass 491) - for those sponsored by a qualifying family member or nominated by a State or Territory government to live and work in regional Australia.</p>

                                    <p>b.	Skilled Company Sponsored Regional (Provisional) Visa (Subclass 494) - for individuals sponsored by an employer in regional Australia.</p>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="our-mission-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-content">
                                <div className="content"><span className="sub-title">
                                    <img src={CommonImg[0]?.path} alt="services"/>
                                     Services</span>
                                    <h3 className="text_size">Permanent work visas</h3>
                                    
                                    <p>If you have an Australian permanent visa, you are a permanent resident of Australia. Below is the list of permanent work visas:</p>

                                    <h4 className="text_content">A.	Regional Visa</h4>

                                    <p>Skilled migrant visas to reside and work in remote Australia.</p>

                                    <p>a.	The Regional Sponsor Migration Scheme (subclass 187) requires sponsorship.</p>

                                    <p>b.	Permanent Residence (Skilled Regional) Visa (subclass 191).</p>

                                    <h5 className="text_content">B.	Skilled migration visas</h5>

                                    <p>Qualified immigrants can live and work anywhere in Australia with the proper visas.</p>

                                    <p>a.	Subclass 186- Employer Nomination Scheme Visa- Sponsorship needed</p>

                                    <p>b.	Subclass 189- Skilled Independent Visa</p>

                                    <p>c.	Subclass 190- Skilled Nominated Visa- Sponsorship needed</p>

                                    <p>d.	Subclass 887- Skilled Regional Visa</p>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-image">
                                <img className="ieltstest" src={Study[88]?.path} alt="Permanent work visas"/>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>

            <div className="about-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="about-img">
                                <img className="ieltstest" src={Study[89]?.path} alt="Business investment visas"/>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="about-content">
                                <div className="content"><span className="sub-title">
                                    <img src={CommonImg[0]?.path} alt="services"/> 
                                    Services</span>
                                    <h3 className="text_size">C.	Business investment visas</h3>

                                    <p>Entrepreneurs, investors, and company owners can apply for visas to stay in Australia and start new businesses or expand current ones.</p>

                                    <p>a.	Subclass 888- Business and Investment Visa (Permanent).</p>

                                    <p>b.	Subclass 132- Business Talent Visa (Permanent)</p>

                                    <p>For both Subclass 888 and Subclass132, sponsorship is needed.</p>

                                    <h4 className="text_content">D.	Global Talent visas</h4>

                                    <p>Visas for candidates for the Distinguished Talent path and Global Talent pathway who have a history of exceptionally high success in an area that qualifies, as well as for those who do. Applicants must be nominated for the Global Talent Visa (subclass 858).</p>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="our-mission-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-content">
                                <div className="content"><span className="sub-title">
                                    <img src={CommonImg[0]?.path} alt="services"/> 
                                    Services</span>
                                    <h3 className="text_size">Cost of a Work Visa</h3>
                                    
                                    <p>It costs between AUD315 to AUD4,115 to obtain an Australia work visa. Depending on the stream you apply for within a given subclass, you may occasionally not be required to pay a visa fee.</p>

                                    <h4 className="text_content">Conditions for Obtaining Work Visas in Australia</h4>

                                    <p>The following are the requirements for an Australian work visa:</p>

                                    <p>1.	Three years of necessary professional experience.</p>

                                    <p>2.	Should have Medical examination certificate.</p>

                                    <p>3.	Should have English language proficiency.</p>

                                    <p className="mb-3">4.	Age should be under 45 years of age.</p>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-image">
                                <img className="ieltstest" src={Study[90]?.path} alt="Cost of a Work Visa"/>
                            </div>
                        </div>

                        <div className="col-lg-12">

                        <div className="content missincintbox">
                            <p>5.	One should read and understand in details of the Life in Australia booklet. This book is for those who want to reside in Australia permanently or temporarily and are applying for a visa.</p>

                            <p>6.	The Australian Values Statement is properly signed.</p>
                        </div>

                    </div>
                    </div>
                </div>
            </div>

            <div className="about-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="about-img">
                                <img className="ieltstest" src={Study[91]?.path} alt="Procedure for Applying"/>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="about-content">
                                <div className="content"><span className="sub-title">
                                    <img src={CommonImg[0]?.path} alt="services"/> 
                                    Services</span>
                                    <h3 className="text_size">Procedure for Applying</h3>

                                    <p>Work permits in Australia fall into two types: sponsored visas and skilled visas. As an employer, you may conceive of both categories as an independent. An online Expression of Intent (EOI) through SkillSet must be submitted by your employee if they require a skilled visa. Applications for competent applicants are submitted using this online system, which also processes them. They will be awarded points for things like age, aptitude, education, language prowess, and more. They will get a recommendation from the Australian state territory if their profile is chosen. </p>

                                    <p>Employees applying for sponsored visas must have your sponsorship. A nomination transaction reference number (TRN) or employment ID is required, but you must also be an authorized sponsor. When you have those, the applicant can submit an online application.</p>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}