import React from "react";
import { NavLink } from "react-router-dom";
import SITE_PATHS from "../../Path";
import { CommonImg } from "../../../Images/CommonImg";
import { Study } from "../../../Images/Study";
import { Image } from "../../../Images/Image";

export default function Course3() {


    return (
        <>

            <div className="page-title-area">
                <div className="container">
                    <div className="page-title-content">
                        <h4 className="text_size">Tourist Visa</h4>
                        <ul>
                            <li><NavLink to={SITE_PATHS.LOGOCLICK}>Home</NavLink></li>
                            <li>Tourist Visa</li>
                        </ul>
                    </div>
                </div>

                <div className="shape-img3">

                    <img src={CommonImg[1]?.path} ></img>
                </div>
                
            </div>

            <div className="about-area pb-100 bannermargin">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="about-img">
                                <img src={Study[52]?.path} className="ieltstest" alt="tourist visa in australia"/>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="about-content">
                                <div className="content"><span className="sub-title">
                                    <img src={CommonImg[0]?.path} alt="services"/> 
                                    Services</span>
                                    <h1 className="text_size">Tourist Visa</h1>
                                    <h3>Subclass 600</h3>

                                    <p>Australia is well-known for its deserts, beautiful beaches, lush rainforests, acclaimed fauna, and national parks. To arrange a trip to Australia, you may find all the information you want here on our Australian tourist visa page.</p>

                                    <p>An Australian tourist visa is also known as a visitor visa. With this visa, you can go to Australia for fun, to see loved ones, or for any other purpose outside business or medical treatment. For a tourist visa, the department of home affairs may grant you a stay of up to 12 months. The Indian tourist is permitted on numerous trips during these 12 months, each of which is allowed for a 3-months stay. You can take a cruise, go on vacation, or visit friends and relatives with a tourist or visitor visa.</p>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="our-mission-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-content">
                                <div className="content"><span className="sub-title">
                                    <img src={CommonImg[0]?.path} alt="services"/> 
                                    Services</span>
                                    <h2 className="text_size">eVisitor Australia (subclass 651): What is it?</h2>

                                    <p>The eVisitor visa offered by Australia and the ETA is pretty similar. The term "eVisitor visa," sometimes known as an "electronic travel authorization," is also used. This indicates that the application process is also completed online and the eVisitor is not physically attached to the passport. The criteria and expiration period are the same as for the ETA.</p>

                                    <p>Both e-visas can be utilized as business or tourist visas. The only distinctions between an eVisitor and an ETA Australia are the approved nations, the various application processes, and the various application costs.</p>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-image">
                                <img className="ieltstest" src={Study[50]?.path} alt="eVisitor Australia"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <div className="about-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="about-img">
                                <img className="ieltstest" src={Study[53]?.path} alt="tourist visa in Australia"/>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="about-content">
                                <div className="content"><span className="sub-title">
                                    <img src={CommonImg[0]?.path} alt="services"/> 
                                    Services</span>
                                    <h2 className="text_size">Documentation needed to obtain a tourist visa for Australia:</h2>

                                    <p>1.	The applicant's original passport must still be valid for at least six months after the intended departure date.</p>

                                    <p>2.	Form 1419, which must be correctly completed to apply for a visa.</p>

                                    <p>3.	A current picture of the candidate is required—35 to 40 mm wide and 45 to 50 mm long.</p>

                                    <p>4.	To demonstrate that the applicant has the money necessary to stay in Australia, submit bank statements, tax returns, credit card statements, audited accounts for the past three months, or other supporting documentation.</p>

                                    <p>5.	If the applicant is under the age of 18, a birth certificate must be included with the application.</p>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="our-mission-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-content">
                                <div className="content"><span className="sub-title">
                                    <img src={CommonImg[0]?.path} alt="services"/> 
                                    Services</span>
                                    <h3 className="text_size">How to apply for an Australia tourist visa</h3>

                                    <p>1.	You need a valid passport with all the necessary paperwork and also pass any mandatory health tests.</p>

                                    <p>2.	To fill out the application form, first, create an Immi account. Then start submitting the required documents.</p>

                                    <p>3.	Your visa request will be handled after payment has been received.</p>

                                    <p>4.	 You will get a confirmation when the authorities have reviewed your supporting documents and visa applications. When asked, provide biometric information or further documentation.</p>

                                    <p>5.	You will receive word of the visa outcome. You can get a confirmation copy if you are granted an Australian tourist visa.</p>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-image">
                                <img className="ieltstest" src={Study[54]?.path} alt="Australia tourist visa"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="about-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="about-img">
                                <img className="ieltstest" src={Study[55]?.path} alt="Australia tourist visa"/>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="about-content">
                                <div className="content"><span className="sub-title">
                                    <img src={CommonImg[0]?.path} alt="services"/> 
                                    Services</span>
                                    <h3 className="text_size">In case you want to stay longer</h3>

                                    <p>Subclass 600 or tourist visa cannot be extended, allowing you to leave Australia. Australia requires that you apply for a different key.</p>

                                    <p>If your present visa does not include a restriction that forbids longer stay, such as condition 8503 - No Further Stay, you may apply for a new one. Under certain conditions, we may waive the no further stay clause. You must apply for a visa that permits this if you wish to remain in Australia longer or permanently.</p>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="our-mission-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-content">
                                <div className="content"><span className="sub-title">
                                    <img src={CommonImg[0]?.path} alt="services"/> 
                                    Services</span>
                                    <h3 className="text_size">If parents of Australian residents or citizens</h3>

                                    <p>If you fulfill all the requirements for a visa and you are the parent or step-parent of an Australian citizen or permanent resident, you will be given a visa that is valid for more than 12 months.</p>

                                    <p>You can be permitted to enter Australia more than once while the visa is in effect if it is granted for some time longer than 12 months. A total of 12 months cannot be spent in Australia in any one 18-month period. You must also have taken the necessary steps to secure health insurance.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-image">
                                <img className="ieltstest" src={Study[56]?.path} alt="Australian residents"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="about-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="about-img">
                                <img className="ieltstest" src={Study[57]?.path} alt="Visa Fees"/>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="about-content">
                                <div className="content"><span className="sub-title">
                                    <img src={CommonImg[0]?.path} alt="services"/> 
                                    Services</span>
                                    <h3 className="text_size">Visa Fees</h3>

                                    <p>Each applicant is required to pay AUD 150, as a visa processing fee.</p>

                                    <p>If you were affected by COVID-19-related travel restrictions, you could be entitled to a remission of the Visa Application Charge (VAC). Refunds and waivers of Visa Application Charges are where you may get further details on the Department of Home Affairs website.</p>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="our-mission-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-content">
                                <div className="content"><span className="sub-title">
                                    <img src={CommonImg[0]?.path} alt="services"/> 
                                    Services</span>
                                    <h3 className="text_size">Possible additional expenses include:</h3>
                                    
                                    <p>Health examinations, police certifications, and biometrics require extra costs. Use the Visa Pricing Estimator on the Department of Home Affairs website to determine how much your visa will cost. The other costs are not taken into consideration by the estimate.</p>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-image">
                                <img className="ieltstest" src={Study[59]?.path} alt="Possible additional expenses"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="about-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="about-img">
                                <img className="ieltstest" src={Study[58]?.path} alt="Processing Time"/>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="about-content">
                                <div className="content"><span className="sub-title">
                                    <img src={CommonImg[0]?.path} alt="services"/> 
                                    Services</span>
                                    <h3 className="text_size">Processing Time</h3>

                                    <p>Australian immigration officials typically process your tourist visa application within a few weeks. It might take just a few days if all of your paperwork and qualifications are in place.</p>

                                    <p>On the other side, there have been instances where the processing of an application took months. Your application's specifics, nationality, if all of your paperwork is in order, etc. will all play a role in the decision.</p>

                                    <p>You could qualify for a Sponsored Parent (Temporary) visa if you wish to remain for more than 12 months on each visit.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="our-mission-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-content">
                                <div className="content"><span className="sub-title">
                                    <img src={CommonImg[0]?.path} alt="services"/> 
                                    Services</span>
                                    <h3 className="text_size">What is Aussie Immigration Consultancy providing?</h3>
                                    
                                    <p>●	Based on the availability of the documents, we match applicants with the criteria for visa eligibility.</p>

                                    <p>●	Before filing for an Australia tourist visa, we will assist you with organizing the necessary paperwork.</p>

                                    <p>●	We are knowledgeable about the details of the application procedure for visiting visas to Australia as visa advisors.</p>

                                    <p>●	From the beginning, we keep track of everything and offer all the help we can. You can always reach one of our representatives.</p>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-image">
                            <img className="ieltstest" src={Study[60]?.path} alt="Aussie Immigration Consultancy"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}