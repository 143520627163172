const SITE_PATHS ={
    LOGOCLICK : "/",
    DASHBOARD:"/dashboard",
    ABOUT:"/about",
    CONTACT:"/contact",
    COURSE:"/course",
    COURSEDETAILS:"/course-details",
    FAQ:"/faq",
    GALLARY:"/gallary",
    HISTORY:"/history",
    IELTS:"/ielts",
    POLICY:"/our-policy",
    PTE:"/pte",
    SERVICE:"/service",
    SERVICEDETAILS:"/service-details",
    SPOKENENGLISH:"/spoken-english",
    AUSTRALIA:"/Study-Visa-In-Australia",
    CANADA:"/Study-Visa-In-Canada",
    UK:"/Study-Visa-In-UK",
    USA:"/Study-Visa-In-USA",
    NEWZEALAND:"/Study-Visa-In-NewZealand",
    TERMSOFSERVICE:"/Terms-Of-Service",
    TESTIMONIALS:"/Testimonials",
    TEAM:"/team",
    DISCLAIMER:"/disclaimer",
    BLOG1:"/Education-System-in-Australia",
    BLOG2:"/Entry-Requirements-Australian-Universities",
    BLOG3:"/Visa-Assistance",
    COURSE1:"/Student-Visa",
    COURSE2:"/Permanent-Residence",
    COURSE3:"/Tourist-Visa",
    COURSE4:"/Family-Visa",
    COURSE5:"/Parent-Visa",
    COURSE6:"/Work-Visa",
    COURSE7:"/Work-Visa2",
    COURSE8:"/Education-Visa",
    LANDINGPAGE1:"/In",
}

export default SITE_PATHS;