import React, { useState } from "react";
import { Image } from "../../Images/Image";
import { Popover, Tooltip, Modal, Button } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import SITE_PATHS from "../Path";
import { CommonImg } from "../../Images/CommonImg";

export default function OurTeam() {
    const [show, setShow] = useState(false);

    return (
        <>
            <div className="page-title-area">
                <div className="container">
                    <div className="page-title-content">
                        <h4 className="text_size">Team</h4>
                        <ul>
                        <li><NavLink to={SITE_PATHS.LOGOCLICK}>Home</NavLink></li>
                            <li>Our Team</li>
                        </ul>
                    </div>
                </div>

                <div className="shape-img3">
                  <img className="bannermargin_img" src={CommonImg[9]?.path} alt="logo"/>
                </div>              
            </div>
            {/* <section className="scientist-area pt-100 pb-70">
                <div className="container">
                    <div className="section-title"><span className="sub-title"><img
                       src={CommonImg[0]?.path} 
                        alt="team" />Team Members</span>
                        <h2>Our Awesome Team</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                            labore et dolore magna.</p>
                    </div>
                    <div className="row">
                        <div className="col-lg-3 col-sm-6">
                            <div className="single-scientist-item-box">
                                <div className="image">
                                    <img src={Image[4]?.path} alt="team" />
                                    <ul className="social">
                                        <li><a className="d-block" href="#"><i
                                            className="fab fa-facebook-square"></i></a></li>
                                        <li><a className="d-block" href="#"><i
                                            className="fab fa-twitter-square"></i></a></li>
                                        <li><a className="d-block" href="#"><i
                                            className="fab fa-instagram-square"></i></a></li>
                                        <li><a className="d-block" href="#"><i
                                            className="fab fa-linkedin"></i></a></li>
                                    </ul>
                                </div>
                                <div className="content">
                                    <h3>Merv Adrian</h3><span>CEO &amp; Founder</span>
                                </div>
                                <div onClick={() => setShow(true)} className="testimonials-view-btn text-center"><a className="default-btn"
                                    href="#"><i className="fas fa-long-arrow-alt-right"></i>View Details
                                    Our Team <span></span></a></div>

                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-6">
                            <div className="single-scientist-item-box">
                                <div className="image">
                                    <img src={Image[5]?.path} alt="team" />
                                    <ul className="social">
                                        <li><a className="d-block" href="#"><i
                                            className="fab fa-facebook-square"></i></a></li>
                                        <li><a className="d-block" href="#"><i
                                            className="fab fa-twitter-square"></i></a></li>
                                        <li><a className="d-block" href="#"><i
                                            className="fab fa-instagram-square"></i></a></li>
                                        <li><a className="d-block" href="#"><i
                                            className="fab fa-linkedin"></i></a></li>
                                    </ul>
                                </div>
                                <div className="content">
                                    <h3>Kirk Borne</h3><span>UX/UI Designer</span>
                                </div>
                                <div onClick={() => setShow(true)} className="testimonials-view-btn text-center"><a className="default-btn"
                                    href="#"><i className="fas fa-long-arrow-alt-right"></i>View Details
                                    Our Team <span></span></a></div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-6">
                            <div className="single-scientist-item-box">
                                <div className="image"><img
                                    src={Image[6]?.path}
                                    alt="team" />
                                    <ul className="social">
                                    <li><a className="d-block" href="#"><i
                                            className="fab fa-facebook-square"></i></a></li>
                                        <li><a className="d-block" href="#"><i
                                            className="fab fa-twitter-square"></i></a></li>
                                        <li><a className="d-block" href="#"><i
                                            className="fab fa-instagram-square"></i></a></li>
                                        <li><a className="d-block" href="#"><i
                                            className="fab fa-linkedin"></i></a></li>
                                    </ul>
                                </div>
                                <div className="content">
                                    <h3>Carla Gentry</h3><span>Web Developer</span>
                                </div>
                                <div onClick={() => setShow(true)} className="testimonials-view-btn text-center"><a className="default-btn"
                                    href="#"><i className="fas fa-long-arrow-alt-right"></i>View Details
                                    Our Team <span></span></a></div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-6">
                            <div className="single-scientist-item-box">
                                <div className="image"><img
                                    src={Image[7]?.path}
                                    alt="team" />
                                    <ul className="social">
                                    <li><a className="d-block" href="#"><i
                                            className="fab fa-facebook-square"></i></a></li>
                                        <li><a className="d-block" href="#"><i
                                            className="fab fa-twitter-square"></i></a></li>
                                        <li><a className="d-block" href="#"><i
                                            className="fab fa-instagram-square"></i></a></li>
                                        <li><a className="d-block" href="#"><i
                                            className="fab fa-linkedin"></i></a></li>
                                    </ul>
                                </div>
                                <div className="content">
                                    <h3>Marie Curie</h3><span>Support</span>
                                </div>
                                <div onClick={() => setShow(true)} className="testimonials-view-btn text-center"><a className="default-btn"
                                    href="#"><i className="fas fa-long-arrow-alt-right"></i>View Details
                                    Our Team <span></span></a></div>
                            </div>
                        </div>
                    </div>
                    <Displaydetails show={show} title="test" body="testing body" handleClose={() => setShow(false)} />
                </div>
            </section> */}
            <p> Comming soon</p>
        </>
    );
}

export function Displaydetails(props) {
    const { show, handleClose, title, body } = props;

    return (
        <>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header>
                    <Modal.Title>{title}</Modal.Title>
                    <a onClick={handleClose}>
                        <svg width="13" viewBox="6.29 6.34 11.31 11.31">
                            <path
                                fill="#425b76"
                                d="m16.192 6.344-4.243 4.242-4.242-4.242-1.414 1.414L10.535 12l-4.242 4.242 1.414 1.414 4.242-4.242 4.243 4.242 1.414-1.414L13.364 12l4.242-4.242z"
                            ></path>
                        </svg>
                    </a>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        {body}
                    </p>

                </Modal.Body>
                <Modal.Footer>
                    <a className="d-flex align-items-center">
                        <svg viewBox="2 2 20 20" width="14px">
                            <path
                                fill="#425b76"
                                d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zm0 18c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8z"
                            ></path>
                            <path fill="#425b76" d="M11 11h2v6h-2zm0-4h2v2h-2z"></path>
                        </svg>
                        &nbsp; Learn More
                    </a>

                </Modal.Footer>
            </Modal>
        </>
    );
};