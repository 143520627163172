import React from "react";
import { NavLink } from "react-router-dom";
import { CommonImg } from "../Images/CommonImg";
import SITE_PATHS from "./Path";
import { FloatingWhatsApp } from 'react-floating-whatsapp'

export default function LandingFooter() {

    return (
        <>

            <footer className="footer-area bg-color landing-footer">
                <div className="container">
                <div className="App">
                        <FloatingWhatsApp
                            phoneNumber="+918447101910"
                            accountName="Aussie Immigration"
                            allowEsc
                            allowClickAway
                            notification
                            notificationSound
                        />
                    </div>
                    <div className="footer-bottom-area">
                        <div className="row align-items-center">
                            <div className="col-lg-6 col-md-6">
                                <p>@2022 | All rights reserved by <a href="" rel="noreferrer">Aussieimmigration.com.au</a></p>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <ul>
                                    <li><NavLink to={SITE_PATHS.POLICY}>Privacy Policy</NavLink>
                                    </li>
                                    <li><NavLink to={SITE_PATHS.TERMSOFSERVICE}>Terms &amp;
                                        Conditions</NavLink></li>

                                    {/* <li><NavLink to={SITE_PATHS.DISCLAIMER}>disclaimer </NavLink></li> */}
                                    
                                                                    </ul>

                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="footer-map"><img
                    src={CommonImg[14]?.path}
                    alt="footer-logo" /></div> */}
            </footer>
        </>
    );
}