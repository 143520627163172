import React from "react";
import { NavLink } from "react-router-dom";
import SITE_PATHS from "../../Path";
import { CommonImg } from "../../../Images/CommonImg";
import { Study } from "../../../Images/Study";

export default function Course4() {


    return (
        <>

            <div className="page-title-area">
                <div className="container">
                    <div className="page-title-content">
                        <h4 className="text_size">Spouse/Family Visa</h4>
                        <ul>
                            <li><NavLink to={SITE_PATHS.LOGOCLICK}>Home</NavLink></li>
                            <li>Spouse/Family Visa</li>
                        </ul>
                    </div>
                </div>

                <div className="shape-img3">

                    <img src={CommonImg[1]?.path} ></img>
                </div>
            </div>

            <div className="about-area pb-100 bannermargin">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="about-img">
                                <img src={Study[61]?.path} alt="family visa in australia"/>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="about-content">
                                <div className="content"><span className="sub-title"><img
                                   src={CommonImg[0]?.path} 
                                    alt="icon" /> Services</span>
                                    <h1 className="text_size">Spouse / Family Visa</h1>
                                    <h5>(Subclass 309 and Subclass 100)</h5>

                                    <p>You could wish to invite your spouse or family to move to Australia with you, either temporarily or permanently. A qualified New Zealand citizen, Australian permanent resident, partner, or spouse may live permanently in Australia under Subclass 100.</p>

                                    <p>Subclass 309 enables the temporary residence in Australia of the partner or spouse of an Australian national, an Australian permanent resident, or an eligible New Zealand national.</p>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="our-mission-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-content">
                                <div className="content"><span className="sub-title">
                                    <img src={CommonImg[0]?.path} alt="family visa services"/>
                                    Services</span>
                                    <h2 className="text_size">(Subclasses 820 and 801- Partner visa (apply in Australia) </h2>

                                    <p>Permits the spouse or partner of an Australian citizen, an Australian permanent resident, or a qualified New Zealand citizen to remain in Australia. </p>

                                    <h3>Subclass 820 - Partner visa(Temporary) </h3>

                                    <p>With this visa, a de facto partner or spouse of an Australian national, an Australian permanent resident, or a New Zealander who qualifies may stay in Australia for a limited amount of time. To get a permanent Partner visa, you must first obtain this visa.</p>

                                    <h4 className="text_content">Visa for partners, Subclass 801 (Permanent) </h4>

                                    <p>This visa enables the spouse or de facto partner of an Australian national, Australian permanent resident, or eligible New Zealand national to remain permanently in Australia. Typically, it is only available to those who presently possess a temporary Partner visa.</p>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-image">
                                <img className="ieltstest" src={Study[50]?.path} alt="partner visa in australia"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <div className="about-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="about-img">
                                <img className="ieltstest" src={Study[69]?.path} alt="provisional visa in australia" />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="about-content">
                                <div className="content"><span className="sub-title">
                                    <img src={CommonImg[0]?.path} alt="provisional visa services" /> 
                                    Services</span>
                                    <h2 className="text_size">Subclass 309 (Provisional Visa)</h2>

                                    <p>In the interim, until the processing of your application for a permanent Partner visa (subclass 100) is complete, unless you decide to withdraw it. It provides immediate access to the long-term Partner visa. When applying, you must be outside of Australia. With subclass 309 you can permanently stay.</p>

                                    <h3>Processing time</h3>

                                    <p>Processing time may vary from 10 months - 38 months. Regular updates to processing timelines are made, and each application is reviewed individually. Processing complex situations might take longer. Published processing times cannot be changed and should only be used as a reference.</p>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="about-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                            <div className="our-mission-content">
                                <div className="content"><span className="sub-title">
                                    <img src={CommonImg[0]?.path} alt="cost per visa services"/> 
                                    Services</span>
                                    <h3 className="text_size">Cost per visa</h3>

                                    <p>The fee per applicant is AUD 8,085.</p>

                                    <h4 className="text_content">What you can do with this visa?</h4>

                                    <p>With this visa, you can travel to Australia as often as you like, live, work, and attend school there while your permanent partner visa is processed. You can also apply for Medicare, Australia's public health insurance program if you meet the requirements.</p>

                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-12">
                            <div className="about-img">
                                <img className="ieltstest" src={Study[68]?.path} alt="cost per visa in australia"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="about-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-12">
                            <div className="content contentboxheader"><span className="sub-title">
                                <img src={CommonImg[0]?.path} alt="eligibility services"/> 
                                Services</span>
                                <h3 className="text_size">Eligibility</h3>

                                <strong>The following requirements must be met to qualify for this visa:</strong>

                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="about-content">
                                <div className="content contentbox">

                                    <p>●	If you've had a visa denied or revoked while you were in Australia, you could not be qualified for this visa.</p>

                                    <p>●	Normally, for the Department of Home Affairs to consider your request for a temporary visa, you must be outside of Australia. Your family must be outside of Australia to apply with you.</p>

                                    <p>●	Both when you submit your application and while you are on this visa, a sponsor is required. In most cases, your partner is your sponsor.</p>

                                    <p>●	As per Australian Law, if you are a married couple your age should not be less than 18.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="about-content">
                                <div className="content">
                                    <p>●	Should meet all the criteria required for the visa application.</p>

                                    <p>●	You must fulfill the health condition if you, a family member, or dependent children are applying for the visa with you.</p>

                                    <p>●	Must meet the character criteria as per the Department of Home Affairs guidelines.</p>

                                    <p>●	You should not owe anything to the Australian government.</p>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="about-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="about-img">
                                <img className="ieltstest" src={Study[67]?.path} alt="documents for subclass in australia"/>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="about-content">
                                <div className="content"><span className="sub-title">
                                    <img src={CommonImg[0]?.path} alt="subclass services" /> 
                                    Services</span>
                                    <h3 className="text_size">Documents for Subclass 309</h3>

                                    <p>1.	The pages of your existing passport that display your portrait, private information, and the validity and expiration dates of your passport</p>

                                    <p className="mb-3">2.	You must present a birth certificate with the names of both parents on it. If you are unable to do so, please submit the identification page for one of the following: A family census register, a family book with both parents' names on it, a court-issued identity certificate, or another official government identification.</p>
                                    
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">

                        <div className="content missincintbox">
                        <p>3.	You need to provide a character certificate, which is a clearance report given by your home country's police along with a military service record if you have any. Fill out Form 80 Personal Particulars for Assessment, Including a Character Evaluation, and attach it to your application.</p>

                        <p>4.	You need to show evidence of your relationship such as Your marriage certificate if you're married. If you are in a de facto relationship, you must provide written information about your past, present, and future goals, as well as evidence that you and your partner share responsibility for financial, domestic, and social issues and that you are dedicated to your relationship for the foreseeable future.</p>

                        <p>5.	Submit Form 1229. Form seeking parental approval to provide a kid under the age of 18 an Australian visa.</p>

                        <p>6.	A filled-out Form 47a containing information about a kid or other dependant family member aged 18 years or over must be presented to include your child who is over 18 but hasn't turned 23 in your visa application.</p>

                        <p>7.	Translate all non-English documents to English.</p>
                        </div>

                    </div>
                    </div>
                </div>
            </div>

            <div className="about-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="about-img">
                                <img className="ieltstest" src={Study[65]?.path} alt="steps to apply in australia" />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="about-content">
                                <div className="content"><span className="sub-title">
                                    <img src={CommonImg[0]?.path} alt="services"/> 
                                    Services</span>
                                    <h3 className="text_size">Steps to apply</h3>

                                    <p>1.	To receive correspondence regarding your visa application, you can choose anybody. Use Form 956A Appointment or withdrawal of an approved recipient to designate someone to receive your mail.</p>

                                    <p>2.	Use Form 956 to nominate someone to offer immigration help. Appointment of a licensed immigration agent, attorney, or other exempt people.</p>

                                    <p>3.	Submit your requisite documents.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="our-mission-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-content">
                                <div className="content"><span className="sub-title">
                                    <img src={CommonImg[0]?.path} alt="services"/>
                                    Services</span>
                                    
                                    <p>4.	Apply for the visa, either online or offline.</p>

                                    <p>5.	After applying for your visa application-  organize a health examination, and biometrics if informed so. If any papers were missing when you used them, please add them as soon as you can in ImmiAccount. If you want to add another dependent child fill out form 1436. Suppose you have made a mistake fill out form 1023 notification of an incorrect answer as soon as possible.</p>

                                    <p>6.	You will receive a visa if you satisfy the prerequisites for obtaining one.</p>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-image">
                                <img className="ieltstest" src={Study[64]?.path} alt="steps to apply in australia"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="about-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="about-img">
                                <img className="ieltstest" src={Study[58]?.path} alt="migrant visa in australia"/>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="about-content">
                                <div className="content"><span className="sub-title">
                                    <img src={CommonImg[0]?.path} alt="migrant visa services" /> 
                                    Services</span>
                                    <h3 className="text_size">Subclass 100 (Migrant Visa)</h3>

                                    <p>The visa enables the spouse or partner of an eligible New Zealand citizen, an Australian permanent resident, or both to remain permanently in Australia. People on a temporary Partner visa (subclass 309) are often eligible. Subclass 100 is a permanent visa.</p>

                                    <h4  className="text_content">What you can do with this visa?</h4>

                                    <p>In addition to the permitted activities on your subclass 309 visa, you may also: live, work, and study in Australia indefinitely; invite eligible relatives to visit; and, if qualified, seek Australian citizenship.</p>

                                    <p>In addition to this maintain an ongoing, sincere connection with your partner while holding a temporary Partner (Provisional) visa (subclass 309) or Dependent Child visa (subclass 445).</p>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="about-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-12">
                            <div className="content contentboxheader"><span className="sub-title">
                                <img src={CommonImg[0]?.path} alt="eligibility services"/> 
                                Services</span>
                                <h3 className="text_size">Eligibility</h3>

                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="about-content">
                                <div className="content contentbox">

                                    <p>1.	You must fulfill the conditions for the visa, maintain the required level of commitment in your partnership, and have no outstanding obligations to the Australian government or have made arrangements to pay them.</p>

                                    <p>2.	You must hold a partner visa (provisional) (subclass 309) or a dependent child visa (subclass 445).</p>

                                    <p>3.	You cannot be evaluated for a permanent visa until two years have elapsed after you filed for a combined 309 and 100 visa.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="about-content">
                                <div className="content">
                                    <p>4.	You must be married and be in an ongoing, sincere relationship. Being jointly committed to a shared life without other partners means living together or being apart temporarily.</p>

                                    <p>5.	You or your family member should have no debts to the Australian government.</p>

                                    <p>6.	Visa is not granted if the applicant is under 18 years of age.</p>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="about-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="about-img">
                                <img className="ieltstest" src={Study[62]?.path} alt="subclass visa in australia"/>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="about-content">
                                <div className="content"><span className="sub-title">
                                    <img src={CommonImg[0]?.path} alt="subclass services" /> 
                                    Services</span>
                                    <h3 className="text_size">Documents needed for Subclass 100</h3>

                                    <p>1.	A copy of your most current passport's information page should be provided. The signature on this page must be visible.</p>

                                    <p>2.	Only if the police certificate you submitted with your subclass 309 application is no longer valid, are you need to provide this. Police certificates are acceptable for immigration for a year after the date of issuance.</p>

                                    <p className="mb-3">3.	You must offer proof that your partner and you are still together.</p>

                                </div>
                            </div>
                        </div>

                        <div className="col-lg-12">

                        <div className="content missincintbox">
                        <p>4.	This Statutory Declaration - Partner Visa must be completed and attached to your ImmiAccount. Please present your marriage license or other documentation proving your union is legal in Australia if you are married. Provide evidence of your de facto partnership if you are one.</p>

                        </div>
                    </div>
                    </div>
                </div>
            </div>

            <div className="our-mission-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-content">
                                <div className="content"><span className="sub-title">
                                    <img src={CommonImg[0]?.path} alt="subclass services"/>
                                    Services</span>
                                    
                                    <p>5.	Proof of your and your partner's shared financial status.</p>

                                    <p>6.	For your social concerns, witness testimonies are required. Fill out Form 888, which is a statutory declaration from a witness in support of a partner or prospective marriage visa application. The age of your witness must be at least 18.</p>

                                    <p>7.	If your situation has changed since your temporary visa was granted, such as a name change, change in your relationship status, the birth of a new child, the death of your sponsor, the awarding of child custody by a court, etc., you must submit Form 1022 - Notification of Changes in Circumstances (PDF).</p>

                                    <p>8.	Translate all non-English documents to English.</p>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-image">
                                <img className="ieltstest" src={Study[63]?.path} alt="subclass visa in australia" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="about-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="about-img">
                                <img className="ieltstest" src={Study[66]?.path} alt="steps to apply in australia"/>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="about-content">
                                <div className="content"><span className="sub-title">
                                    <img src={CommonImg[0]?.path} alt="services"/> 
                                    Services</span>
                                    <h3 className="text_size">Steps to apply</h3>

                                    <p>1.	You may only designate a registered migration agency, a licensed attorney, or an exempt person to provide you with immigration aid.</p>

                                    <p>2.	Gather and prepare all the documents.</p>

                                    <p>3.	Two years after applying for the combined temporary and permanent partner visa, the immigration service might evaluate you for a permanent visa. One month before the two-year deadline, you can attach the papers. Not before this point, attach them.</p>

                                    <p>4.	After applying you can make changes to your ImmiAccount.</p>

                                    <p>5.	You will be informed about your visa outcome. If refused the money will not be refunded.</p>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}