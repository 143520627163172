import React from "react";


//import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { CommonImg } from "../../Images/CommonImg";
//import { ServiceImage } from "../../Images/ServiceImage";
import SITE_PATHS from "../Path";

export default function Userlist() {


    return (
        <>



            <div className="pb-100 user_List">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-sm-12">
                        <table>
  <tr>
    <th>Name</th>
    <th>Email</th>
    <th>Phone Number</th>
    <th>Visa Type Of Country</th>
  </tr>
  <tr>
    <td>Aliana</td>
    <td>B.Tech CSE</td>
    <td>17218</td>
    <td>Australia</td>
  </tr>
  <tr>
    <td>Maria</td>
    <td>Fashion Tech</td>
    <td>17219</td>
    <td>Australia</td>
  </tr>
  <tr>
    <td>Sarah</td>
    <td>Journalism</td>
    <td>17272</td>
    <td>Australia</td>
  </tr>
  <tr>
    <td>Elena</td>
    <td>MBA</td>
    <td>17291</td>
    <td>Australia</td>
  </tr>
  <tr>
    <td>Elena</td>
    <td>MBA</td>
    <td>17291</td>
    <td>Australia</td>
  </tr>
</table>
                        </div>
                        
                    </div>
                </div>
            </div>

        </>
    )
}