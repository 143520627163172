import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { ServiceImage } from "../../Images/ServiceImage";
import SITE_PATHS from "../Path";
import { CommonImg } from "../../Images/CommonImg";


export default function SpokenEnglish() {


  return (
    <>

<div className="page-title-area">
                <div className="container">
                    <div className="page-title-content">
                        <h4 className="text_size">Spoken English</h4>
                        <ul>
                        <li><NavLink to={SITE_PATHS.LOGOCLICK}>Home</NavLink></li>
                            <li><a href="#">Spoken English Details </a></li>
                        </ul>
                    </div>
                </div>

                <div className="shape-img3">

                    <img  className="bannermargin_img" src={CommonImg[4]?.path}></img>
                </div>
                
            </div>

      <div className="about-area pb-100 bannermargin">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="about-img">
                                <img src={ServiceImage[0]?.path} alt="Spoken English"/>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="about-content">
                                <div className="content"><span className="sub-title">
                                    <img src={CommonImg[0]?.path} alt="services"/> 
                                    Services</span>
                                    <h1 className="text_size">Spoken English</h1>
                                    <p>One of the most extensively used and favored languages for interaction is English, particularly when non-native people are interacting with one another in a professional setting. The majority of positions and occupations, as well as academic entities in the nation and throughout the world, need candidates to be fluent in the English language in both verbal and non-verbal communication. Achieving success in the global economy and among the new generation, particularly the millennials also require this skill.</p>
                                    
                                    <p>There is a dramatic rise in the number of non-native English speakers globally. The importance of the language has also grown with it. It is now essential for successful international conversation as well as the formation and development of ideas.</p>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="our-mission-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-content">
                                <div className="content"><span className="sub-title">
                                    <img src={CommonImg[0]?.path} alt="services"/> 
                                    Services</span>
                                    <h2 className="text_size">Why is spoken English necessary?</h2>
                                    
                        <p>Spoken English allows for the ability to relate to people from every country and using that allows for more job opportunities. People who can speak English fluently will have more opportunities. Businesses need employees who can communicate with English-speaking partners and clients. Entrepreneurs can access more customers online by using English.</p>
                        <p>Our English program is designed to give learners practical skills and linguistic understanding of the English language. To better interact in both personal and business settings. It's crucial to have effective communication skills and a useful vocabulary. The course will ensure that you can effortlessly speak formally in English and that you can speak casually at other times.</p>
                        
                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-image">
                                <img src={ServiceImage[2]?.path} alt="Why is spoken English necessary"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <div className="about-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="about-img">
                                <img src={ServiceImage[1]?.path} alt="Why is spoken English necessary"/>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="about-content">
                                <div className="content"><span className="sub-title">
                                    <img src={CommonImg[0]?.path} alt="services"/> 
                                    Services</span>
                                   
                                    <p>Professional experts devised and presented our Best Spoken English Institute. In the field of spoken English training, they have extensive and varied professional experience. To help students reach their personal and professional objectives, they place a strong emphasis on teaching them how to communicate verbally in English. They concentrate on getting good scores on tests like the IELTS.</p>
                                    <p>Our professionals make sure that you gain tremendous confidence, mastery over your spoken English abilities, and the ability to overcome language phobias. Enroll in our courses to improve your spoken English skills and join the dozens of other successful hopefuls.</p>
                                
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </>
  );
}