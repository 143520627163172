import React, { useEffect, useState } from "react";
import { ServiceImage } from "../../Images/ServiceImage";
import { Study } from "../../Images/Study";
import { CommonImg } from "../../Images/CommonImg";
import { Image } from "../../Images/Image";
import SITE_PATHS from "../Path";
import { NavLink } from "react-router-dom";
export default function Study_Visa_In_USA() {


  return (
    <>
    <div className="page-title-area">
                <div className="container">
                    <div className="page-title-content">
                        <h4 className="text_size">USA</h4>
                        <ul>
                            <li><NavLink to={SITE_PATHS.LOGOCLICK}>Home</NavLink></li>
                            <li>Study Visa In USA</li>
                        </ul>
                    </div>
                </div>

                <div className="shape-img3">

                    <img  src={Study[29]?.path}></img>
                </div>
               
            </div>
     <div className="about-area pb-100 bannermargin">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="about-img">
                                <img src={Study[24]?.path} alt="Study Visa in the USA"/>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="about-content">
                                <div className="content"><span className="sub-title">
                                    <img src={CommonImg[0]?.path} alt="services"/>
                                    Services</span>
                                    <h1 className="text_size">Study Visa in the USA</h1>
                                    <p>The top country for students to study abroad is the United States. It provides unmatched opportunities for learners from all disciplines to learn and succeed in their chosen professions. They are having a robust economy and are one of the most developed countries in the world. For both jobs and education, the USA is in the first place.</p>

                                    <p>For all international students seeking quick and straightforward access to student visas for the United States, Aussie Immigration Consultancy provides its finest assistance.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="our-mission-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-content">
                                <div className="content"><span className="sub-title">
                                    <img src={CommonImg[0]?.path} alt="services"/> 
                                    Services</span>
                                    <h2 className="text_size">Student Exchange and Visitor Program (SEVP)</h2>
                                    <p>The Student and Exchange Visitor Information System is managed by the Department of Homeland Security (DHS) program known as the Student and Exchange Visitor Program (SEVP). To maintain national security, it makes sure that government organizations have access to vital information on nonimmigrant students and exchange guests. For schools allowed to accept F and M nonimmigrant students, SEVP grants authorization and monitoring. It also offers advice to schools about the requirements for preserving students' status and to students about those obligations.</p>
                        
                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-image">
                                <img className="ieltstest" src={Study[12]?.path} alt="Student Exchange and Visitor Program"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <div className="about-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="about-img">
                                <img className="ieltstest" src={Study[20]?.path} alt="student visas in the USA"/>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="about-content">
                                <div className="content"><span className="sub-title">
                                    <img src={CommonImg[0]?.path} alt="services"/>
                                    Services</span>
                                    <h2 className="text_size">Types of Student Visas in the USA</h2>

                                    <p>The US government offers three different categories of student visas: F, J, and M. A U.S. school of higher study that the Student Exchange and Visitor Program accredit must first admit you before you may apply for an F, J, or M student visa (SEVP).</p>

                                    <h3 className="text_content">1. F Visa</h3>
                                    <p>To study at a recognized university or college in the United States or to study English at an institution that offers the English Language.</p>

                                    <h4 className="text_content">2. J Visa</h4>
                                    <p>Involvement in an exchange program, as well as high school and university studies.</p>

                                    <h5 className="text_content">3. M Visa</h5>
                                    <p>For non-academic or professional education or training in the US.</p>

                                    <p>A U.S. school of higher study that is recognized by the Student Exchange and Visitor Program (SEVP) must first admit you before you may apply for an F, J, or M student visa. Once you've been admitted to a school with SEVP certification, the international student office will provide you a Form I-20 or DS-2019 that you may use to apply for a student visa</p>
                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="our-mission-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-content">
                                <div className="content"><span className="sub-title">
                                    <img src={CommonImg[0]?.path} alt="services"/>
                                    Services</span>
                                    <h3 className="text_size">Eligibility criteria for a student visa for the USA</h3>

                                    <strong>The following is the requirement of a US student visa:</strong>

                                    <p>1. A valid passport that is up to date and has a minimum of six months left on it after your planned stay in the US.</p>

                                    <p>2. For requests for non-immigrant visas, use form DS-160.</p>

                                    <p>3. Enrollment at a school that has received SEVP approval and your Form I-20.</p>

                                    <p>4. DS-160 confirmation sheet with the non-immigrant visa application.</p>

                                    <p>5. A recent passport-size photograph with a white background.</p>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-image">
                                <img className="ieltstest" src={Study[0]?.path} alt="Student Visas in USA"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="about-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="about-img">
                                <img className="ieltstest" src={Study[17]?.path} alt="Banking records"/>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="about-content">
                                <div className="content"><span className="sub-title">
                                    <img src={CommonImg[0]?.path} alt="services"/> 
                                    Services</span>

                                    <h3 className="text_size">Evidence showing you have enough money to cover your living expenses for the duration of your stay in the US This could comprise:</h3>

                                    <h4 className="text_content">Banking records</h4>
                                   
                                    <p>Sponsor's financial commitment to pay for your lodging and living expenses.</p>

                                    <h5 className="text_content">Education-Related Documents:</h5>

                                    <p>Academic records such as certificates and mark sheets from the 10th and 12th grades. GRE, GMAT, and SAT results.</p>

                                    <h6 className="text_content">Scorecard for IELTS, TOEFL, and PTE.</h6>

                                    <p>Certificates for bachelor's, provisional degrees, course completion certificates, mark sheets from each year, and a combined mark sheet.</p>
                     
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="our-mission-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-content">
                                <div className="content"><span className="sub-title">
                                    <img src={CommonImg[0]?.path} alt="services"/> 
                                    Services</span>
                                    <h3 className="text_size">Financial Records:</h3>

                                    <p>1. Letter of Loan Approval</p>

                                    <p>2. Statement from a savings account (preferable from the previous three years).</p>

                                    <p>3. Sponsor's financial commitment to pay for your lodging and living expenses.</p>

                                    <p>4. Start getting ready for the visa interview as soon as you have all the necessary paperwork.</p>

                                    <p>5. Remember to come a few minutes early, with all the necessary paperwork and receipts.</p>
                                    
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-image">
                                <img className="ieltstest" src={Study[4]?.path} alt="Financial Records"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="about-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="about-img">
                                <img className="ieltstest" src={ServiceImage[5]?.path} alt="student visa"/>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="about-content">
                                <div className="content"><span className="sub-title">
                                    <img src={CommonImg[0]?.path} alt="services"/> 
                                    Services</span>

                                    <h3 className="text_size">IELTS score band for USA student visa</h3>

                                    <p>In 140 nations worldwide, more than 10,000 organizations including academic institutions, companies, professional organizations, and governments recognize the IELTS results.</p>

                                    <p>US academic institutions require a minimum IELTS score of 6.5 bands.</p>
                     
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="our-mission-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-content">
                                <div className="content"><span className="sub-title">
                                    <img src={CommonImg[0]?.path} alt="services"/>
                                    Services</span>
                                    <h3 className="text_size">Without IELTS, you can study in the USA.</h3>

                                    <p>You must fulfill university admissions standards and federal study visa requirements to study abroad in the US. That is typical for any international studies.</p>

                                    <p>Since most American colleges recognize IELTS, here is a list of additional English language tests that could be approved to enable you to pursue a degree there.</p>

                                    <p>1. Cambridge Test for the (CAE/CPE)</p>

                                    <p>2. TOEFL</p>

                                    <p>3. Pearson Test of English and the Duolingo English Test</p>

                                    <p>4. ACT (American College Test)</p>

                                    <p>5. SAT</p>

                                    <p>6. iTEP</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-image">
                                <img className="ieltstest" src={Study[6]?.path} alt="study in the USA"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="about-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="about-img">
                                <img className="ieltstest" src={Study[15]?.path} alt="IELTS score band"/>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="about-content">
                                <div className="content"><span className="sub-title">
                                    <img src={CommonImg[0]?.path} alt="services"/> 
                                    Services</span>
                                    <h3 className="text_size">List of 10 universities that don’t require an IELTS score band:</h3>

                                    <ul>
                                        <li className="list-unstyled mb-3"><a href="https://udayton.edu/">1. The University of Dayton</a></li>

                                        <li className="list-unstyled mb-3"><a href="https://www.rice.edu/">2. Rice University</a></li>

                                        <li className="list-unstyled mb-3"><a href="https://www.calstate.edu/">3. California State University</a></li>

                                        <li className="list-unstyled mb-3"><a href="https://www.uno.edu/">4. The University of New Orleans</a></li>

                                        <li className="list-unstyled mb-3"><a href="https://drexel.edu/">5. Drexel University</a></li>

                                        <li className="list-unstyled mb-3"><a href="https://www.una.edu/">6. The University of North Alabama</a></li>

                                        <li className="list-unstyled mb-3"><a href="https://www.northwood.edu/">7. Northwood University</a></li>

                                        <li className="list-unstyled mb-3"><a href="https://nl.edu/">8. National Louis University</a></li>

                                        <li className="list-unstyled mb-3"><a href="https://www.colorado.edu/">9. University of Colorado Boulder</a></li>

                                        <li className="list-unstyled"><a href="https://www.uark.edu/">10. University of Arkansas</a></li>

                                    </ul>
                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="our-mission-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-content">
                                <div className="content"><span className="sub-title">
                                    <img src={CommonImg[0]?.path} alt="services"/> 
                                    Services</span>
                                    <h3 className="text_size">Scholarships for overseas students</h3>

                                    <h4 className="text_content">Government-funded Scholarship</h4>

                                    <p><strong>Fullbright Foreign Student Program - </strong>Graduate students, recent graduates, and artists can study in the US for a master's or Ph.D. for a year or longer with the help of Fulbright grants. International students are welcome in all disciplines except medicine.</p>

                                </div>
                            </div>
                        </div>
                <div className="col-lg-6 col-md-12">
                    <div className="our-mission-image">
                        <img className="ieltstest" src={Image[34]?.path} alt="Scholarships for overseas students"/>
                    </div>
                </div>
                    </div>
                </div>
            </div>

            <div className="about-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="about-img">
                                <img className="ieltstest" src={Study[16]?.path} alt="Fellowship Program"/>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="about-content">
                                <div className="content"><span className="sub-title">
                                    <img src={CommonImg[0]?.path} alt="services"/> 
                                    Services</span>

                                    <h4 className="text_content">Hubert Humphrey Fellowship Program</h4>

                                    <p>An foreign professional with work experience who wants to spend 10 months studying in the US may apply for this non-degree scholarship program.</p>

                                    <p>1. US Universities and Colleges with Scholarships for Foreign Students</p>

                                    <p>2. Civil Society Leadership Award</p>

                                    <p>3. University of Minnesota Fellowship</p>

                                    <p>4. ACI Foundation Scholarship</p>

                                    <p>5. Onsi Sawiris Scholarship program</p>

                                    <p>6. Yale University Scholarship</p>

                                    <p>7. University of New Haven Scholarship</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="our-mission-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-content">
                                <div className="content"><span className="sub-title">
                                    <img src={CommonImg[0]?.path} alt="services"/> 
                                    Services</span>
                                    <h3 className="text_size">Cost of studies in the USA</h3>

                                    <h4 className="text_content">Studies Cost</h4>

                                    <p>1. The English Language - $680 - $2000 per month (Approx.)</p>

                                    <p>2. Undergraduate - $21000 - $42000 per year (Approx.)</p>

                                    <p>3. Community College - $7000- $21000 per year (Approx.)</p>

                                    <p>4. Graduate Program - $21000- $46000 per year (Approx.)</p>

                                    <h5 className="text_content">Excluding MBA cost.</h5>

                                    <p>1. Doctoral Degree - $29000- $55000 per year (Approx.)</p>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-image"> 
                                <img src={Study[2]?.path} alt="Cost of studies in the USA"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </>
  );
}