import visaimage from "../Images/visa-img.gif";
import eduimg from "../Images/edu-1.png"
import service from "../Images/service1.gif"
import service1 from "../Images/services.png"
import team1 from "../Images/dashboard1.jpg"
import team2 from "../Images/dashboard2.jpg"
import team3 from "../Images/dashboard3.jpg"
import team4 from "../Images/dashboard3.jpg"
import project1 from "../Images/dashboard4.jpg"
import project2 from "../Images/dashboard5.jpg"
import project3 from "../Images/dashboard6.jpg"
import project4 from "../Images/dashboard7.jpg"
import project5 from "../Images/dashboard8.jpg"
import project6 from "../Images/dashboard9.jpg"
import blog1 from "../Images/dashboard10.jpg"
import blog2 from "../Images/dashboard12.jpg"
import blog3 from "../Images/dashboard11.jpg"
import blog4 from "../Images/dashboard12.jpg"
import blog5 from "../Images/dashboard12.jpg"
import blog6 from "../Images/dashboard12.jpg"
import circle from "../Images/dashboard13.png"
import start from "../Images/dashboard14.png"
import projstart from "../Images/dashboard14.png"
import start1 from "../Images/start1.gif"
import start2 from "../Images/start2.gif"
import Icon2 from "../Images/dashboard15.png"
import Icon3 from "../Images/dashboard16.png"
import Icon4 from "../Images/dashboard17.png"
import Icon5 from "../Images/dashboard18.png"
import Icon6 from "../Images/dashboard19.png"
import Icon7 from "../Images/dashboard20.png"
import Icon8 from "../Images/dashboard21.png"
import Icon9 from "../Images/dashboard22.png"
import Service from "../Images/dashboard23.png"
import Service1 from "../Images/dashboard24.png"
import Icon10 from "../Images/dashboard25.png"
import Icon11 from "../Images/dashboard26.png"
import Icon12 from "../Images/dashboard27.png"
import Icon13 from "../Images/dashboard28.png"
import Contact from "../Images/dashboard29.gif"
import Icon14 from "../Images/dashboard30.png"
import Icon15 from "../Images/dashboard31.png"
import Icon16 from "../Images/dashboard32.png"
import Icon17 from "../Images/dashboard33.png"
import Icon18 from "../Images/dashboard34.png"
import Icon19 from "../Images/dashboard35.png"
import Icon20 from "../Images/dashboard36.png"
import Icon21 from "../Images/dashboard37.png"

export const Image =[
    {
        id : 1,
        path : visaimage,
    },
    {
        id: 2,
        path : eduimg,
    },
    {
        id:3,
        path : service,
    },
    {
        id:4,
        path : service1,
    },
    {
        id:5,
        path : team1,
    },
    {
        id:5,
        path : team2,
    },
    {
        id:6,
        path : team3,
    },
    {
        id:7,
        path : team4,
    },
    {
        id:8,
        path: project1,
    },
    {
        id:9,
        path: project2,
    },
    {
        id:10,
        path: project3,
    },
    {
        id:11,
        path: project4,
    },
    {
        id:12,
        path: project5,
    },
    {
        id:13,
        path: project6,
    },
    {
        id:14,
        path: blog1,
    },
    {
        id:15,
        path: blog2,
    },
    {
        id:16,
        path: blog3,
    },
    {
        id:17,
        path: blog4,
    },
    {
        id:18,
        path: blog5,
    },
    {
        id:19,
        path: blog6,
    },
    {
        id:20,
        path: circle,
    },
    {
        id:21,
        path: start,
    },
    {
        id:22,
        path: projstart,
    },
    {
        id:23,
        path: start1,
    },
    {
        id:24,
        path: start2,
    },
    {
        id:25,
        path: Icon2,
    },
    {
        id:26,
        path: Icon3,
    },
    {
        id:27,
        path: Icon4,
    },
    {
        id:28,
        path: Icon5,
    },
    {
        id:29,
        path: Icon6,
    },
    {
        id:30,
        path: Icon7,
    },
    {
        id:31,
        path: Icon8,
    },
    {
        id:32,
        path: Icon9,
    },
    {
        id:33,
        path: Service,
    },
    {
        id:34,
        path: Service1,
    },
    {
        id:35,
        path: Icon10,
    },
    {
        id:36,
        path: Icon11,
    },
    {
        id:37,
        path: Icon12,
    },
    {
        id:38,
        path: Icon13,
    },
    {
        id:39,
        path: Contact,
    },
    {
        id:40,
        path: Icon14,
    },
    {
        id:41,
        path: Icon15,
    },
    {
        id:42,
        path: Icon16,
    },
    {
        id:43,
        path: Icon17,
    },
    {
        id:44,
        path: Icon18,
    },
    {
        id:45,
        path: Icon19,
    },
    {
        id:46,
        path: Icon20,
    },
    {
        id:47,
        path: Icon21,
    },
]