import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { ServiceImage } from "../../Images/ServiceImage";
import { Study } from "../../Images/Study";
import { CommonImg } from "../../Images/CommonImg";
// import { NavLink } from "react-bootstrap";
import SITE_PATHS from "../Path";

export default function Study_Visa_In_Canada() {


    return (
        <>
            <div className="page-title-area">
                <div className="container">
                    <div className="page-title-content">
                        <h4 className="text_size">Canada</h4>
                        <ul>
                            <li><NavLink to={SITE_PATHS.LOGOCLICK}>Home</NavLink></li>
                            <li>Study Visa In Canada</li>
                        </ul>
                    </div>
                </div>

                <div className="shape-img3">

                    <img src={Study[27]?.path}></img>
                </div>
               
            </div>
            
            <div className="about-area pb-100 bannermargin">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="about-img">
                                <img src={Study[22]?.path} alt="Study in Canada"/>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="about-content">
                                <div className="content"><span className="sub-title">
                                    <img src={CommonImg[0]?.path} alt="services"/> 
                                    Services</span>
                                    <h1 className="text_size">Study in Canada</h1>
                                    <p>Many students choose Canada as their study abroad location since it is one of their top choices. There are several well-regarded schools and institutions there, and the tuition is also reasonably priced. It is all the more enticing because of this. The icing on the cake is that Canada provides some fantastic employment options.</p>
                                    <p>A record number of approx. 4,00,000 new international students entered Canada in 2021. This increase in student enrolment is the result of their relaxation of travel limitations and Post-Graduation Work Permit (PWP) regulations. In addition to all of this, the pleasant people and stunning scenery are worth visiting.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="our-mission-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-content">
                                <div className="content"><span className="sub-title">
                                    <img src={CommonImg[0]?.path} alt="services"/> 
                                    Services</span>
                                    <h2 className="text_size">Canadian Study Permit</h2>
                                    <p>A Canada study permit is required if you are applying to study in Canada. The candidates must apply for a study permit in Canada. This important document is required for international students to apply to the university of their choice. But it's important to keep in mind that a study permit and a student visa are not the same things. It entails things like remaining in the nation, the course one is doing, etc.</p>

                                    <p>A visiting visa or electronic travel authorization might also be required. You will receive a visa in addition to your study permit if it is accepted. In Canada, both on-campus jobs and off-campus jobs are legal for overseas students. The need for a work permit is avoided for full-time students who have a current study permit. During the academic year, this permission enables students to work 20 hrs/per week, and during the summer and other breaks, they can work full-time.</p>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-image">
                                <img src={Study[7]?.path} alt="Canadian Study Permit"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <div className="about-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="about-img">
                                <img className="ieltstest" src={ServiceImage[9]?.path} alt="Eligibility criteria"/>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="about-content">
                                <div className="content"><span className="sub-title">
                                    <img src={CommonImg[0]?.path} alt="services"/> 
                                    Services</span>
                                    <h2 className="text_size">Eligibility criteria for a student visa for Canada</h2>
                                    <strong>If you meet the requirements below, you are eligible to travel to Canada to study.</strong>
                                    <p>1). Meet the requirements to travel having received all necessary vaccinations. Underage individuals are exempt from receiving all recommended vaccinations.</p>

                                    <p>2). If an overseas scholar wants to qualify for a Canadian study permit, they must get an approval certificate from the Designated Learning Institution (DLI). Only the DLIs that is stated on your permission may be used for academic purposes.</p>

                                    <p>3). Show that you have the finances necessary to cover your tuition costs, your living expenses while in Canada, the costs of any family members you bring along, and their transportation back home.</p>

                                    <p>4). Follow the law, maintain a clean criminal record, and get a police certificate if needed.</p>

                                    <p>5). You should be in good health and should obtain a medical examination report.</p>

                                    <p>6). When your study permission expires, demonstrate to the officer that you will depart Canada.</p>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="our-mission-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-content">
                                <div className="content"><span className="sub-title">
                                    <img src={CommonImg[0]?.path} alt="services"/> 
                                    Services</span>
                                    <h3 className="text_size">What paperwork is needed for a Canadian visa?</h3>
                                    <h4>1. A Valid Passport</h4>
                                    <p>One's passport must be current and must not expire before the time one thinks of spending in Canada. For instance, applicants' passports must be valid through September 2023 if they intend to visit Canada in September 2022.</p>

                                    <h4 className="text_content">2. Funds Verification</h4>
                                    <p>You would have to provide proof that you have enough money to pay for both our tuition and residential expenditures following the existing criteria. Canadian Immigration stipulates that a student must have at least CAD 10,000 for each year of their stay.</p>
                                    <p>In addition to the two criteria indicated above, the student must additionally show that they have the funds necessary to cover the return fair.</p>

                                    <strong>Furthermore, they should have:</strong>

                                    <p>1. Banking records</p>

                                    <p>Information about the student loan</p>

                                    <p>3. From the organization, a letter verifying your funding</p>

                                    <p>4. Evidence of scholarship</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-image">
                        <img className="ieltstest" src={Study[0]?.path} alt="paperwork needed for Canadian visa"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="about-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="about-img">
                                <img className="ieltstest" src={Study[18]?.path} alt="Letter of acceptance"/>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="about-content">
                                <div className="content"><span className="sub-title">
                                    <img src={CommonImg[0]?.path} alt="services"/> 
                                    Services</span>
                                    <h3 className="text_size">Letter of acceptance from the university or college</h3>

                                    <p>It is important to possess documentation of approval from the university or college to which one has applied. Additionally, the Immigration Department must accept the Designated Learning Institute.</p>

                                    <h4> Passport-size pictures</h4>

                                    <p>For offline applications, applicants need to have two passport-sized photos. Additionally, for online applications, a digital copy of the passport-size photo that is no larger than 4 MB is required. The picture should be recent with white background.</p>

                                    <h4 className="text_content"> A medical examination certificate</h4>

                                    <p>According to Canadian Immigration standards, candidates must be subject to a medical examination.</p>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="our-mission-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-content">
                                <div className="content"><span className="sub-title">
                                    <img src={CommonImg[0]?.path} alt="services"/> 
                                    Services</span>
                                    <h4 className="text_content">Proof of English Language Competence</h4>
                                    <p>IELTS, TOEFL, and other English proficiency tests, among others, must be taken by candidates. To expedite the visa application process, experts advise students to register for their exams and obtain their results beforehand. You need to submit the exam score band to the institute you are applying for.</p>

                                    <h5 className="text_content">Objective Statement or statement of purpose</h5>
                                    <p>You must write an essay outlining why you are traveling to Canada and why you have picked the specific university while requesting a Canadian Study Permit. Although the checklist would invite you to submit this as an optional document, we highly advise against it.
                                        You should keep up-to-date copies of all of your educational certificates in addition to the paperwork listed above.</p>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-image">
                                <img src={ServiceImage[2]?.path} alt="Proof of English Language Competence"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="about-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="about-img">
                                <img className="ieltstest" src={Study[8]?.path} alt="credit card"/>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="about-content">
                                <div className="content"><span className="sub-title">
                                    <img src={CommonImg[0]?.path} alt="services"/> 
                                    Services</span>
                                    <h4 className="text_content">A credit card is required</h4>

                                    <p>You will also want a credit card if you are applying online to pay the registration fee. Students from India must pay a CAD 150 visa application fee to study in Canada. Please keep in mind that the system only takes credit cards, not debit cards. Furthermore, it is not required to be your credit card. You may use your parent's cards, providing you have their consent.</p>

                                    <h5 className="text_content">For a student visa to Canada, you must have IELTS.</h5>

                                    <p>A minimum IELTS score of 6.0 and higher is required for undergraduate and diploma programs to qualify for a study visa for Canada. This score must also be at least 5.5 in each of the four IELTS bands. But for graduate courses, the necessary IELTS score is 6.5 overall, with a minimum of 6.0 in each band. If you want to pursue a degree in engineering, teaching, or education studies, keep in mind that the minimum IELTS score is 7.0 overall and in each band.</p>

                                    <p>With Aussie Immigration Consultancy you get the best guidance in IELTS. We know better about this exam. With the help of our experienced guidance, you score better if you follow what our experts are teaching to you.</p>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="our-mission-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-content">
                                <div className="content"><span className="sub-title">
                                    <img src={CommonImg[0]?.path} alt="services"/> 
                                    Services</span>
                                    <h3 className="text_size">Without IELTS, how can You study in Canada?</h3>

                                    <strong>Many universities want the following exam to be cleared rather than IELTS. The list of the exam is-</strong>

                                    <p>1. CAEL</p>

                                    <p>2. TOEFL</p>

                                    <p>3. Cambridge Test (CAE / CPE)</p>

                                    <p>4. CELPIP</p>

                                    <p>5. Duolingo English Test</p>

                                    <p>6. CanTEST</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-image">
                                <img className="ieltstest" src={Study[9]?.path} alt="study in Canada"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="about-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="about-img">
                                <img src={ServiceImage[1]?.path} alt="Pearson Test of English"/>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="about-content">
                                <div className="content"><span className="sub-title">
                                    <img src={CommonImg[0]?.path} alt="services"/> 
                                    Services</span>
                                    <h3 className="text_size">7. Pearson Test of English</h3>

                                    <p>Many of the assessment tests is included in our comprehensive list of admission examinations for international studies. We not only prepare our applicants for IELTS but also for other exams which are required for admission.</p>

                                    <p>Even though almost all Canadian universities recognize IELTS, the options listed below may be sufficient for you to enroll in a program in Canada.</p>

                                    <p>1. University of Toronto</p>

                                    <p>2. University of Canada West</p>

                                    <p>3. University of Prince Edward Island</p>

                                    <p>4. Thompson Rivers University</p>

                                    <p>5. The University of Regina</p>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="our-mission-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-content">
                                <div className="content"><span className="sub-title">
                                    <img src={CommonImg[0]?.path} alt="services"/> 
                                    Services</span>
                                    <h3 className="text_size">Scholarship</h3>

                                    <p>There are several scholarship programs for international students. These scholarships include tuition costs, housing costs, medical insurance, and travel expenses in addition to an estimated monthly allowance of $2500. Some of the top universities that provide scholarships are listed below:</p>

                                    <h4 className="text_content">1. University of Waterloo Scholarships</h4>

                                    <p>For undergraduate, graduate, and doctoral studies, this fellowship is available. $10,000 is the maximum amount of the Waterloo University scholarship.</p>

                                    <h5 className="text_content">2. University of Montreal Scholarship</h5>

                                    <p>This scholarship provides $27300 per year.</p>

                                    <h6 className="text_content">3. Douglas college international scholarship</h6>

                                    <p>Up to 7,000 CAD in tuition costs is covered by this award. Up to five suitable recipients will be chosen each academic year.</p>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-image">
                                <img className="ieltstest" src={Study[6]?.path} alt="Scholarship"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="about-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="about-img">
                            <img className="ieltstest" src={Study[3]?.path} alt="Saskatchewan Graduate Scholarship"/>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="about-content">
                                <div className="content"><span className="sub-title">
                                    <img src={CommonImg[0]?.path} alt="services"/> 
                                    Services</span>
                                    <h4 className="text_content">4. University of Saskatchewan Graduate Scholarship</h4>

                                    <p>For international students pursuing master's and doctoral degrees, the University of Saskatchewan Graduate Scholarship gives complete finance. For PhDs students, this grant offers up to $20,000, and for master's degrees, up to $16,000.</p>

                                    <h5 className="text_content">5. SheBegan an International scholarship for women</h5>

                                    <p>This fellowship is available for undergraduate, graduate, and doctoral studies. Incentives include a monthly salary, health insurance, and degree sponsorship ranging from $18,000 to $32,000.</p>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="our-mission-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-content">
                                <div className="content"><span className="sub-title">
                                    <img src={CommonImg[0]?.path} alt="services"/> 
                                    Services</span>
                                    <h3 className="text_size">Cost of studies in Canada</h3>

                                    <p>One of the most popular nations for students to pursue higher education is Canada. If we contrast the costs associated with studying in Canada, the US, and the UK. Canada is more affordable than both of these nations. Here is a list of courses in Canada along with their approximate costs:</p>

                                    <h4 className="text_content">1. Undergraduate courses</h4>

                                    <p>The average annual cost of a bachelor's degree in Canada varies by subject and ranges from CAD 12,000 to CAD 30,000. Engineering and medical courses (undergraduate level) range from about CAD 22,000.</p>

                                    <h5 className="text_content">2. Master’s Courses</h5>

                                    <p>Numerous individuals participate in postgraduate or master's degree programs like the MBBS in Canada in parallel to obtaining undergraduate degrees in these fields. The annual cost of tuition for an overseas student will be around CAD 18,000. Exceptionally costly courses are the management (MBA) programs. While mainstream MBA schools typically charge a tuition charge of around CAD 28,000, elite MBA studies typically cost CAD 57,000.</p>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-image">
                                <img src={Study[2]?.path} alt="Cost of studies in Canada"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}