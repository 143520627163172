import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { ServiceImage } from "../../Images/ServiceImage";
import SITE_PATHS from "../Path";
import { CommonImg } from "../../Images/CommonImg";
export default function PTE() {


  return (
    <>

<div className="page-title-area">
                <div className="container">
                    <div className="page-title-content">
                        <h4 className="text_size">PTE</h4>
                        <ul>
                        <li><NavLink to={SITE_PATHS.LOGOCLICK}>Home</NavLink></li>
                            <li>PTE Details</li>
                        </ul>
                    </div>
                </div>

                <div className="shape-img3">
                    <img className="bannermargin_img" src={CommonImg[10]?.path} alt="logo"/>
                </div>
              
            </div>

     <div className="about-area pb-100 bannermargin">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="about-img">
                                <img className="ieltstest" src={ServiceImage[8]?.path} alt="pte"/>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="about-content">
                                <div className="content"><span className="sub-title">
                                    <img src={CommonImg[0]?.path} alt="services"/> 
                                    Services</span>
                                    <h1 className="text_size">PTE</h1>
                                    <p>PTE stands for Pearson Test of English. This test gauges one's skill in the English language. The PTE score is equally important to consider as the IELTS score. A high number of Australian colleges accept the PTE score. To determine a person's ability to study, work, and immigrate, the Australian government administers the Pearson Test for English (PTE).</p>
                                    <h3>About PTE</h3>
                                    <p>Pearson Language Tests (PTE) is a unit of the Pearson PLC group which are dedicated to assessing and validating the use of English for non-native English speakers. There are three tests; Pearson Test of English (PTE) Academic, PTE General, and PTE Young Learners. The tests are marked according to the Global Scale of English from a scale of 10-90. The exams are scenario-based and credited by the QCA and administered in association with Edexcel.</p>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="our-mission-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-content">
                                <div className="content"><span className="sub-title">
                                    <img src={CommonImg[0]?.path} alt="services"/> 
                                    Services</span>
                                    <h2 className="text_size">PTE academics consist of three parts:</h2>
                                    <p>1). Speaking & Writing- Time limit 56-67 minutes.</p>
                                    <p>2). Reading - Time Limit 29-30 minutes.</p>
                                    <p>3). Listening- 30-43 minutes.</p>
                                    <h4>PTE Eligibility</h4>
                        <p>There is no such harsh qualifying requirement. The PTE Academic test is available to students over the age of 16. There is no specified higher age limit listed on the official PTE website. A parental consent form must be completed and submitted ahead of time by applicants in the 16–18 age range. The parental consent form is available for download from the official PTE Exam website.</p>
                    
                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-image">
                                <img src={ServiceImage[10]?.path} alt="PTE academics consist of three parts"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <div className="about-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="about-img">
                                <img src={ServiceImage[11]?.path} alt="pte score for study visa in australia"/>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="about-content">
                                <div className="content"><span className="sub-title">
                                    <img src={CommonImg[0]?.path} alt="services"/> 
                                    Services</span>
                                    <h2 className="text_size">PTE Score for Study Visa in Australia</h2>
                                    <strong>The minimum score needed to study in Australia is as follows:</strong>
                                    <strong>1). For Diploma Students</strong>
                                    <p>Students who wish to continue their diploma following secondary school must get a score between 42 and 49.</p>
                                    <strong>2). Bachelor’s Degree</strong>
                                    <p>The minimum passing score is between 46-50 if the student wishes to pursue his ambition of studying for a bachelor's degree in Australia.</p>
                                    <strong>3). Master’s Degree</strong>
                                    <p>The required score range for master's admission at Australian universities is 50–58. You will have very little chance of passing the exam if you aim for the required score. Always strive to achieve more than the minimum number of passing grades. You can only fulfill your dream after that.</p>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="our-mission-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-content">
                                <div className="content"><span className="sub-title">
                                    <img src={CommonImg[0]?.path} alt="services"/> 
                                    Services</span>
                                    <h3 className="text_size">From us, what do you gain?</h3>
                                    <p>We have a highly qualified and accredited staff of visa and immigration consultants that can help you obtain your visa and other papers while making sure you don't run into any legal problems when relocating.</p>
                                    <p>Our experts will confirm that your application is valid and that you are fully prepared to get a study visa. In addition to guiding you through the rest of the procedures necessary to get your visa, our visa and immigration advisors will help you secure all legal permissions necessary for studying abroad.</p>
                                    <p>We offer every resource needed to pass the PTE exam. You will receive guidance from a highly competent teacher who has received special training. Numerous pupils have benefited from their instruction, and many of them achieved excellent results. We pay equal attention to each learner. Every student is significant to us, thus we wish everyone success.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-image">
                                <img className="ieltstest" src={ServiceImage[9]?.path} alt="service"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </>
  );
}